.appointment {
  border-radius: $standard-border-radius;
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.02),
    0 9px 46px 8px rgba(0, 0, 0, 0.06), 0 11px 15px -7px rgba(0, 0, 0, 0.02);
  margin-left: 2rem;
  margin-right: 2rem;
  padding: 2rem;
  width: 100%;

  .links-block {
    font-size: 1.4rem;
  }

  .patient-details-block {
    font-size: 1.4rem;

    div {
      align-content: center;
      display: flex;
      margin-bottom: 0.5rem;
    }

    svg {
      margin-right: 1rem;
    }
  }

  .cancel-appointment-button {
    align-self: flex-end;
    background-color: $warning-colour;
    border: 0;
    border-radius: $rounded-border-radius;
    color: #fff;
    cursor: pointer;
    font-size: 1.2rem;
    padding: 1rem;
    width: 100%;
  }

  .title-block {
    align-content: center;
    display: flex;
    justify-content: space-between;

    button {
      background: none;
      border: 0;
      cursor: pointer;
    }
  }

  h1,
  h2,
  h3 {
    font-weight: normal;
  }

  .date {
    font-size: 1.2rem;
  }
}
