.header {
  align-items: center;
  background-color: $tertiary-background-colour;
  color: #fff;
  display: flex;
  font-size: 2rem;
  justify-content: flex-end;
  padding: 2rem 2rem 0;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 20;

  .book-btn-wrapper {
    justify-self: flex-start;
    margin-right: auto;
    padding-bottom: 1rem;
  }

  .register-btn-wrapper {
    justify-self: flex-start;
    margin-right: auto;
    padding-bottom: 1rem;
  }

  .tab-block {
    align-content: flex-end;
    background-color: #f1f1f1;
    border-bottom: 1px solid #ccc;
    border-top-left-radius: $standard-border-radius;
    border-top-right-radius: $standard-border-radius;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
    margin-top: 2rem;
    overflow: hidden;
  }

  .tab-link {
    background-color: inherit;
    border: 0;
    color: $secondary-background-colour;
    cursor: pointer;
    float: left;
    font-size: 1.5rem;

    padding: 1.4rem 1.6rem;
    text-decoration: none;
  }

  .tab-link:hover {
    background-color: lighten($primary-background-colour, 10%);
    color: #fff;
  }

  .active-tab {
    background-color: $primary-background-colour;
    color: #fff;
  }

  .logout-button {
    background: none;
    border: 2px solid #fff;
    border-radius: $rounded-border-radius;
    color: #fff;
    cursor: pointer;
    font-size: 1.5rem;
    padding: 1rem 2rem;
  }

  .header-link {
    background: 0;
    border: 0;
    color: #fff;
    cursor: pointer;
    font-size: 1.5rem;
    margin-right: 3rem;
    text-decoration: none;
  }

  .bm-burger-button {
    height: 30px;
    position: fixed;
    right: 36px;
    top: 1.5rem;
    width: 36px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #fff;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 29px;
    margin-bottom: 2rem;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
    color: #fff;
    margin-bottom: 2rem;
  }

  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    overflow: hidden !important;
    position: fixed;
    top: 0;
    width: 100% !important;
  }

  /* General sidebar styles */
  .bm-menu {
    background: $secondary-background-colour;
    font-size: 1.15em;
    height: 100vh;
    padding: 3rem 0 0;
    padding-top: 4rem;
    width: 100%;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    height: 100%;
  }

  .bm-item {
    background-color: $primary-background-colour;
    color: #fff;
    display: inline-block;
    font-size: 2rem;
    font-weight: light;
    padding: 1.2rem;
    text-decoration: none;
  }

  .bm-overlay {
    display: none;
  }
  @media only screen and (max-width: $mobile-breakpoint-width) {
    justify-content: flex-start;
    margin-bottom: $small;
    overflow: hidden;
    padding: 3rem 0;
    position: fixed;

    .logo {
      align-content: flex-start;
      align-items: flex-start;
      background: #fff;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      padding: 1rem 0;
      width: 50%;

      > img {
        width: 5%;
      }
    }

    .tab-link {
      font-size: 1.2rem;
    }

    .book-btn-wrapper {
      border: 0;
      height: 2rem;
      margin-left: 2rem;
      position: fixed;
      top: 1.2rem;

      a {
        border: 0;
      }
    }

    .register-btn-wrapper {
      border: 0;
      height: 2rem;
      margin-left: 2rem;
      position: fixed;
      top: 1.2rem;

      a {
        border: 0;
      }
    }
  }
}
