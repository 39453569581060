.instore-virtual-consultation {
  text-align: center;
  width: 50rem;

  .store-select {
    margin: auto;

    min-width: 100%;
  }

  h2 {
    font-weight: lighter;
  }
  @media only screen and (max-width: $mobile-breakpoint-width) {
    .store-select {
      max-width: 60%;
      min-width: 60%;
    }
  }
}
