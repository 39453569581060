/*! elementor - v3.0.16 - 06-01-2021 */
.dialog-widget-content {
  background-color: #fff;
  border-radius: 3px;
  border-radius: 3px;
  box-shadow: 2px 8px 23px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 8px 23px 3px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: absolute;
}

.elementor-icon-list-item {
  align-items: center;
  display: flex;
}

.dialog-message {
  box-sizing: border-box;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 1.5;
}

.dialog-type-lightbox {
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
  width: 100%;
  z-index: 9999;
}

.dialog-type-lightbox .dialog-widget-content {
  margin: auto;
  width: 375px;
}

.dialog-type-lightbox .dialog-header {
  color: #495157;
  font-size: 15px;
  font-weight: 500;
  padding: 30px 0 10px;
}

.dialog-type-lightbox .dialog-message {
  min-height: 50px;
  padding: 0 30px 30px;
}

.dialog-type-lightbox:not(.elementor-popup-modal) .dialog-header,
.dialog-type-lightbox:not(.elementor-popup-modal) .dialog-message {
  text-align: center;
}

.dialog-type-lightbox .dialog-buttons-wrapper {
  border-top: 1px solid #e6e9ec;
  text-align: center;
}

.dialog-type-lightbox .dialog-buttons-wrapper > .dialog-button {
  background: none;
  border: none;
  color: #6d7882;
  cursor: pointer;
  font-family: Roboto, Arial, Helvetica, Verdana, sans-serif;
  font-size: 15px;
  outline: 0;
  padding: 13px 0;
  width: 50%;
}

.dialog-type-lightbox .dialog-buttons-wrapper > .dialog-button:hover {
  background-color: #f4f6f7;
}

.dialog-type-lightbox .dialog-buttons-wrapper > .dialog-button.dialog-ok {
  color: #b01b1b;
}

.dialog-type-lightbox
  .dialog-buttons-wrapper
  > .dialog-button.dialog-take_over {
  color: #39b54a;
}

.dialog-type-lightbox .dialog-buttons-wrapper > .dialog-button:active {
  background-color: rgba(230, 233, 236, 0.5);
}

.dialog-type-lightbox
  .dialog-buttons-wrapper
  > .dialog-button::-moz-focus-inner {
  border: 0;
}

.dialog-close-button {
  cursor: pointer;
  font-size: 15px;
  line-height: 1;
  margin-top: 15px;
  position: absolute;
  right: 15px;
}

.dialog-close-button:not(:hover) {
  opacity: 0.4;
}

.dialog-alert-widget .dialog-buttons-wrapper > button {
  width: 100%;
}

.dialog-confirm-widget .dialog-button:first-child {
  border-right: 1px solid #e6e9ec;
}

.dialog-prevent-scroll {
  max-height: 100vh;
  overflow: hidden;
}
@media (min-width: 1024px) {
  body.admin-bar .dialog-lightbox-widget {
    height: calc(100vh - 32px);
  }
}
@media (max-width: 1024px) {
  body.admin-bar .dialog-type-lightbox {
    height: 100vh;
    position: sticky;
    position: sticky;
  }
}

.elementor-aspect-ratio-219 .elementor-fit-aspect-ratio {
  padding-bottom: 42.8571%;
}

.elementor-aspect-ratio-169 .elementor-fit-aspect-ratio {
  padding-bottom: 56.25%;
}

.elementor-aspect-ratio-43 .elementor-fit-aspect-ratio {
  padding-bottom: 75%;
}

.elementor-aspect-ratio-32 .elementor-fit-aspect-ratio {
  padding-bottom: 66.6666%;
}

.elementor-aspect-ratio-11 .elementor-fit-aspect-ratio {
  padding-bottom: 100%;
}

.elementor-aspect-ratio-916 .elementor-fit-aspect-ratio {
  padding-bottom: 177.8%;
}

.elementor-fit-aspect-ratio {
  height: 0;
  position: relative;
}

.elementor-fit-aspect-ratio iframe {
  background-color: #000;
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.elementor-fit-aspect-ratio video {
  width: 100%;
}

.flatpickr-calendar {
  width: 280px;
}

.flatpickr-calendar .flatpickr-current-month span.cur-month {
  font-weight: 300;
}

.flatpickr-calendar .dayContainer {
  max-width: 280px;
  min-width: 280px;
  width: 280px;
}

.flatpickr-calendar .flatpickr-days {
  width: 280px;
}

.flatpickr-calendar .flatpickr-day {
  height: 37px;
  line-height: 37px;
  max-width: 37px;
}

.elementor-templates-modal .dialog-widget-content {
  background-color: #f1f3f5;
  font-family: Roboto, Arial, Helvetica, Verdana, sans-serif;
  width: 100%;
}
@media (max-width: 1439px) {
  .elementor-templates-modal .dialog-widget-content {
    max-width: 990px;
  }
}
@media (min-width: 1440px) {
  .elementor-templates-modal .dialog-widget-content {
    max-width: 1200px;
  }
}

.elementor-templates-modal .dialog-header {
  padding: 0;
  z-index: 1;
}

.elementor-templates-modal .dialog-buttons-wrapper,
.elementor-templates-modal .dialog-header {
  background-color: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

.elementor-templates-modal .dialog-buttons-wrapper {
  border: none;
  box-pack: end;
  display: none;
  justify-content: flex-end;
  padding: 5px;
}

.elementor-templates-modal .dialog-buttons-wrapper .elementor-button {
  height: 40px;
  margin-left: 5px;
}

.elementor-templates-modal .dialog-buttons-wrapper .elementor-button-success {
  color: #fff;
  font-size: 15px;
  padding: 12px 36px;
  width: auto;
}

.elementor-templates-modal
  .dialog-buttons-wrapper
  .elementor-button-success:hover {
  background-color: #39b54a;
}

.elementor-templates-modal .dialog-message {
  height: 750px;
  max-height: 85vh;
  overflow: auto;
  padding-top: 25px;
}

.elementor-templates-modal .dialog-content {
  height: 100%;
}

.elementor-templates-modal .dialog-loading {
  display: none;
}

.elementor-templates-modal__header {
  align-items: center;
  box-align: center;
  display: flex;
  height: 50px;
  justify-content: space-between;
}

.elementor-templates-modal__header__logo {
  cursor: pointer;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
}

.elementor-templates-modal__header__logo-area {
  padding-left: 15px;
  text-align: left;
}

.elementor-templates-modal__header__logo-area > * {
  align-items: center;
  box-align: center;
  display: flex;
}

.elementor-templates-modal__header__logo__icon-wrapper {
  font-size: 12px;
  margin-right: 10px;
}

.elementor-templates-modal__header__logo__title {
  padding-top: 2px;
}

.elementor-templates-modal__header__items-area {
  box-direction: reverse;
  box-orient: horizontal;
  display: flex;
  flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.elementor-templates-modal__header__item {
  align-items: center;
  box-align: center;
  box-pack: center;
  box-sizing: content-box;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  position: relative;
}

.elementor-templates-modal__header__item > i {
  cursor: pointer;
  font-size: 20px;
  transition: all 0.3s;
  transition: all 0.3s;
  transition: all 0.3s;
}

.elementor-templates-modal__header__item > i:not(:hover) {
  color: #a4afb7;
}

.elementor-templates-modal__header__close--normal {
  border-left: 1px solid #e6e9ec;
  width: 47px;
}

.elementor-templates-modal__header__close--normal i {
  font-size: 18px;
}

.elementor-templates-modal__header__close--skip {
  background-color: #a4afb7;
  border-radius: 2px;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  font-size: 11px;
  font-weight: 400;
  line-height: 1;
  margin-right: 10px;
  padding: 10px 10px 10px 20px;
  text-transform: uppercase;
}

.elementor-templates-modal__header__close--skip > i {
  border-left: 1px solid;
  font-size: inherit;
  margin-left: 15px;
  padding-left: 10px;
}

.elementor-templates-modal__header__close--skip > i:not(:hover) {
  color: #fff;
}

.elementor-templates-modal__sidebar {
  background-color: hsla(0, 0%, 100%, 0.3);

  flex-shrink: 0;
  width: 25%;
}

.elementor-templates-modal__content {
  box-flex: 1;
  box-shadow: 0 0 13px inset rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 0 13px rgba(0, 0, 0, 0.05);
  flex-grow: 1;
}

#wpadminbar #wp-admin-bar-elementor_app_site_editor a.ab-item::before {
  color: inherit;
  content: "\e91d";
  font-family: eicons;
  font-size: 13px;
  top: 4px;
}

.elementor-hidden {
  display: none;
}

.elementor-screen-only,
.screen-reader-text,
.screen-reader-text span,
.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: -10000em;
  width: 1px;
}

.elementor-clearfix::after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  width: 0;
}

.e-logo-wrapper {
  background: #93003c;
  border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  line-height: 1;
  padding: 0.75em;
}

.e-logo-wrapper i {
  color: #fff;
  font-size: 1em;
}

.elementor {
  hyphens: manual;
  hyphens: manual;
  hyphens: manual;
}

.elementor *,
.elementor ::after,
.elementor ::before {
  box-sizing: border-box;
  box-sizing: border-box;
}

.elementor a {
  box-shadow: none;
  box-shadow: none;
  text-decoration: none;
}

.elementor hr {
  background-color: transparent;
  margin: 0;
}

.elementor img {
  border: none;
  border-radius: 0;
  border-radius: 0;
  box-shadow: none;
  box-shadow: none;
  height: auto;
  max-width: 100%;
}

.elementor
  .elementor-widget:not(.elementor-widget-text-editor):not(.elementor-widget-theme-post-content)
  figure {
  margin: 0;
}

.elementor embed,
.elementor iframe,
.elementor object,
.elementor video {
  border: none;
  line-height: 1;
  margin: 0;
  max-width: 100%;
  width: 100%;
}

.elementor .elementor-custom-embed {
  line-height: 0;
}

.elementor .elementor-background,
.elementor .elementor-background-holder,
.elementor .elementor-background-video-container {
  direction: ltr;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.elementor .elementor-background-video-container {
  pointer-events: none;
  transition: opacity 1s;
  transition: opacity 1s;
  transition: opacity 1s;
}

.elementor .elementor-background-video-container.elementor-loading {
  opacity: 0;
}

.elementor .elementor-background-video-embed {
  max-width: none;
}

.elementor .elementor-background-video,
.elementor .elementor-background-video-embed,
.elementor .elementor-background-video-hosted {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.elementor .elementor-background-video {
  max-width: none;
}

.elementor .elementor-html5-video {
  object-fit: cover;
  object-fit: cover;
}

.elementor .elementor-background-overlay,
.elementor .elementor-background-slideshow {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.elementor .elementor-background-slideshow {
  z-index: 0;
}

.elementor .elementor-background-slideshow__slide__image {
  background-position: 50%;
  background-size: cover;
  background-size: cover;
  height: 100%;
  width: 100%;
}

.elementor-widget-wrap > .elementor-element.elementor-absolute {
  position: absolute;
}

.elementor-widget-wrap > .elementor-element.elementor-fixed {
  position: fixed;
}

.elementor-widget-wrap .elementor-element.elementor-widget__width-auto,
.elementor-widget-wrap .elementor-element.elementor-widget__width-initial {
  max-width: 100%;
}
@media (max-width: 1024px) {
  .elementor-widget-wrap .elementor-element.elementor-widget-tablet__width-auto,
  .elementor-widget-wrap
    .elementor-element.elementor-widget-tablet__width-initial {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .elementor-widget-wrap .elementor-element.elementor-widget-mobile__width-auto,
  .elementor-widget-wrap
    .elementor-element.elementor-widget-mobile__width-initial {
    max-width: 100%;
  }
}

.elementor-element.elementor-absolute,
.elementor-element.elementor-fixed {
  z-index: 1;
}

.elementor-invisible {
  visibility: hidden;
}

.elementor-align-center {
  text-align: center;
}

.elementor-align-center .elementor-button {
  width: auto;
}

.elementor-align-right {
  text-align: right;
}

.elementor-align-right .elementor-button {
  width: auto;
}

.elementor-align-left {
  text-align: left;
}

.elementor-align-left .elementor-button {
  width: auto;
}

.elementor-align-justify .elementor-button {
  width: 100%;
}

.elementor-custom-embed-play {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.elementor-custom-embed-play i {
  color: #fff;
  font-size: 100px;
  opacity: 0.8;
  text-shadow: 1px 0 6px rgba(0, 0, 0, 0.3);
  transition: all 0.5s;
  transition: all 0.5s;
  transition: all 0.5s;
}

.elementor-custom-embed-play.elementor-playing i {
  animation: fa-spin 2s linear infinite;
  animation: fa-spin 2s linear infinite;
  font-family: eicons;
}

.elementor-custom-embed-play.elementor-playing i::before {
  content: "\e8fb";
}

.elementor-tag {
  display: inline-box;
  display: inline-flexbox;
  display: inline-flex;
}

.elementor-ken-burns {
  transition-duration: 10s;
  transition-duration: 10s;
  transition-duration: 10s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-timing-function: linear;
  transition-timing-function: linear;
  transition-timing-function: linear;
}

.elementor-ken-burns--out {
  transform: scale(1.3);
  transform: scale(1.3);
  transform: scale(1.3);
}

.elementor-ken-burns--active {
  transition-duration: 20s;
  transition-duration: 20s;
  transition-duration: 20s;
}

.elementor-ken-burns--active.elementor-ken-burns--out {
  transform: scale(1);
  transform: scale(1);
  transform: scale(1);
}

.elementor-ken-burns--active.elementor-ken-burns--in {
  transform: scale(1.3);
  transform: scale(1.3);
  transform: scale(1.3);
}
@media (max-width: 1024px) {
  .elementor-tablet-align-center {
    text-align: center;
  }

  .elementor-tablet-align-center .elementor-button {
    width: auto;
  }

  .elementor-tablet-align-right {
    text-align: right;
  }

  .elementor-tablet-align-right .elementor-button {
    width: auto;
  }

  .elementor-tablet-align-left {
    text-align: left;
  }

  .elementor-tablet-align-left .elementor-button {
    width: auto;
  }

  .elementor-tablet-align-justify .elementor-button {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .elementor-mobile-align-center {
    text-align: center;
  }

  .elementor-mobile-align-center .elementor-button {
    width: auto;
  }

  .elementor-mobile-align-right {
    text-align: right;
  }

  .elementor-mobile-align-right .elementor-button {
    width: auto;
  }

  .elementor-mobile-align-left {
    text-align: left;
  }

  .elementor-mobile-align-left .elementor-button {
    width: auto;
  }

  .elementor-mobile-align-justify .elementor-button {
    width: 100%;
  }
}

#wpadminbar #wp-admin-bar-elementor_edit_page > .ab-item::before {
  content: "\e813";
  font-family: eicons;
  font-size: 18px;
  top: 3px;
}

#wpadminbar #wp-admin-bar-elementor_inspector > .ab-item::before {
  content: "\f348";
  top: 2px;
}

:root {
  --page-title-display: block;
}

.elementor-page-title,
h1.entry-title {
  display: var(--page-title-display);
}

.elementor-section {
  position: relative;
}

.elementor-section .elementor-container {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
@media (max-width: 1024px) {
  .elementor-section .elementor-container {
    flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.elementor-section.elementor-section-boxed > .elementor-container {
  max-width: 1140px;
}

.elementor-section.elementor-section-stretched {
  position: relative;
  width: 100%;
}

.elementor-section.elementor-section-items-top > .elementor-container {
  align-items: flex-start;
  box-align: start;
}

.elementor-section.elementor-section-items-middle > .elementor-container {
  align-items: center;
  box-align: center;
}

.elementor-section.elementor-section-items-bottom > .elementor-container {
  align-items: flex-end;
  box-align: end;
}
@media (min-width: 768px) {
  .elementor-section.elementor-section-height-full {
    height: 100vh;
  }

  .elementor-section.elementor-section-height-full > .elementor-container {
    height: 100%;
  }
}

.elementor-bc-flex-widget
  .elementor-section-content-top
  > .elementor-container
  > .elementor-column
  > .elementor-widget-wrap {
  align-items: flex-start;
  box-align: start;
}

.elementor-bc-flex-widget
  .elementor-section-content-middle
  > .elementor-container
  > .elementor-column
  > .elementor-widget-wrap {
  align-items: center;
  box-align: center;
}

.elementor-bc-flex-widget
  .elementor-section-content-bottom
  > .elementor-container
  > .elementor-column
  > .elementor-widget-wrap {
  align-items: flex-end;
  box-align: end;
}

.elementor-row {
  display: flex;
  width: 100%;
}
@media (max-width: 1024px) {
  .elementor-row {
    flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.elementor-widget-wrap {
  align-content: flex-start;
  flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}

.elementor:not(.elementor-bc-flex-widget) .elementor-widget-wrap {
  display: flex;
}

.elementor-widget-wrap > .elementor-element {
  width: 100%;
}

.elementor-widget {
  position: relative;
}

.elementor-widget:not(:last-child) {
  margin-bottom: 20px;
}

.elementor-widget:not(:last-child).elementor-absolute,
.elementor-widget:not(:last-child).elementor-widget__width-auto,
.elementor-widget:not(:last-child).elementor-widget__width-initial {
  margin-bottom: 0;
}

.elementor-column {
  min-height: 1px;
}

.elementor-column,
.elementor-column-wrap {
  display: flex;
  position: relative;
}

.elementor-column-wrap {
  width: 100%;
}

.elementor-column-gap-narrow
  > .elementor-column
  > .elementor-element-populated {
  padding: 5px;
}

.elementor-column-gap-default
  > .elementor-column
  > .elementor-element-populated {
  padding: 10px;
}

.elementor-column-gap-extended
  > .elementor-column
  > .elementor-element-populated {
  padding: 15px;
}

.elementor-column-gap-wide > .elementor-column > .elementor-element-populated {
  padding: 20px;
}

.elementor-column-gap-wider > .elementor-column > .elementor-element-populated {
  padding: 30px;
}

.elementor-inner-section .elementor-column-gap-no .elementor-element-populated {
  padding: 0;
}
@media (min-width: 768px) {
  .elementor-column.elementor-col-10,
  .elementor-column[data-col="10"] {
    width: 10%;
  }

  .elementor-column.elementor-col-11,
  .elementor-column[data-col="11"] {
    width: 11.111%;
  }

  .elementor-column.elementor-col-12,
  .elementor-column[data-col="12"] {
    width: 12.5%;
  }

  .elementor-column.elementor-col-14,
  .elementor-column[data-col="14"] {
    width: 14.285%;
  }

  .elementor-column.elementor-col-16,
  .elementor-column[data-col="16"] {
    width: 16.666%;
  }

  .elementor-column.elementor-col-20,
  .elementor-column[data-col="20"] {
    width: 20%;
  }

  .elementor-column.elementor-col-25,
  .elementor-column[data-col="25"] {
    width: 25%;
  }

  .elementor-column.elementor-col-30,
  .elementor-column[data-col="30"] {
    width: 30%;
  }

  .elementor-column.elementor-col-33,
  .elementor-column[data-col="33"] {
    width: 33.333%;
  }

  .elementor-column.elementor-col-40,
  .elementor-column[data-col="40"] {
    width: 40%;
  }

  .elementor-column.elementor-col-50,
  .elementor-column[data-col="50"] {
    width: 50%;
  }

  .elementor-column.elementor-col-60,
  .elementor-column[data-col="60"] {
    width: 60%;
  }

  .elementor-column.elementor-col-66,
  .elementor-column[data-col="66"] {
    width: 66.666%;
  }

  .elementor-column.elementor-col-70,
  .elementor-column[data-col="70"] {
    width: 70%;
  }

  .elementor-column.elementor-col-75,
  .elementor-column[data-col="75"] {
    width: 75%;
  }

  .elementor-column.elementor-col-80,
  .elementor-column[data-col="80"] {
    width: 80%;
  }

  .elementor-column.elementor-col-83,
  .elementor-column[data-col="83"] {
    width: 83.333%;
  }

  .elementor-column.elementor-col-90,
  .elementor-column[data-col="90"] {
    width: 90%;
  }

  .elementor-column.elementor-col-100,
  .elementor-column[data-col="100"] {
    width: 100%;
  }
}
@media (max-width: 479px) {
  .elementor-column.elementor-xs-10 {
    width: 10%;
  }

  .elementor-column.elementor-xs-11 {
    width: 11.111%;
  }

  .elementor-column.elementor-xs-12 {
    width: 12.5%;
  }

  .elementor-column.elementor-xs-14 {
    width: 14.285%;
  }

  .elementor-column.elementor-xs-16 {
    width: 16.666%;
  }

  .elementor-column.elementor-xs-20 {
    width: 20%;
  }

  .elementor-column.elementor-xs-25 {
    width: 25%;
  }

  .elementor-column.elementor-xs-30 {
    width: 30%;
  }

  .elementor-column.elementor-xs-33 {
    width: 33.333%;
  }

  .elementor-column.elementor-xs-40 {
    width: 40%;
  }

  .elementor-column.elementor-xs-50 {
    width: 50%;
  }

  .elementor-column.elementor-xs-60 {
    width: 60%;
  }

  .elementor-column.elementor-xs-66 {
    width: 66.666%;
  }

  .elementor-column.elementor-xs-70 {
    width: 70%;
  }

  .elementor-column.elementor-xs-75 {
    width: 75%;
  }

  .elementor-column.elementor-xs-80 {
    width: 80%;
  }

  .elementor-column.elementor-xs-83 {
    width: 83.333%;
  }

  .elementor-column.elementor-xs-90 {
    width: 90%;
  }

  .elementor-column.elementor-xs-100 {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .elementor-column.elementor-sm-10 {
    width: 10%;
  }

  .elementor-column.elementor-sm-11 {
    width: 11.111%;
  }

  .elementor-column.elementor-sm-12 {
    width: 12.5%;
  }

  .elementor-column.elementor-sm-14 {
    width: 14.285%;
  }

  .elementor-column.elementor-sm-16 {
    width: 16.666%;
  }

  .elementor-column.elementor-sm-20 {
    width: 20%;
  }

  .elementor-column.elementor-sm-25 {
    width: 25%;
  }

  .elementor-column.elementor-sm-30 {
    width: 30%;
  }

  .elementor-column.elementor-sm-33 {
    width: 33.333%;
  }

  .elementor-column.elementor-sm-40 {
    width: 40%;
  }

  .elementor-column.elementor-sm-50 {
    width: 50%;
  }

  .elementor-column.elementor-sm-60 {
    width: 60%;
  }

  .elementor-column.elementor-sm-66 {
    width: 66.666%;
  }

  .elementor-column.elementor-sm-70 {
    width: 70%;
  }

  .elementor-column.elementor-sm-75 {
    width: 75%;
  }

  .elementor-column.elementor-sm-80 {
    width: 80%;
  }

  .elementor-column.elementor-sm-83 {
    width: 83.333%;
  }

  .elementor-column.elementor-sm-90 {
    width: 90%;
  }

  .elementor-column.elementor-sm-100 {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .elementor-column.elementor-md-10 {
    width: 10%;
  }

  .elementor-column.elementor-md-11 {
    width: 11.111%;
  }

  .elementor-column.elementor-md-12 {
    width: 12.5%;
  }

  .elementor-column.elementor-md-14 {
    width: 14.285%;
  }

  .elementor-column.elementor-md-16 {
    width: 16.666%;
  }

  .elementor-column.elementor-md-20 {
    width: 20%;
  }

  .elementor-column.elementor-md-25 {
    width: 25%;
  }

  .elementor-column.elementor-md-30 {
    width: 30%;
  }

  .elementor-column.elementor-md-33 {
    width: 33.333%;
  }

  .elementor-column.elementor-md-40 {
    width: 40%;
  }

  .elementor-column.elementor-md-50 {
    width: 50%;
  }

  .elementor-column.elementor-md-60 {
    width: 60%;
  }

  .elementor-column.elementor-md-66 {
    width: 66.666%;
  }

  .elementor-column.elementor-md-70 {
    width: 70%;
  }

  .elementor-column.elementor-md-75 {
    width: 75%;
  }

  .elementor-column.elementor-md-80 {
    width: 80%;
  }

  .elementor-column.elementor-md-83 {
    width: 83.333%;
  }

  .elementor-column.elementor-md-90 {
    width: 90%;
  }

  .elementor-column.elementor-md-100 {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .elementor-reverse-tablet > .elementor-container > :first-child {
    box-ordinal-group: 11;

    order: 10;
  }

  .elementor-reverse-tablet > .elementor-container > :nth-child(2) {
    box-ordinal-group: 10;
    order: 9;
  }

  .elementor-reverse-tablet > .elementor-container > :nth-child(3) {
    box-ordinal-group: 9;
    order: 8;
  }

  .elementor-reverse-tablet > .elementor-container > :nth-child(4) {
    box-ordinal-group: 8;
    order: 7;
  }

  .elementor-reverse-tablet > .elementor-container > :nth-child(5) {
    box-ordinal-group: 7;
    order: 6;
  }

  .elementor-reverse-tablet > .elementor-container > :nth-child(6) {
    box-ordinal-group: 6;

    order: 5;
  }

  .elementor-reverse-tablet > .elementor-container > :nth-child(7) {
    box-ordinal-group: 5;
    order: 4;
  }

  .elementor-reverse-tablet > .elementor-container > :nth-child(8) {
    box-ordinal-group: 4;
    order: 3;
  }

  .elementor-reverse-tablet > .elementor-container > :nth-child(9) {
    box-ordinal-group: 3;
    order: 2;
  }

  .elementor-reverse-tablet > .elementor-container > :nth-child(10) {
    box-ordinal-group: 2;
    order: 1;
  }
}
@media (max-width: 767px) {
  .elementor-reverse-mobile > .elementor-container > :first-child {
    box-ordinal-group: 11;
    order: 10;
  }

  .elementor-reverse-mobile > .elementor-container > :nth-child(2) {
    box-ordinal-group: 10;
    order: 9;
  }

  .elementor-reverse-mobile > .elementor-container > :nth-child(3) {
    box-ordinal-group: 9;
    order: 8;
  }

  .elementor-reverse-mobile > .elementor-container > :nth-child(4) {
    box-ordinal-group: 8;
    order: 7;
  }

  .elementor-reverse-mobile > .elementor-container > :nth-child(5) {
    box-ordinal-group: 7;
    order: 6;
  }

  .elementor-reverse-mobile > .elementor-container > :nth-child(6) {
    box-ordinal-group: 6;
    order: 5;
  }

  .elementor-reverse-mobile > .elementor-container > :nth-child(7) {
    box-ordinal-group: 5;
    order: 4;
  }

  .elementor-reverse-mobile > .elementor-container > :nth-child(8) {
    box-ordinal-group: 4;
    order: 3;
  }

  .elementor-reverse-mobile > .elementor-container > :nth-child(9) {
    box-ordinal-group: 3;
    order: 2;
  }

  .elementor-reverse-mobile > .elementor-container > :nth-child(10) {
    box-ordinal-group: 2;
    order: 1;
  }

  .elementor-column {
    width: 100%;
  }
}

ul.elementor-icon-list-items.elementor-inline-items {
  display: flex;
  flex-wrap: wrap;
  flex-wrap: wrap;
}

ul.elementor-icon-list-items.elementor-inline-items .elementor-inline-item {
  word-break: break-word;
}

.elementor-grid {
  display: grid;
  grid-column-gap: var(--grid-column-gap);
  grid-row-gap: var(--grid-row-gap);
}

.elementor-grid .elementor-grid-item {
  min-width: 0;
}

.elementor-grid-0 .elementor-grid {
  display: inline-block;
  margin-bottom: calc(-1 * var(--grid-row-gap));
  width: 100%;
  word-spacing: var(--grid-column-gap);
}

.elementor-grid-0 .elementor-grid .elementor-grid-item {
  display: inline-block;
  margin-bottom: var(--grid-row-gap);
  word-break: break-word;
}

.elementor-grid-1 .elementor-grid {
  grid-template-columns: repeat(1, 1fr);
}

.elementor-grid-2 .elementor-grid {
  grid-template-columns: repeat(2, 1fr);
}

.elementor-grid-3 .elementor-grid {
  grid-template-columns: repeat(3, 1fr);
}

.elementor-grid-4 .elementor-grid {
  grid-template-columns: repeat(4, 1fr);
}

.elementor-grid-5 .elementor-grid {
  grid-template-columns: repeat(5, 1fr);
}

.elementor-grid-6 .elementor-grid {
  grid-template-columns: repeat(6, 1fr);
}

.elementor-grid-7 .elementor-grid {
  grid-template-columns: repeat(7, 1fr);
}

.elementor-grid-8 .elementor-grid {
  grid-template-columns: repeat(8, 1fr);
}

.elementor-grid-9 .elementor-grid {
  grid-template-columns: repeat(9, 1fr);
}

.elementor-grid-10 .elementor-grid {
  grid-template-columns: repeat(10, 1fr);
}

.elementor-grid-11 .elementor-grid {
  grid-template-columns: repeat(11, 1fr);
}

.elementor-grid-12 .elementor-grid {
  grid-template-columns: repeat(12, 1fr);
}
@media (max-width: 1024px) {
  .elementor-grid-tablet-0 .elementor-grid {
    display: inline-block;
    margin-bottom: calc(-1 * var(--grid-row-gap));
    width: 100%;
    word-spacing: var(--grid-column-gap);
  }

  .elementor-grid-tablet-0 .elementor-grid .elementor-grid-item {
    display: inline-block;
    margin-bottom: var(--grid-row-gap);
    word-break: break-word;
  }

  .elementor-grid-tablet-1 .elementor-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .elementor-grid-tablet-2 .elementor-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .elementor-grid-tablet-3 .elementor-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .elementor-grid-tablet-4 .elementor-grid {
    grid-template-columns: repeat(4, 1fr);
  }

  .elementor-grid-tablet-5 .elementor-grid {
    grid-template-columns: repeat(5, 1fr);
  }

  .elementor-grid-tablet-6 .elementor-grid {
    grid-template-columns: repeat(6, 1fr);
  }

  .elementor-grid-tablet-7 .elementor-grid {
    grid-template-columns: repeat(7, 1fr);
  }

  .elementor-grid-tablet-8 .elementor-grid {
    grid-template-columns: repeat(8, 1fr);
  }

  .elementor-grid-tablet-9 .elementor-grid {
    grid-template-columns: repeat(9, 1fr);
  }

  .elementor-grid-tablet-10 .elementor-grid {
    grid-template-columns: repeat(10, 1fr);
  }

  .elementor-grid-tablet-11 .elementor-grid {
    grid-template-columns: repeat(11, 1fr);
  }

  .elementor-grid-tablet-12 .elementor-grid {
    grid-template-columns: repeat(12, 1fr);
  }
}
@media (max-width: 767px) {
  .elementor-grid-mobile-0 .elementor-grid {
    display: inline-block;
    margin-bottom: calc(-1 * var(--grid-row-gap));
    width: 100%;
    word-spacing: var(--grid-column-gap);
  }

  .elementor-grid-mobile-0 .elementor-grid .elementor-grid-item {
    display: inline-block;
    margin-bottom: var(--grid-row-gap);
    word-break: break-word;
  }

  .elementor-grid-mobile-1 .elementor-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .elementor-grid-mobile-2 .elementor-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .elementor-grid-mobile-3 .elementor-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .elementor-grid-mobile-4 .elementor-grid {
    grid-template-columns: repeat(4, 1fr);
  }

  .elementor-grid-mobile-5 .elementor-grid {
    grid-template-columns: repeat(5, 1fr);
  }

  .elementor-grid-mobile-6 .elementor-grid {
    grid-template-columns: repeat(6, 1fr);
  }

  .elementor-grid-mobile-7 .elementor-grid {
    grid-template-columns: repeat(7, 1fr);
  }

  .elementor-grid-mobile-8 .elementor-grid {
    grid-template-columns: repeat(8, 1fr);
  }

  .elementor-grid-mobile-9 .elementor-grid {
    grid-template-columns: repeat(9, 1fr);
  }

  .elementor-grid-mobile-10 .elementor-grid {
    grid-template-columns: repeat(10, 1fr);
  }

  .elementor-grid-mobile-11 .elementor-grid {
    grid-template-columns: repeat(11, 1fr);
  }

  .elementor-grid-mobile-12 .elementor-grid {
    grid-template-columns: repeat(12, 1fr);
  }
}
@media (min-width: 1025px) {
  #elementor-device-mode::after {
    content: "desktop";
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  #elementor-device-mode::after {
    content: "tablet";
  }
}
@media (max-width: 767px) {
  #elementor-device-mode::after {
    content: "mobile";
  }
}

.elementor-form-fields-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-wrap: wrap;
}

.elementor-form-fields-wrapper.elementor-labels-above
  .elementor-field-group
  .elementor-field-subgroup,
.elementor-form-fields-wrapper.elementor-labels-above
  .elementor-field-group
  > .elementor-select-wrapper,
.elementor-form-fields-wrapper.elementor-labels-above
  .elementor-field-group
  > input,
.elementor-form-fields-wrapper.elementor-labels-above
  .elementor-field-group
  > textarea {
  flex-basis: 100%;

  max-width: 100%;
}

.elementor-form-fields-wrapper.elementor-labels-inline
  > .elementor-field-group
  .elementor-select-wrapper,
.elementor-form-fields-wrapper.elementor-labels-inline
  > .elementor-field-group
  > input {
  box-flex: 1;
  flex-grow: 1;
}

.elementor-field-group {
  align-items: center;
  box-align: center;

  flex-wrap: wrap;
  flex-wrap: wrap;
}

.elementor-field-group.elementor-field-type-submit {
  align-items: flex-end;
  box-align: end;
}

.elementor-field-group .elementor-field-textual {
  background-color: transparent;
  border: 1px solid #818a91;
  box-flex: 1;
  color: #373a3c;
  flex-grow: 1;
  max-width: 100%;
  vertical-align: middle;
  width: 100%;
}

.elementor-field-group .elementor-field-textual:focus {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  outline: 0;
}

.elementorgroup .elementortextual::input-placeholder {
  color: inherit;
  font-family: inherit;
  opacity: 0.6;
}

.elementorgroup .elementortextual:input-placeholder {
  color: inherit;
  font-family: inherit;
  opacity: 0.6;
}

.elementorgroup .elementortextual:placeholder,
.elementorgroup .elementortextual::placeholder {
  color: inherit;
  font-family: inherit;
  opacity: 0.6;
}

.elementorgroup .elementortextual::input-placeholder {
  color: inherit;
  font-family: inherit;
  opacity: 0.6;
}

.elementor-field-group .elementor-field-textual::placeholder {
  color: inherit;
  font-family: inherit;
  opacity: 0.6;
}

.elementor-field-group .elementor-select-wrapper {
  display: flex;
  position: relative;
  width: 100%;
}

.elementor-field-group .elementor-select-wrapper select {
  appearance: none;
  appearance: none;
  appearance: none;
  color: inherit;
  flex-basis: 100%;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  padding-right: 20px;
  text-transform: inherit;
}

.elementor-field-group .elementor-select-wrapper::before {
  content: "\e92a";
  font-family: eicons;
  font-size: 15px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  top: 50%;
  transform: translateY(-50%);
  transform: translateY(-50%);
  transform: translateY(-50%);
}

.elementor-field-group.elementor-field-type-select-multiple
  .elementor-select-wrapper::before {
  content: "";
}

.elementor-field-subgroup {
  display: flex;
  flex-wrap: wrap;
  flex-wrap: wrap;
}

.elementor-field-subgroup .elementor-field-option label {
  display: inline-block;
}

.elementor-field-subgroup.elementor-subgroup-inline .elementor-field-option {
  padding-right: 10px;
}

.elementor-field-subgroup:not(.elementor-subgroup-inline)
  .elementor-field-option {
  flex-basis: 100%;
}

.elementor-field-type-acceptance
  .elementor-field-subgroup
  .elementor-field-option
  input,
.elementor-field-type-acceptance
  .elementor-field-subgroup
  .elementor-field-option
  label,
.elementor-field-type-checkbox
  .elementor-field-subgroup
  .elementor-field-option
  input,
.elementor-field-type-checkbox
  .elementor-field-subgroup
  .elementor-field-option
  label,
.elementor-field-type-radio
  .elementor-field-subgroup
  .elementor-field-option
  input,
.elementor-field-type-radio
  .elementor-field-subgroup
  .elementor-field-option
  label {
  display: inline;
}

.elementor-field-label {
  cursor: pointer;
}

.elementor-mark-required .elementor-field-label::after {
  color: red;
  content: "*";
  padding-left: 0.2em;
}

.elementor-field-textual {
  border-radius: 3px;
  border-radius: 3px;
  font-size: 15px;
  line-height: 1.4;
  min-height: 40px;
  padding: 5px 14px;
}

.elementor-field-textual.elementor-size-xs {
  border-radius: 2px;
  border-radius: 2px;
  font-size: 13px;
  min-height: 33px;
  padding: 4px 12px;
}

.elementor-field-textual.elementor-size-md {
  border-radius: 4px;
  border-radius: 4px;
  font-size: 16px;
  min-height: 47px;
  padding: 6px 16px;
}

.elementor-field-textual.elementor-size-lg {
  border-radius: 5px;
  border-radius: 5px;
  font-size: 18px;
  min-height: 59px;
  padding: 7px 20px;
}

.elementor-field-textual.elementor-size-xl {
  border-radius: 6px;
  border-radius: 6px;
  font-size: 20px;
  min-height: 72px;
  padding: 8px 24px;
}

.elementor-button-align-stretch
  .elementor-field-type-submit:not(.e-form__buttons__wrapper)
  .elementor-button {
  flex-basis: 100%;
}

.elementor-button-align-stretch .e-form__buttons__wrapper {
  box-flex: 1;
  flex-basis: 50%;
  flex-grow: 1;
}

.elementor-button-align-stretch .e-form__buttons__wrapper__button {
  flex-basis: 100%;
}

.elementor-button-align-center .e-form__buttons,
.elementor-button-align-center .elementor-field-type-submit {
  box-pack: center;

  justify-content: center;
}

.elementor-button-align-start .e-form__buttons,
.elementor-button-align-start .elementor-field-type-submit {
  box-pack: start;
  justify-content: flex-start;
}

.elementor-button-align-end .e-form__buttons,
.elementor-button-align-end .elementor-field-type-submit {
  box-pack: end;

  justify-content: flex-end;
}

.elementor-button-align-center
  .elementor-field-type-submit:not(.e-form__buttons__wrapper)
  .elementor-button,
.elementor-button-align-end
  .elementor-field-type-submit:not(.e-form__buttons__wrapper)
  .elementor-button,
.elementor-button-align-start
  .elementor-field-type-submit:not(.e-form__buttons__wrapper)
  .elementor-button {
  flex-basis: auto;
}

.elementor-button-align-center .e-form__buttons__wrapper,
.elementor-button-align-end .e-form__buttons__wrapper,
.elementor-button-align-start .e-form__buttons__wrapper {
  box-flex: initial;
  flex-grow: 0;
}

.elementor-button-align-center .e-form__buttons__wrapper,
.elementor-button-align-center .e-form__buttons__wrapper__button,
.elementor-button-align-end .e-form__buttons__wrapper,
.elementor-button-align-end .e-form__buttons__wrapper__button,
.elementor-button-align-start .e-form__buttons__wrapper,
.elementor-button-align-start .e-form__buttons__wrapper__button {
  flex-basis: auto;
}
@media screen and (max-width: 1024px) {
  .elementor-tablet-button-align-stretch
    .elementor-field-type-submit:not(.e-form__buttons__wrapper)
    .elementor-button {
    flex-basis: 100%;
  }

  .elementor-tablet-button-align-stretch .e-form__buttons__wrapper {
    box-flex: 1;
    flex-basis: 50%;
    flex-grow: 1;
  }

  .elementor-tablet-button-align-stretch .e-form__buttons__wrapper__button {
    flex-basis: 100%;
  }

  .elementor-tablet-button-align-center .e-form__buttons,
  .elementor-tablet-button-align-center .elementor-field-type-submit {
    box-pack: center;

    justify-content: center;
  }

  .elementor-tablet-button-align-start .e-form__buttons,
  .elementor-tablet-button-align-start .elementor-field-type-submit {
    box-pack: start;

    justify-content: flex-start;
  }

  .elementor-tablet-button-align-end .e-form__buttons,
  .elementor-tablet-button-align-end .elementor-field-type-submit {
    box-pack: end;

    justify-content: flex-end;
  }

  .elementor-tablet-button-align-center
    .elementor-field-type-submit:not(.e-form__buttons__wrapper)
    .elementor-button,
  .elementor-tablet-button-align-end
    .elementor-field-type-submit:not(.e-form__buttons__wrapper)
    .elementor-button,
  .elementor-tablet-button-align-start
    .elementor-field-type-submit:not(.e-form__buttons__wrapper)
    .elementor-button {
    flex-basis: auto;
  }

  .elementor-tablet-button-align-center .e-form__buttons__wrapper,
  .elementor-tablet-button-align-end .e-form__buttons__wrapper,
  .elementor-tablet-button-align-start .e-form__buttons__wrapper {
    box-flex: initial;
    flex-grow: 0;
  }

  .elementor-tablet-button-align-center .e-form__buttons__wrapper,
  .elementor-tablet-button-align-center .e-form__buttons__wrapper__button,
  .elementor-tablet-button-align-end .e-form__buttons__wrapper,
  .elementor-tablet-button-align-end .e-form__buttons__wrapper__button,
  .elementor-tablet-button-align-start .e-form__buttons__wrapper,
  .elementor-tablet-button-align-start .e-form__buttons__wrapper__button {
    flex-basis: auto;
  }
}
@media screen and (max-width: 767px) {
  .elementor-mobile-button-align-stretch
    .elementor-field-type-submit:not(.e-form__buttons__wrapper)
    .elementor-button {
    flex-basis: 100%;
  }

  .elementor-mobile-button-align-stretch .e-form__buttons__wrapper {
    box-flex: 1;
    flex-basis: 50%;
    flex-grow: 1;
  }

  .elementor-mobile-button-align-stretch .e-form__buttons__wrapper__button {
    flex-basis: 100%;
  }

  .elementor-mobile-button-align-center .e-form__buttons,
  .elementor-mobile-button-align-center .elementor-field-type-submit {
    box-pack: center;

    justify-content: center;
  }

  .elementor-mobile-button-align-start .e-form__buttons,
  .elementor-mobile-button-align-start .elementor-field-type-submit {
    box-pack: start;

    justify-content: flex-start;
  }

  .elementor-mobile-button-align-end .e-form__buttons,
  .elementor-mobile-button-align-end .elementor-field-type-submit {
    box-pack: end;

    justify-content: flex-end;
  }

  .elementor-mobile-button-align-center
    .elementor-field-type-submit:not(.e-form__buttons__wrapper)
    .elementor-button,
  .elementor-mobile-button-align-end
    .elementor-field-type-submit:not(.e-form__buttons__wrapper)
    .elementor-button,
  .elementor-mobile-button-align-start
    .elementor-field-type-submit:not(.e-form__buttons__wrapper)
    .elementor-button {
    flex-basis: auto;
  }

  .elementor-mobile-button-align-center .e-form__buttons__wrapper,
  .elementor-mobile-button-align-end .e-form__buttons__wrapper,
  .elementor-mobile-button-align-start .e-form__buttons__wrapper {
    box-flex: initial;
    flex-grow: 0;
  }

  .elementor-mobile-button-align-center .e-form__buttons__wrapper,
  .elementor-mobile-button-align-center .e-form__buttons__wrapper__button,
  .elementor-mobile-button-align-end .e-form__buttons__wrapper,
  .elementor-mobile-button-align-end .e-form__buttons__wrapper__button,
  .elementor-mobile-button-align-start .e-form__buttons__wrapper,
  .elementor-mobile-button-align-start .e-form__buttons__wrapper__button {
    flex-basis: auto;
  }
}

.elementor-error .elementor-field {
  border-color: #d9534f;
}

.elementor-error .help-inline {
  color: #d9534f;
  font-size: 0.9em;
}

.elementor-message {
  font-size: 1em;
  line-height: 1;
  margin: 10px 0;
}

.elementor-message::before {
  content: "\e90e";
  display: inline-block;
  font-family: eicons;
  font-style: normal;
  font-weight: 400;
  margin-right: 5px;
  vertical-align: middle;
}

.elementor-message.elementor-message-danger {
  color: #d9534f;
}

.elementor-message.elementor-message-danger::before {
  content: "\e87f";
}

.elementor-message.form-message-success {
  color: #5cb85c;
}

.elementor-form .elementor-button {
  border: none;
  padding-bottom: 0;
  padding-top: 0;
}

.elementor-form .elementor-button > span {
  box-pack: center;
  display: flex;
  justify-content: center;
}

.elementor-form .elementor-button.elementor-size-xs {
  min-height: 33px;
}

.elementor-form .elementor-button.elementor-size-sm {
  min-height: 40px;
}

.elementor-form .elementor-button.elementor-size-md {
  min-height: 47px;
}

.elementor-form .elementor-button.elementor-size-lg {
  min-height: 59px;
}

.elementor-form .elementor-button.elementor-size-xl {
  min-height: 72px;
}

.elementor-element .elementor-widget-container {
  transition: background 0.3s, border 0.3s, -webkit-border-radius 0.3s,
    -webkit-box-shadow 0.3s;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  transition: background 0.3s, border 0.3s, -webkit-border-radius 0.3s,
    -webkit-box-shadow 0.3s;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s,
    -webkit-border-radius 0.3s, -webkit-box-shadow 0.3s;
}

.elementor-accordion {
  text-align: left;
}

.elementor-accordion .elementor-accordion-item {
  border: 1px solid #d4d4d4;
}

.elementor-accordion .elementor-accordion-item + .elementor-accordion-item {
  border-top: none;
}

.elementor-accordion .elementor-tab-title {
  cursor: pointer;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  outline: none;
  padding: 15px 20px;
}

.elementor-accordion .elementor-tab-title .elementor-accordion-icon {
  display: inline-block;
  width: 1.5em;
}

.elementor-accordion
  .elementor-tab-title
  .elementor-accordion-icon.elementor-accordion-icon-right {
  float: right;
  text-align: right;
}

.elementor-accordion
  .elementor-tab-title
  .elementor-accordion-icon.elementor-accordion-icon-left {
  float: left;
  text-align: left;
}

.elementor-accordion
  .elementor-tab-title
  .elementor-accordion-icon
  .elementor-accordion-icon-closed {
  display: block;
}

.elementor-accordion
  .elementor-tab-title
  .elementor-accordion-icon
  .elementor-accordion-icon-opened,
.elementor-accordion
  .elementor-tab-title.elementor-active
  .elementor-accordion-icon-closed {
  display: none;
}

.elementor-accordion
  .elementor-tab-title.elementor-active
  .elementor-accordion-icon-opened {
  display: block;
}

.elementor-accordion .elementor-tab-content {
  border-top: 1px solid #d4d4d4;
  display: none;
  padding: 15px 20px;
}
@media (max-width: 767px) {
  .elementor-accordion .elementor-tab-title {
    padding: 12px 15px;
  }

  .elementor-accordion .elementor-tab-title .elementor-accordion-icon {
    width: 1.2em;
  }

  .elementor-accordion .elementor-tab-content {
    padding: 7px 15px;
  }
}

.elementor-alert {
  border-left: 5px solid transparent;
  padding: 15px;
  position: relative;
  text-align: left;
}

.elementor-alert .elementor-alert-title {
  display: block;
  font-weight: 700;
}

.elementor-alert .elementor-alert-description {
  font-size: 13px;
}

.elementor-alert button.elementor-alert-dismiss {
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  font-size: 20px;
  line-height: 1;
  padding: 3px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.elementor-alert.elementor-alert-info {
  background-color: #d9edf7;
  border-color: #bcdff1;
  color: #31708f;
}

.elementor-alert.elementor-alert-success {
  background-color: #dff0d8;
  border-color: #cae6be;
  color: #3c763d;
}

.elementor-alert.elementor-alert-warning {
  background-color: #fcf8e3;
  border-color: #f9f0c3;
  color: #8a6d3b;
}

.elementor-alert.elementor-alert-danger {
  background-color: #f2dede;
  border-color: #e8c4c4;
  color: #a94442;
}
@media (max-width: 767px) {
  .elementor-alert {
    padding: 10px;
  }

  .elementor-alert button.elementor-alert-dismiss {
    right: 7px;
    top: 7px;
  }
}

.elementor-tab-title a {
  color: inherit;
}

.elementor-button {
  background-color: #818a91;
  border-radius: 3px;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  fill: #fff;
  font-size: 15px;
  line-height: 1;
  padding: 12px 24px;
  text-align: center;
  transition: all 0.3s;
  transition: all 0.3s;
  transition: all 0.3s;
}

.elementor-button:focus,
.elementor-button:hover,
.elementor-button:visited {
  color: #fff;
}

.elementor-button-content-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}

.elementor-button-icon {
  box-flex: 0;
  box-ordinal-group: 6;
  flex-grow: 0;

  order: 5;
}

.elementor-button-icon svg {
  width: 1em;
}

.elementor-button-text {
  box-flex: 1;
  box-ordinal-group: 11;
  display: inline-block;
  flex-grow: 1;

  order: 10;
}

.elementor-button.elementor-size-xs {
  border-radius: 2px;
  border-radius: 2px;
  font-size: 13px;
  padding: 10px 20px;
}

.elementor-button.elementor-size-md {
  border-radius: 4px;
  border-radius: 4px;
  font-size: 16px;
  padding: 15px 30px;
}

.elementor-button.elementor-size-lg {
  border-radius: 5px;
  border-radius: 5px;
  font-size: 18px;
  padding: 20px 40px;
}

.elementor-button.elementor-size-xl {
  border-radius: 6px;
  border-radius: 6px;
  font-size: 20px;
  padding: 25px 50px;
}

.elementor-button .elementor-align-icon-right {
  box-ordinal-group: 16;
  margin-left: 5px;
  order: 15;
}

.elementor-button .elementor-align-icon-left {
  box-ordinal-group: 6;
  margin-right: 5px;
  order: 5;
}

.elementor-button span {
  text-decoration: inherit;
}

.elementor-menu-cart__toggle .elementor-button-icon {
  box-ordinal-group: 16;
  order: 15;
}

.elementor-element.elementor-button-info .elementor-button {
  background-color: #5bc0de;
}

.elementor-element.elementor-button-success .elementor-button {
  background-color: #5cb85c;
}

.elementor-element.elementor-button-warning .elementor-button {
  background-color: #f0ad4e;
}

.elementor-element.elementor-button-danger .elementor-button {
  background-color: #d9534f;
}

.elementor-widget-button .elementor-button .elementor-button-info {
  background-color: #5bc0de;
}

.elementor-widget-button .elementor-button .elementor-button-success {
  background-color: #5cb85c;
}

.elementor-widget-button .elementor-button .elementor-button-warning {
  background-color: #f0ad4e;
}

.elementor-widget-button .elementor-button .elementor-button-danger {
  background-color: #d9534f;
}

.elementor-counter .elementor-counter-number-wrapper {
  color: #222;
  display: flex;
  font-size: 69px;
  font-weight: 600;
  line-height: 1;
}

.elementor-counter .elementor-counter-number-prefix,
.elementor-counter .elementor-counter-number-suffix {
  box-flex: 1;
  flex-grow: 1;

  white-space: pre-wrap;
}

.elementor-counter .elementor-counter-number-prefix {
  text-align: right;
}

.elementor-counter .elementor-counter-number-suffix {
  text-align: left;
}

.elementor-counter .elementor-counter-title {
  color: #666;
  font-size: 19px;
  font-weight: 400;
  line-height: 2.5;
  text-align: center;
}

.elementor-widget-divider {
  --divider-border-style: none;
  --divider-border-width: 1px;
  --divider-color: #2c2c2c;
  --divider-icon-size: 20px;
  --divider-element-spacing: 10px;
  --divider-pattern-height: 24px;
  --divider-pattern-size: 20px;
  --divider-pattern-url: none;
  --divider-pattern-repeat: repeat-x;
}

.elementor-widget-divider .elementor-divider {
  display: flex;
}

.elementor-widget-divider .elementor-divider__text {
  font-size: 15px;
  line-height: 1;
  max-width: 95%;
}

.elementor-widget-divider .elementor-divider__element {
  flex-shrink: 0;
  margin: 0 var(--divider-element-spacing);
}

.elementor-widget-divider .elementor-icon {
  font-size: var(--divider-icon-size);
}

.elementor-widget-divider .elementor-divider-separator {
  direction: ltr;
  display: flex;
  margin: 0;
}

.elementor-widget-divider--view-line_icon .elementor-divider-separator,
.elementor-widget-divider--view-line_text .elementor-divider-separator {
  align-items: center;
  box-align: center;
}

.elementor-widget-divider--view-line_icon .elementor-divider-separator::after,
.elementor-widget-divider--view-line_icon .elementor-divider-separator::before,
.elementor-widget-divider--view-line_text .elementor-divider-separator::after,
.elementor-widget-divider--view-line_text .elementor-divider-separator::before {
  border-bottom: 0;
  border-top: var(--divider-border-width) var(--divider-border-style)
    var(--divider-color);
  box-flex: 1;
  content: "";
  display: block;
  flex-grow: 1;
}

.elementor-widget-divider--element-align-left
  .elementor-divider
  .elementor-divider-separator
  > .elementor-divider__svg:first-of-type {
  box-flex: 0;
  flex-grow: 0;

  flex-shrink: 100;
}

.elementor-widget-divider--element-align-left
  .elementor-divider-separator::before {
  content: none;
}

.elementor-widget-divider--element-align-left .elementor-divider__element {
  margin-left: 0;
}

.elementor-widget-divider--element-align-right
  .elementor-divider
  .elementor-divider-separator
  > .elementor-divider__svg:last-of-type {
  box-flex: 0;
  flex-grow: 0;

  flex-shrink: 100;
}

.elementor-widget-divider--element-align-right
  .elementor-divider-separator::after {
  content: none;
}

.elementor-widget-divider--element-align-right .elementor-divider__element {
  margin-right: 0;
}

.elementor-widget-divider:not(.elementor-widget-divider--view-line_text):not(.elementor-widget-divider--view-line_icon)
  .elementor-divider-separator {
  border-top: var(--divider-border-width) var(--divider-border-style)
    var(--divider-color);
}

.elementor-widget-divider--separator-type-pattern {
  --divider-border-style: none;
}

.elementor-widget-divider--separator-type-pattern.elementor-widget-divider--view-line
  .elementor-divider-separator,
.elementor-widget-divider--separator-type-pattern:not(.elementor-widget-divider--view-line)
  .elementor-divider-separator::after,
.elementor-widget-divider--separator-type-pattern:not(.elementor-widget-divider--view-line)
  .elementor-divider-separator::before,
.elementor-widget-divider--separator-type-pattern:not([class*="elementor-widget-divider--view"])
  .elementor-divider-separator {
  background-color: var(--divider-color);
  mask-image: var(--divider-pattern-url);
  mask-image: var(--divider-pattern-url);
  mask-repeat: var(--divider-pattern-repeat);
  mask-repeat: var(--divider-pattern-repeat);
  mask-size: var(--divider-pattern-size) 100%;
  mask-size: var(--divider-pattern-size) 100%;
  min-height: var(--divider-pattern-height);
  width: 100%;
}

.elementor-widget-divider--no-spacing {
  --divider-pattern-size: auto;
}

.elementor-widget-divider--bg-round {
  --divider-pattern-repeat: round;
}

.rtl .elementor-widget-divider .elementor-divider__text {
  direction: rtl;
}

.elementor-image-gallery .gallery-item {
  display: inline-block;
  margin: 0 auto;
  max-width: 100%;
  text-align: center;
  vertical-align: top;
  width: 100%;
}

.elementor-image-gallery .gallery-item img {
  margin: 0 auto;
}

.elementor-image-gallery .gallery-item .gallery-caption {
  margin: 0;
}
@media (min-width: 768px) {
  .elementor-image-gallery .gallery-columns-2 .gallery-item {
    max-width: 50%;
  }

  .elementor-image-gallery .gallery-columns-3 .gallery-item {
    max-width: 33.33%;
  }

  .elementor-image-gallery .gallery-columns-4 .gallery-item {
    max-width: 25%;
  }

  .elementor-image-gallery .gallery-columns-5 .gallery-item {
    max-width: 20%;
  }

  .elementor-image-gallery .gallery-columns-6 .gallery-item {
    max-width: 16.666%;
  }

  .elementor-image-gallery .gallery-columns-7 .gallery-item {
    max-width: 14.28%;
  }

  .elementor-image-gallery .gallery-columns-8 .gallery-item {
    max-width: 12.5%;
  }

  .elementor-image-gallery .gallery-columns-9 .gallery-item {
    max-width: 11.11%;
  }

  .elementor-image-gallery .gallery-columns-10 .gallery-item {
    max-width: 10%;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .elementor-image-gallery .gallery.gallery-columns-2 .gallery-item,
  .elementor-image-gallery .gallery.gallery-columns-3 .gallery-item,
  .elementor-image-gallery .gallery.gallery-columns-4 .gallery-item,
  .elementor-image-gallery .gallery.gallery-columns-5 .gallery-item,
  .elementor-image-gallery .gallery.gallery-columns-6 .gallery-item,
  .elementor-image-gallery .gallery.gallery-columns-7 .gallery-item,
  .elementor-image-gallery .gallery.gallery-columns-8 .gallery-item,
  .elementor-image-gallery .gallery.gallery-columns-9 .gallery-item,
  .elementor-image-gallery .gallery.gallery-columns-10 .gallery-item {
    max-width: 50%;
  }
}
@media (max-width: 479px) {
  .elementor-image-gallery .gallery.gallery-columns-2 .gallery-item,
  .elementor-image-gallery .gallery.gallery-columns-3 .gallery-item,
  .elementor-image-gallery .gallery.gallery-columns-4 .gallery-item,
  .elementor-image-gallery .gallery.gallery-columns-5 .gallery-item,
  .elementor-image-gallery .gallery.gallery-columns-6 .gallery-item,
  .elementor-image-gallery .gallery.gallery-columns-7 .gallery-item,
  .elementor-image-gallery .gallery.gallery-columns-8 .gallery-item,
  .elementor-image-gallery .gallery.gallery-columns-9 .gallery-item,
  .elementor-image-gallery .gallery.gallery-columns-10 .gallery-item {
    max-width: 100%;
  }
}

.elementor-widget-google_maps iframe {
  height: 300px;
}

.elementor-heading-title {
  line-height: 1;
  margin: 0;
  padding: 0;
}

.elementor-widget-heading
  .elementor-heading-title[class*="elementor-size-"]
  > a {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}

.elementor-widget-heading .elementor-heading-title.elementor-size-small {
  font-size: 15px;
}

.elementor-widget-heading .elementor-heading-title.elementor-size-medium {
  font-size: 19px;
}

.elementor-widget-heading .elementor-heading-title.elementor-size-large {
  font-size: 29px;
}

.elementor-widget-heading .elementor-heading-title.elementor-size-xl {
  font-size: 39px;
}

.elementor-widget-heading .elementor-heading-title.elementor-size-xxl {
  font-size: 59px;
}

.elementor-icon {
  color: #818a91;
  display: inline-block;
  font-size: 50px;
  line-height: 1;
  text-align: center;
  transition: all 0.3s;
  transition: all 0.3s;
  transition: all 0.3s;
}

.elementor-icon:hover {
  color: #818a91;
}

.elementor-icon i,
.elementor-icon svg {
  display: block;
  height: 1em;
  position: relative;
  width: 1em;
}

.elementor-icon i::before,
.elementor-icon svg::before {
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  transform: translateX(-50%);
  transform: translateX(-50%);
}

.elementor-icon i.fad {
  width: auto;
}

.elementor-view-stacked .elementor-icon {
  background-color: #818a91;
  color: #fff;
  fill: #fff;
  padding: 0.5em;
}

.elementor-view-framed .elementor-icon {
  background-color: transparent;
  border: 3px solid #818a91;
  color: #818a91;
  padding: 0.5em;
}

.elementor-shape-circle .elementor-icon {
  border-radius: 50%;
  border-radius: 50%;
}
@media (min-width: 768px) {
  .elementor-widget-icon-box.elementor-position-left
    .elementor-icon-box-wrapper,
  .elementor-widget-icon-box.elementor-position-right
    .elementor-icon-box-wrapper {
    display: flex;
  }

  .elementor-widget-icon-box.elementor-position-left .elementor-icon-box-icon,
  .elementor-widget-icon-box.elementor-position-right .elementor-icon-box-icon {
    box-flex: 0;
    display: inline-box;
    display: inline-flexbox;
    display: inline-flex;
    flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .elementor-widget-icon-box.elementor-position-right
    .elementor-icon-box-wrapper {
    box-direction: reverse;
    box-orient: horizontal;
    flex-direction: row-reverse;
    flex-direction: row-reverse;
    text-align: right;
  }

  .elementor-widget-icon-box.elementor-position-left
    .elementor-icon-box-wrapper {
    box-direction: normal;
    box-orient: horizontal;
    flex-direction: row;
    flex-direction: row;
    text-align: left;
  }

  .elementor-widget-icon-box.elementor-position-top .elementor-icon-box-img {
    margin: auto;
  }

  .elementor-widget-icon-box.elementor-vertical-align-top
    .elementor-icon-box-wrapper {
    align-items: flex-start;
    box-align: start;
  }

  .elementor-widget-icon-box.elementor-vertical-align-middle
    .elementor-icon-box-wrapper {
    align-items: center;
    box-align: center;
  }

  .elementor-widget-icon-box.elementor-vertical-align-bottom
    .elementor-icon-box-wrapper {
    align-items: flex-end;
    box-align: end;
  }
}
@media (max-width: 767px) {
  .elementor-widget-icon-box .elementor-icon-box-icon {
    margin-bottom: 15px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.elementor-widget-icon-box .elementor-icon-box-wrapper {
  text-align: center;
}

.elementor-widget-icon-box .elementor-icon-box-title a {
  color: inherit;
}

.elementor-widget-icon-box .elementor-icon-box-content {
  box-flex: 1;
  flex-grow: 1;
}

.elementor-widget-icon-box .elementor-icon-box-description {
  margin: 0;
}

.elementor-widget.elementor-icon-list--layout-inline
  .elementor-widget-container {
  overflow: hidden;
}

.elementor-widget .elementor-icon-list-items.elementor-inline-items {
  margin-left: -8px;
  margin-right: -8px;
}

.elementor-widget
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item {
  margin-left: 8px;
  margin-right: 8px;
}

.elementor-widget
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item::after {
  border-bottom: 0;
  border-left-width: 1px;
  border-right: 0;
  border-style: solid;
  border-top: 0;
  height: 100%;
  left: auto;
  position: relative;
  right: auto;
  right: -8px;
  top: 50%;
  transform: translateY(-50%);
  transform: translateY(-50%);
  transform: translateY(-50%);
  width: auto;
}

.elementor-widget .elementor-icon-list-items {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.elementor-widget .elementor-icon-list-item {
  margin: 0;
  padding: 0;
  position: relative;
}

.elementor-widget .elementor-icon-list-item::after {
  bottom: 0;
  position: absolute;
  width: 100%;
}

.elementor-widget .elementor-icon-list-item,
.elementor-widget .elementor-icon-list-item a {
  align-items: center;
  display: flex;
}

.elementor-widget .elementor-icon-list-icon + .elementor-icon-list-text {
  align-self: center;

  padding-left: 5px;
}

.elementor-widget .elementor-icon-list-icon {
  flex-shrink: 0;
}

.elementor-widget .elementor-icon-list-icon i {
  width: 1.25em;
}

.elementor-widget .elementor-icon-list-icon svg {
  width: 1em;
}

.elementor-widget.elementor-list-item-link-full_width a {
  width: 100%;
}

.elementor-widget.elementor-align-center .elementor-icon-list-item,
.elementor-widget.elementor-align-center .elementor-icon-list-item a {
  justify-content: center;
}

.elementor-widget.elementor-align-center .elementor-icon-list-item::after {
  margin: auto;
}

.elementor-widget.elementor-align-center .elementor-inline-items {
  box-pack: center;

  justify-content: center;
}

.elementor-widget.elementor-align-left .elementor-icon-list-item,
.elementor-widget.elementor-align-left .elementor-icon-list-item a {
  box-pack: start;

  justify-content: flex-start;
  text-align: left;
}

.elementor-widget.elementor-align-left .elementor-inline-items {
  box-pack: start;

  justify-content: flex-start;
}

.elementor-widget.elementor-align-right .elementor-icon-list-item,
.elementor-widget.elementor-align-right .elementor-icon-list-item a {
  box-pack: end;

  justify-content: flex-end;
  text-align: right;
}

.elementor-widget.elementor-align-right .elementor-icon-list-items {
  box-pack: end;

  justify-content: flex-end;
}

.elementor-widget:not(.elementor-align-right) .elementor-icon-list-item::after {
  left: 0;
}

.elementor-widget:not(.elementor-align-left) .elementor-icon-list-item::after {
  right: 0;
}
@media (max-width: 1024px) {
  .elementor-widget.elementor-tablet-align-center .elementor-icon-list-item,
  .elementor-widget.elementor-tablet-align-center .elementor-icon-list-item a,
  .elementor-widget.elementor-tablet-align-center .elementor-icon-list-items {
    box-pack: center;

    justify-content: center;
  }

  .elementor-widget.elementor-tablet-align-center
    .elementor-icon-list-item::after {
    margin: auto;
  }

  .elementor-widget.elementor-tablet-align-left .elementor-icon-list-items {
    box-pack: start;

    justify-content: flex-start;
  }

  .elementor-widget.elementor-tablet-align-left .elementor-icon-list-item,
  .elementor-widget.elementor-tablet-align-left .elementor-icon-list-item a {
    box-pack: start;

    justify-content: flex-start;
    text-align: left;
  }

  .elementor-widget.elementor-tablet-align-right .elementor-icon-list-items {
    box-pack: end;

    justify-content: flex-end;
  }

  .elementor-widget.elementor-tablet-align-right .elementor-icon-list-item,
  .elementor-widget.elementor-tablet-align-right .elementor-icon-list-item a {
    box-pack: end;

    justify-content: flex-end;
    text-align: right;
  }

  .elementor-widget:not(.elementor-tablet-align-right)
    .elementor-icon-list-item::after {
    left: 0;
  }

  .elementor-widget:not(.elementor-tablet-align-left)
    .elementor-icon-list-item::after {
    right: 0;
  }
}
@media (max-width: 767px) {
  .elementor-widget.elementor-mobile-align-center .elementor-icon-list-item,
  .elementor-widget.elementor-mobile-align-center .elementor-icon-list-item a,
  .elementor-widget.elementor-mobile-align-center .elementor-icon-list-items {
    box-pack: center;

    justify-content: center;
  }

  .elementor-widget.elementor-mobile-align-center
    .elementor-icon-list-item::after {
    margin: auto;
  }

  .elementor-widget.elementor-mobile-align-left .elementor-icon-list-items {
    box-pack: start;

    justify-content: flex-start;
  }

  .elementor-widget.elementor-mobile-align-left .elementor-icon-list-item,
  .elementor-widget.elementor-mobile-align-left .elementor-icon-list-item a {
    box-pack: start;

    justify-content: flex-start;
    text-align: left;
  }

  .elementor-widget.elementor-mobile-align-right .elementor-icon-list-items {
    box-pack: end;

    justify-content: flex-end;
  }

  .elementor-widget.elementor-mobile-align-right .elementor-icon-list-item,
  .elementor-widget.elementor-mobile-align-right .elementor-icon-list-item a {
    box-pack: end;

    justify-content: flex-end;
    text-align: right;
  }

  .elementor-widget:not(.elementor-mobile-align-right)
    .elementor-icon-list-item::after {
    left: 0;
  }

  .elementor-widget:not(.elementor-mobile-align-left)
    .elementor-icon-list-item::after {
    right: 0;
  }
}

.elementor-widget-image {
  text-align: center;
}

.elementor-widget-image .elementor-image > a,
.elementor-widget-image .elementor-image figure > a {
  display: inline-block;
}

.elementor-widget-image .elementor-image > a img[src$=".svg"],
.elementor-widget-image .elementor-image figure > a img[src$=".svg"] {
  width: 48px;
}

.elementor-widget-image .elementor-image img {
  display: inline-block;
  vertical-align: middle;
}

.elementor-widget-image .elementor-image.elementor-image-shape-circle {
  border-radius: 50%;
  border-radius: 50%;
}

.elementor-widget-image-box .elementor-image-box-content {
  width: 100%;
}
@media (min-width: 768px) {
  .elementor-widget-image-box.elementor-position-left
    .elementor-image-box-wrapper,
  .elementor-widget-image-box.elementor-position-right
    .elementor-image-box-wrapper {
    display: flex;
  }

  .elementor-widget-image-box.elementor-position-right
    .elementor-image-box-wrapper {
    box-direction: reverse;
    box-orient: horizontal;
    flex-direction: row-reverse;
    flex-direction: row-reverse;
    text-align: right;
  }

  .elementor-widget-image-box.elementor-position-left
    .elementor-image-box-wrapper {
    box-direction: normal;
    box-orient: horizontal;
    flex-direction: row;
    flex-direction: row;
    text-align: left;
  }

  .elementor-widget-image-box.elementor-position-top .elementor-image-box-img {
    margin: auto;
  }

  .elementor-widget-image-box.elementor-vertical-align-top
    .elementor-image-box-wrapper {
    align-items: flex-start;
    box-align: start;
  }

  .elementor-widget-image-box.elementor-vertical-align-middle
    .elementor-image-box-wrapper {
    align-items: center;
    box-align: center;
  }

  .elementor-widget-image-box.elementor-vertical-align-bottom
    .elementor-image-box-wrapper {
    align-items: flex-end;
    box-align: end;
  }
}
@media (max-width: 767px) {
  .elementor-widget-image-box .elementor-image-box-img {
    margin-bottom: 15px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.elementor-widget-image-box .elementor-image-box-img {
  display: inline-block;
}

.elementor-widget-image-box .elementor-image-box-title a {
  color: inherit;
}

.elementor-widget-image-box .elementor-image-box-wrapper {
  text-align: center;
}

.elementor-widget-image-box .elementor-image-box-description {
  margin: 0;
}

.elementor-widget-image-carousel .swiper-container {
  position: static;
}

.elementor-widget-image-carousel .swiper-container .swiper-slide figure {
  line-height: inherit;
}

.elementor-widget-image-carousel .swiper-slide {
  text-align: center;
}

.elementor-image-gallery figure img {
  display: block;
}

.elementor-image-gallery figure figcaption {
  width: 100%;
}

.gallery-spacing-custom .elementor-image-gallery .gallery-icon {
  padding: 0;
}

body.elementor-page .elementor-widget-menu-anchor {
  margin-bottom: 0;
}

.elementor-widget-progress {
  text-align: left;
}

.elementor-progress-wrapper {
  background-color: #eee;
  border-radius: 2px;
  border-radius: 2px;
  color: #fff;
  height: 100%;
  position: relative;
}

.elementor-progress-bar {
  background-color: #818a91;
  border-radius: 2px;
  border-radius: 2px;
  display: flex;
  font-size: 11px;
  height: 30px;
  line-height: 30px;
  transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
  width: 0;
}

.elementor-progress-text {
  box-flex: 1;
  flex-grow: 1;

  overflow: hidden;
  padding-left: 15px;
  text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.elementor-progress-percentage {
  padding-right: 15px;
}

.elementor-widget-progress
  .elementor-progress-wrapper.progress-info
  .elementor-progress-bar {
  background-color: #5bc0de;
}

.elementor-widget-progress
  .elementor-progress-wrapper.progress-success
  .elementor-progress-bar {
  background-color: #5cb85c;
}

.elementor-widget-progress
  .elementor-progress-wrapper.progress-warning
  .elementor-progress-bar {
  background-color: #f0ad4e;
}

.elementor-widget-progress
  .elementor-progress-wrapper.progress-danger
  .elementor-progress-bar {
  background-color: #d9534f;
}

.elementor-progress .elementor-title {
  display: block;
}
@media (max-width: 767px) {
  .elementor-progress-text {
    padding-left: 10px;
  }
}

.elementor-widget-social-icons.elementor-grid-0 .elementor-widget-container,
.elementor-widget-social-icons.elementor-grid-mobile-0
  .elementor-widget-container,
.elementor-widget-social-icons.elementor-grid-tablet-0
  .elementor-widget-container {
  font-size: 0;
  line-height: 1;
}

.elementor-widget-social-icons:not(.elementor-grid-0):not(.elementor-grid-tablet-0):not(.elementor-grid-mobile-0)
  .elementor-grid {
  display: inline-grid;
}

.elementor-widget-social-icons .elementor-grid {
  box-pack: var(--justify-content, center);
  grid-column-gap: var(--grid-column-gap, 5px);
  grid-row-gap: var(--grid-row-gap, 5px);
  grid-template-columns: var(--grid-template-columns);
  justify-content: var(--justify-content, center);
  justify-items: var(--justify-content, center);
}

.elementor-social-icon {
  align-items: center;
  background-color: #818a91;
  box-align: center;
  box-pack: center;
  cursor: pointer;
  display: inline-box;
  display: inline-flexbox;
  display: inline-flex;

  font-size: var(--icon-size, 25px);
  height: calc(var(--icon-size, 25px) + 2 * var(--icon-padding, 0.5em));
  justify-content: center;
  line-height: var(--icon-size, 25px);
  text-align: center;
  width: calc(var(--icon-size, 25px) + 2 * var(--icon-padding, 0.5em));
}

.elementor-social-icon i {
  color: #fff;
}

.elementor-social-icon:last-child {
  margin: 0;
}

.elementor-social-icon:hover {
  color: #fff;
  opacity: 0.9;
}

.elementor-social-icon-android {
  background-color: #a4c639;
}

.elementor-social-icon-apple {
  background-color: #999;
}

.elementor-social-icon-behance {
  background-color: #1769ff;
}

.elementor-social-icon-bitbucket {
  background-color: #205081;
}

.elementor-social-icon-codepen {
  background-color: #000;
}

.elementor-social-icon-delicious {
  background-color: #39f;
}

.elementor-social-icon-deviantart {
  background-color: #05cc47;
}

.elementor-social-icon-digg {
  background-color: #005be2;
}

.elementor-social-icon-dribbble {
  background-color: #ea4c89;
}

.elementor-social-icon-elementor {
  background-color: #d30c5c;
}

.elementor-social-icon-envelope {
  background-color: #ea4335;
}

.elementor-social-icon-facebook,
.elementor-social-icon-facebook-f {
  background-color: #3b5998;
}

.elementor-social-icon-flickr {
  background-color: #0063dc;
}

.elementor-social-icon-foursquare {
  background-color: #2d5be3;
}

.elementor-social-icon-free-code-camp,
.elementor-social-icon-freecodecamp {
  background-color: #006400;
}

.elementor-social-icon-github {
  background-color: #333;
}

.elementor-social-icon-gitlab {
  background-color: #e24329;
}

.elementor-social-icon-globe {
  background-color: #818a91;
}

.elementor-social-icon-google-plus,
.elementor-social-icon-google-plus-g {
  background-color: #dd4b39;
}

.elementor-social-icon-houzz {
  background-color: #7ac142;
}

.elementor-social-icon-instagram {
  background-color: #262626;
}

.elementor-social-icon-jsfiddle {
  background-color: #487aa2;
}

.elementor-social-icon-link {
  background-color: #818a91;
}

.elementor-social-icon-linkedin,
.elementor-social-icon-linkedin-in {
  background-color: #0077b5;
}

.elementor-social-icon-medium {
  background-color: #00ab6b;
}

.elementor-social-icon-meetup {
  background-color: #ec1c40;
}

.elementor-social-icon-mixcloud {
  background-color: #273a4b;
}

.elementor-social-icon-odnoklassniki {
  background-color: #f4731c;
}

.elementor-social-icon-pinterest {
  background-color: #bd081c;
}

.elementor-social-icon-product-hunt {
  background-color: #da552f;
}

.elementor-social-icon-reddit {
  background-color: #ff4500;
}

.elementor-social-icon-rss {
  background-color: #f26522;
}

.elementor-social-icon-shopping-cart {
  background-color: #4caf50;
}

.elementor-social-icon-skype {
  background-color: #00aff0;
}

.elementor-social-icon-slideshare {
  background-color: #0077b5;
}

.elementor-social-icon-snapchat {
  background-color: #fffc00;
}

.elementor-social-icon-soundcloud {
  background-color: #f80;
}

.elementor-social-icon-spotify {
  background-color: #2ebd59;
}

.elementor-social-icon-stack-overflow {
  background-color: #fe7a15;
}

.elementor-social-icon-steam {
  background-color: #00adee;
}

.elementor-social-icon-stumbleupon {
  background-color: #eb4924;
}

.elementor-social-icon-telegram {
  background-color: #2ca5e0;
}

.elementor-social-icon-thumb-tack {
  background-color: #1aa1d8;
}

.elementor-social-icon-tripadvisor {
  background-color: #589442;
}

.elementor-social-icon-tumblr {
  background-color: #35465c;
}

.elementor-social-icon-twitch {
  background-color: #6441a5;
}

.elementor-social-icon-twitter {
  background-color: #1da1f2;
}

.elementor-social-icon-viber {
  background-color: #665cac;
}

.elementor-social-icon-vimeo {
  background-color: #1ab7ea;
}

.elementor-social-icon-vk {
  background-color: #45668e;
}

.elementor-social-icon-weibo {
  background-color: #dd2430;
}

.elementor-social-icon-weixin {
  background-color: #31a918;
}

.elementor-social-icon-whatsapp {
  background-color: #25d366;
}

.elementor-social-icon-wordpress {
  background-color: #21759b;
}

.elementor-social-icon-xing {
  background-color: #026466;
}

.elementor-social-icon-yelp {
  background-color: #af0606;
}

.elementor-social-icon-youtube {
  background-color: #cd201f;
}

.elementor-social-icon-500px {
  background-color: #0099e5;
}

.elementor-shape-rounded .elementor-icon.elementor-social-icon {
  border-radius: 10%;
  border-radius: 10%;
}

.elementor-shape-circle .elementor-icon.elementor-social-icon {
  border-radius: 50%;
  border-radius: 50%;
}

.elementor-star-rating {
  color: #ccd6df;
  display: inline-block;
  font-family: eicons;
}

.elementor-star-rating i {
  cursor: default;
  display: inline-block;
  font-style: normal;
  position: relative;
}

.elementor-star-rating i::before {
  color: #f0ad4e;
  content: "\e934";
  display: block;
  font-family: inherit;
  font-size: inherit;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
}

.elementor-star-rating .elementor-star-empty::before {
  content: none;
}

.elementor-star-rating .elementor-star-1::before {
  width: 10%;
}

.elementor-star-rating .elementor-star-2::before {
  width: 20%;
}

.elementor-star-rating .elementor-star-3::before {
  width: 30%;
}

.elementor-star-rating .elementor-star-4::before {
  width: 40%;
}

.elementor-star-rating .elementor-star-5::before {
  width: 50%;
}

.elementor-star-rating .elementor-star-6::before {
  width: 60%;
}

.elementor-star-rating .elementor-star-7::before {
  width: 70%;
}

.elementor-star-rating .elementor-star-8::before {
  width: 80%;
}

.elementor-star-rating .elementor-star-9::before {
  width: 90%;
}

.elementor-star-rating__wrapper {
  align-items: center;
  box-align: center;
  display: flex;
}

.elementor-star-rating__title {
  margin-right: 10px;
}

.elementor-star-rating--align-right .elementor-star-rating__wrapper {
  box-pack: end;

  justify-content: flex-end;
  text-align: right;
}

.elementor-star-rating--align-left .elementor-star-rating__wrapper {
  box-pack: start;

  justify-content: flex-start;
  text-align: left;
}

.elementor-star-rating--align-center .elementor-star-rating__wrapper {
  box-pack: center;

  justify-content: center;
  text-align: center;
}

.elementor-star-rating--align-justify .elementor-star-rating__title {
  margin-right: auto;
}
@media (max-width: 1024px) {
  .elementor-star-rating-tablet--align-right .elementor-star-rating__wrapper {
    box-pack: end;

    justify-content: flex-end;
    text-align: right;
  }

  .elementor-star-rating-tablet--align-left .elementor-star-rating__wrapper {
    box-pack: start;

    justify-content: flex-start;
    text-align: left;
  }

  .elementor-star-rating-tablet--align-center .elementor-star-rating__wrapper {
    box-pack: center;

    justify-content: center;
    text-align: center;
  }

  .elementor-star-rating-tablet--align-justify .elementor-star-rating__title {
    margin-right: auto;
  }
}
@media (max-width: 767px) {
  .elementor-star-rating-mobile--align-right .elementor-star-rating__wrapper {
    box-pack: end;

    justify-content: flex-end;
    text-align: right;
  }

  .elementor-star-rating-mobile--align-left .elementor-star-rating__wrapper {
    box-pack: start;

    justify-content: flex-start;
    text-align: left;
  }

  .elementor-star-rating-mobile--align-center .elementor-star-rating__wrapper {
    box-pack: center;

    justify-content: center;
    text-align: center;
  }

  .elementor-star-rating-mobile--align-justify .elementor-star-rating__title {
    margin-right: auto;
  }
}

.last-star {
  letter-spacing: 0;
}

.elementor--star-style-star_unicode .elementor-star-rating {
  font-family: Arial, Helvetica, sans-serif;
}

.elementor--star-style-star_unicode
  .elementor-star-rating
  i:not(.elementor-star-empty)::before {
  content: "\002605";
}

.elementor-widget-tabs.elementor-tabs-view-vertical .elementor-tabs-wrapper {
  flex-shrink: 0;
  width: 25%;
}

.elementor-widget-tabs.elementor-tabs-view-vertical
  .elementor-tab-desktop-title.elementor-active {
  border-right-style: none;
}

.elementor-widget-tabs.elementor-tabs-view-vertical
  .elementor-tab-desktop-title.elementor-active::after,
.elementor-widget-tabs.elementor-tabs-view-vertical
  .elementor-tab-desktop-title.elementor-active::before {
  border-right-style: solid;
  height: 999em;
  right: 0;
  width: 0;
}

.elementor-widget-tabs.elementor-tabs-view-vertical
  .elementor-tab-desktop-title.elementor-active::before {
  top: 0;
  transform: translateY(-100%);
  transform: translateY(-100%);
  transform: translateY(-100%);
}

.elementor-widget-tabs.elementor-tabs-view-vertical
  .elementor-tab-desktop-title.elementor-active::after {
  top: 100%;
}

.elementor-widget-tabs.elementor-tabs-view-horizontal
  .elementor-tab-desktop-title {
  display: table-cell;
}

.elementor-widget-tabs.elementor-tabs-view-horizontal
  .elementor-tab-desktop-title.elementor-active {
  border-bottom-style: none;
}

.elementor-widget-tabs.elementor-tabs-view-horizontal
  .elementor-tab-desktop-title.elementor-active::after,
.elementor-widget-tabs.elementor-tabs-view-horizontal
  .elementor-tab-desktop-title.elementor-active::before {
  border-bottom-style: solid;
  bottom: 0;
  height: 0;
  width: 999em;
}

.elementor-widget-tabs.elementor-tabs-view-horizontal
  .elementor-tab-desktop-title.elementor-active::before {
  right: 100%;
}

.elementor-widget-tabs.elementor-tabs-view-horizontal
  .elementor-tab-desktop-title.elementor-active::after {
  left: 100%;
}

.elementor-widget-tabs .elementor-tab-content,
.elementor-widget-tabs .elementor-tab-title,
.elementor-widget-tabs .elementor-tab-title::after,
.elementor-widget-tabs .elementor-tab-title::before,
.elementor-widget-tabs .elementor-tabs-content-wrapper {
  border: 1px #d4d4d4;
}

.elementor-widget-tabs .elementor-tabs {
  text-align: left;
}

.elementor-widget-tabs .elementor-tabs-wrapper {
  overflow: hidden;
}

.elementor-widget-tabs .elementor-tab-title {
  cursor: pointer;
  outline: none;
}

.elementor-widget-tabs .elementor-tab-desktop-title {
  border: solid transparent;
  font-weight: 700;
  line-height: 1;
  padding: 20px 25px;
  position: relative;
}

.elementor-widget-tabs .elementor-tab-desktop-title.elementor-active {
  border-color: #d4d4d4;
}

.elementor-widget-tabs .elementor-tab-desktop-title.elementor-active::after,
.elementor-widget-tabs .elementor-tab-desktop-title.elementor-active::before {
  content: "";
  display: block;
  position: absolute;
}

.elementor-widget-tabs .elementor-tab-mobile-title {
  cursor: pointer;
  padding: 10px;
}

.elementor-widget-tabs .elementor-tab-content {
  display: none;
  padding: 20px;
}
@media (max-width: 767px) {
  .elementor-tabs .elementor-tab-content,
  .elementor-tabs .elementor-tab-title {
    border-style: solid solid none;
  }

  .elementor-tabs .elementor-tabs-wrapper {
    display: none;
  }

  .elementor-tabs .elementor-tabs-content-wrapper {
    border-bottom-style: solid;
  }

  .elementor-tabs .elementor-tab-content {
    padding: 10px;
  }
}
@media (min-width: 768px) {
  .elementor-widget-tabs.elementor-tabs-view-vertical .elementor-tabs {
    display: flex;
  }

  .elementor-widget-tabs.elementor-tabs-view-vertical
    .elementor-tabs-content-wrapper {
    border-style: solid solid solid none;
    box-flex: 1;
    flex-grow: 1;
  }

  .elementor-widget-tabs.elementor-tabs-view-horizontal .elementor-tab-content {
    border-style: none solid solid;
  }

  .elementor-tabs .elementor-tab-mobile-title {
    display: none;
  }
}

.elementor-testimonial-wrapper {
  overflow: hidden;
  text-align: center;
}

.elementor-testimonial-wrapper .elementor-testimonial-content {
  font-size: 1.3em;
  margin-bottom: 20px;
}

.elementor-testimonial-wrapper .elementor-testimonial-name {
  color: inherit;
  display: block;
  line-height: 1.5;
}

.elementor-testimonial-wrapper .elementor-testimonial-job {
  color: inherit;
  display: block;
  font-size: 0.85em;
}

.elementor-testimonial-wrapper.elementor-testimonial-text-align-left {
  text-align: left;
}

.elementor-testimonial-wrapper.elementor-testimonial-text-align-right {
  text-align: right;
}

.elementor-testimonial-wrapper .elementor-testimonial-meta {
  line-height: 1;
  width: 100%;
}

.elementor-testimonial-wrapper .elementor-testimonial-meta-inner {
  display: inline-block;
}

.elementor-testimonial-wrapper
  .elementor-testimonial-meta
  .elementor-testimonial-details,
.elementor-testimonial-wrapper
  .elementor-testimonial-meta
  .elementor-testimonial-image {
  display: table-cell;
  vertical-align: middle;
}

.elementor-testimonial-wrapper
  .elementor-testimonial-meta
  .elementor-testimonial-image
  img {
  border-radius: 50%;
  border-radius: 50%;
  height: 60px;
  max-width: none;
  object-fit: cover;
  object-fit: cover;
  width: 60px;
}

.elementor-testimonial-wrapper
  .elementor-testimonial-meta.elementor-testimonial-image-position-aside
  .elementor-testimonial-image {
  padding-right: 15px;
}

.elementor-testimonial-wrapper
  .elementor-testimonial-meta.elementor-testimonial-image-position-aside
  .elementor-testimonial-details {
  text-align: left;
}

.elementor-testimonial-wrapper
  .elementor-testimonial-meta.elementor-testimonial-image-position-top
  .elementor-testimonial-details,
.elementor-testimonial-wrapper
  .elementor-testimonial-meta.elementor-testimonial-image-position-top
  .elementor-testimonial-image {
  display: block;
}

.elementor-testimonial-wrapper
  .elementor-testimonial-meta.elementor-testimonial-image-position-top
  .elementor-testimonial-image {
  margin-bottom: 20px;
}

.elementor-widget-text-editor.elementor-drop-cap-view-stacked
  .elementor-drop-cap {
  background-color: #818a91;
  color: #fff;
}

.elementor-widget-text-editor.elementor-drop-cap-view-framed
  .elementor-drop-cap {
  background-color: transparent;
  border: 3px solid;
  color: #818a91;
}

.elementor-widget-text-editor:not(.elementor-drop-cap-view-default)
  .elementor-drop-cap {
  margin-top: 8px;
}

.elementor-widget-text-editor:not(.elementor-drop-cap-view-default)
  .elementor-drop-cap-letter {
  height: 1em;
  width: 1em;
}

.elementor-widget-text-editor .elementor-drop-cap {
  float: left;
  font-size: 50px;
  line-height: 1;
  text-align: center;
}

.elementor-widget-text-editor .elementor-drop-cap-letter {
  display: inline-block;
}

.elementor-toggle {
  text-align: left;
}

.elementor-toggle .elementor-tab-title {
  border-bottom: 1px solid #d4d4d4;
  cursor: pointer;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  outline: none;
  padding: 15px;
}

.elementor-toggle .elementor-tab-title .elementor-toggle-icon {
  display: inline-block;
  width: 1em;
}

.elementor-toggle
  .elementor-tab-title
  .elementor-toggle-icon.elementor-toggle-icon-right {
  float: right;
  text-align: right;
}

.elementor-toggle
  .elementor-tab-title
  .elementor-toggle-icon.elementor-toggle-icon-left {
  float: left;
  text-align: left;
}

.elementor-toggle
  .elementor-tab-title
  .elementor-toggle-icon
  .elementor-toggle-icon-closed {
  display: block;
}

.elementor-toggle
  .elementor-tab-title
  .elementor-toggle-icon
  .elementor-toggle-icon-opened {
  display: none;
}

.elementor-toggle .elementor-tab-title.elementor-active {
  border-bottom: none;
}

.elementor-toggle
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon-closed {
  display: none;
}

.elementor-toggle
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon-opened {
  display: block;
}

.elementor-toggle .elementor-tab-content {
  border-bottom: 1px solid #d4d4d4;
  display: none;
  padding: 15px;
}
@media (max-width: 767px) {
  .elementor-toggle .elementor-tab-title {
    padding: 12px;
  }

  .elementor-toggle .elementor-tab-content {
    padding: 12px 10px;
  }
}

.elementor-widget-video .elementor-widget-container {
  overflow: hidden;
  transform: translateZ(0);
  transform: translateZ(0);
}

.elementor-widget-video
  .elementor-open-inline
  .elementor-custom-embed-image-overlay {
  background-position: 50%;
  background-size: cover;
  background-size: cover;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.elementor-widget-video .elementor-custom-embed-image-overlay {
  cursor: pointer;
  text-align: center;
}

.elementor-widget-video
  .elementor-custom-embed-image-overlay:hover
  .elementor-custom-embed-play
  i {
  opacity: 1;
}

.elementor-widget-video .elementor-custom-embed-image-overlay img {
  display: block;
  width: 100%;
}

.elementor-widget-video .elementor-video {
  object-fit: cover;
  object-fit: cover;
}

.slick-slider {
  box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  touch-action: pan-y;
  touch-action: pan-y;
  -webkit-touch-callout: none;
  user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
}

.slick-list,
.slick-slider {
  display: block;
  position: relative;
}

.slick-list {
  margin: 0;
  overflow: hidden;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
  transform: translateZ(0);
  transform: translateZ(0);
}

.slick-track {
  display: block;
  left: 0;
  position: relative;
  top: 0;
}

.slick-track::after,
.slick-track::before {
  content: "";
  display: table;
}

.slick-track::after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

.elementor-slick-slider[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  border: 1px solid transparent;
  display: block;
  height: auto;
}

.slick-arrow.slick-hidden {
  display: none;
}

.elementor-slick-slider .slick-loading .slick-list {
  background: #fff;
}

.elementor-slick-slider .slick-loading .slick-list::after {
  animation: fa-spin 2s linear infinite;
  animation: fa-spin 2s linear infinite;
  color: #a4afb7;
  content: "\e8fb";
  font-family: eicons;
  font-size: 25px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.elementor-slick-slider .slick-next,
.elementor-slick-slider .slick-prev {
  background: transparent;
  border: none;
  color: transparent;
  cursor: pointer;
  display: block;
  font-size: 0;
  line-height: 0;
  outline: none;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transform: translateY(-50%);
  transform: translateY(-50%);
  width: 20px;
}

.elementor-slick-slider .slick-next:focus,
.elementor-slick-slider .slick-next:hover,
.elementor-slick-slider .slick-prev:focus,
.elementor-slick-slider .slick-prev:hover {
  background: transparent;
  color: transparent;
  outline: none;
}

.elementor-slick-slider .slick-next:focus::before,
.elementor-slick-slider .slick-next:hover::before,
.elementor-slick-slider .slick-prev:focus::before,
.elementor-slick-slider .slick-prev:hover::before {
  opacity: 1;
}

.elementor-slick-slider .slick-next.slick-disabled::before,
.elementor-slick-slider .slick-prev.slick-disabled::before {
  opacity: 0.25;
}

.elementor-slick-slider .slick-next::before,
.elementor-slick-slider .slick-prev::before {
  color: #fff;
  font-family: eicons;
  font-size: 35px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  opacity: 0.75;
}

.elementor-slick-slider .slick-prev {
  left: -25px;
}

[dir="rtl"] .elementor-slick-slider .slick-prev {
  left: auto;
  right: -25px;
}

.elementor-slick-slider .slick-prev::before {
  content: "\e89f";
}

[dir="rtl"] .elementor-slick-slider .slick-prev::before {
  content: "\e89e";
}

.elementor-slick-slider .slick-next {
  right: -25px;
}

[dir="rtl"] .elementor-slick-slider .slick-next {
  left: -25px;
  right: auto;
}

.elementor-slick-slider .slick-next::before {
  content: "\e89e";
}

[dir="rtl"] .elementor-slick-slider .slick-next::before {
  content: "\e89f";
}

.elementor-slick-slider .slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.elementor-slick-slider ul.slick-dots {
  bottom: -25px;
  display: block;
  line-height: 1;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.elementor-slick-slider ul.slick-dots li {
  cursor: pointer;
  display: inline-block;
  height: 20px;
  margin: 0;
  padding: 0;
  position: relative;
  width: 20px;
}

.elementor-slick-slider ul.slick-dots li button {
  background: transparent;
  border: 0;
  color: transparent;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 20px;
  line-height: 0;
  outline: none;
  padding: 5px;
  width: 20px;
}

.elementor-slick-slider ul.slick-dots li button:focus,
.elementor-slick-slider ul.slick-dots li button:hover {
  outline: none;
}

.elementor-slick-slider ul.slick-dots li button:focus::before,
.elementor-slick-slider ul.slick-dots li button:hover::before {
  opacity: 1;
}

.elementor-slick-slider ul.slick-dots li button::before {
  color: #000;
  content: "\e914";
  font-family: eicons;
  font-size: 6px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 20px;
  left: 0;
  line-height: 20px;
  opacity: 0.25;
  position: absolute;
  text-align: center;
  top: 0;
  width: 20px;
}

.elementor-slick-slider ul.slick-dots li.slick-active button::before {
  color: #000;
  opacity: 0.75;
}

.elementor-slick-slider .slick-arrows-inside .slick-prev {
  left: 20px;
}

[dir="rtl"] .elementor-slick-slider .slick-arrows-inside .slick-prev {
  left: auto;
  right: 20px;
}

.elementor-slick-slider .slick-arrows-inside .slick-next {
  right: 20px;
}

[dir="rtl"] .elementor-slick-slider .slick-arrows-inside .slick-next {
  left: 20px;
  right: auto;
}

.elementor-slick-slider .slick-dots-inside .slick-dots {
  bottom: 5px;
}

.elementor-slick-slider .slick-dots-inside.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.elementor-slick-slider .slick-slider .slick-next,
.elementor-slick-slider .slick-slider .slick-prev {
  z-index: 1;
}

.elementor-slick-slider .slick-slide img {
  margin: auto;
}

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.swiper-container .swiper-slide figure {
  line-height: 0;
}

.swiper-container .elementor-lightbox-content-source {
  display: none;
}

.swiper-container-no-flexbox .swiper-slide {
  float: left;
}

.swiper-container-vertical > .swiper-wrapper {
  box-direction: normal;
  box-orient: vertical;
  flex-direction: column;
  flex-direction: column;
}

.swiper-wrapper {
  box-sizing: content-box;
  box-sizing: content-box;
  display: flex;
  height: 100%;
  position: relative;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  width: 100%;
  z-index: 1;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  transform: translateZ(0);
  transform: translateZ(0);
}

.swiper-container-multirow > .swiper-wrapper {
  flex-wrap: wrap;
  flex-wrap: wrap;
}

.swiper-container-free-mode > .swiper-wrapper {
  margin: 0 auto;
  transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.swiper-slide {
  flex-shrink: 0;
  height: 100%;
  position: relative;
  width: 100%;
}

.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto;
}

.swiper-container-autoheight .swiper-wrapper {
  align-items: flex-start;
  box-align: start;

  transition-property: height, -webkit-transform;
  transition-property: transform, height;
  transition-property: height, -webkit-transform;
  transition-property: transform, height;
  transition-property: transform, height, -webkit-transform;
}

.swiper-container .swiper-notification {
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: -1000;
}

.swiper-wp8-horizontal {
  touch-action: pan-y;
  touch-action: pan-y;
}

.swiper-wp8-vertical {
  touch-action: pan-x;
  touch-action: pan-x;
}

.swiper-button-next,
.swiper-button-prev {
  background: no-repeat 50%;
  background-size: 27px 44px;
  background-size: 27px 44px;
  cursor: pointer;
  height: 44px;
  margin-top: -22px;
  position: absolute;
  top: 50%;
  width: 27px;
  z-index: 10;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  cursor: auto;
  opacity: 0.35;
  pointer-events: none;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M0 22L22 0l2.1 2.1L4.2 22l19.9 19.9L22 44 0 22z' fill='%23007aff'/%3E%3C/svg%3E");
  left: 10px;
  right: auto;
}

.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M0 22L22 0l2.1 2.1L4.2 22l19.9 19.9L22 44 0 22z'/%3E%3C/svg%3E");
}

.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M0 22L22 0l2.1 2.1L4.2 22l19.9 19.9L22 44 0 22z' fill='%23fff'/%3E%3C/svg%3E");
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M27 22L5 44l-2.1-2.1L22.8 22 2.9 2.1 5 0l22 22z' fill='%23007aff'/%3E%3C/svg%3E");
  left: auto;
  right: 10px;
}

.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M27 22L5 44l-2.1-2.1L22.8 22 2.9 2.1 5 0l22 22z'/%3E%3C/svg%3E");
}

.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M27 22L5 44l-2.1-2.1L22.8 22 2.9 2.1 5 0l22 22z' fill='%23fff'/%3E%3C/svg%3E");
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  transform: translateZ(0);
  transform: translateZ(0);
  transition: 0.3s;
  transition: 0.3s;
  transition: 0.3s;
  z-index: 10;
}

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 5px;
  left: 0;
  width: 100%;
}

.swiper-pagination-bullet {
  background: #000;
  border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  height: 6px;
  opacity: 0.2;
  width: 6px;
}

.swiper-pagination-fraction {
  color: #000;
}

button.swiper-pagination-bullet {
  appearance: none;
  appearance: none;
  appearance: none;
  border: none;
  box-shadow: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-white .swiper-pagination-bullet {
  background: #fff;
}

.swiper-pagination-bullet-active {
  opacity: 1;
}

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff;
}

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000;
}

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}

.swiper-container-vertical
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  display: block;
  margin: 5px 0;
}

.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 6px;
}

.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #000;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: scale(0);
  transform: scale(0);
  transform: scale(0);
  transform-origin: left top;
  transform-origin: left top;
  transform-origin: left top;
  width: 100%;
}

.swiper-container-rtl
  .swiper-pagination-progressbar
  .swiper-pagination-progressbar-fill {
  transform-origin: right top;
  transform-origin: right top;
  transform-origin: right top;
}

.swiper-container-horizontal > .swiper-pagination-progressbar {
  height: 4px;
  left: 0;
  top: 0;
  width: 100%;
}

.swiper-container-vertical > .swiper-pagination-progressbar {
  height: 100%;
  left: 0;
  top: 0;
  width: 4px;
}

.swiper-pagination-progressbar.swiper-pagination-white {
  background: hsla(0, 0%, 100%, 0.5);
}

.swiper-pagination-progressbar.swiper-pagination-white
  .swiper-pagination-progressbar-fill {
  background: #fff;
}

.swiper-pagination-progressbar.swiper-pagination-black
  .swiper-pagination-progressbar-fill {
  background: #000;
}

.swiper-container-3d {
  perspective: 1200px;
  perspective: 1200px;
}

.swiper-container-3d .swiper-cube-shadow,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-wrapper {
  transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top {
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
}

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgba(0, 0, 0, 0.5)),
    to(transparent)
  );
  background-image: linear-gradient("to left", rgba(0, 0, 0, 0.5), transparent);
  background-image: linear-gradient("to left", rgba(0, 0, 0, 0.5), transparent);
  background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.5), transparent);
}

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(0, 0, 0, 0.5)),
    to(transparent)
  );
  background-image: linear-gradient(
    "to right",
    rgba(0, 0, 0, 0.5),
    transparent
  );
  background-image: linear-gradient(
    "to right",
    rgba(0, 0, 0, 0.5),
    transparent
  );
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.5), transparent);
}

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(0, 0, 0, 0.5)),
    to(transparent)
  );
  background-image: linear-gradient("to top", rgba(0, 0, 0, 0.5), transparent);
  background-image: linear-gradient("to top", rgba(0, 0, 0, 0.5), transparent);
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), transparent);
}

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0.5)),
    to(transparent)
  );
  background-image: linear-gradient(
    "to bottom",
    rgba(0, 0, 0, 0.5),
    transparent
  );
  background-image: linear-gradient(
    "to bototm",
    rgba(0, 0, 0, 0.5),
    transparent
  );
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), transparent);
}

.swiper-container-coverflow .swiper-wrapper,
.swiper-container-flip .swiper-wrapper {
  perspective: 1200px;
}

.swiper-container-cube,
.swiper-container-flip {
  overflow: visible;
}

.swiper-container-cube .swiper-slide,
.swiper-container-flip .swiper-slide {
  pointer-events: none;
  z-index: 1;
}

.swiper-container-cube .swiper-slide .swiper-slide,
.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active,
.swiper-container-flip .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-top {
  backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 0;
}

.swiper-container-cube .swiper-slide {
  height: 100%;
  transform-origin: 0 0;
  transform-origin: 0 0;
  transform-origin: 0 0;
  visibility: hidden;
  width: 100%;
}

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  transform-origin: 100% 0;
  transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-next + .swiper-slide,
.swiper-container-cube .swiper-slide-prev {
  pointer-events: auto;
  visibility: visible;
}

.swiper-container-cube .swiper-cube-shadow {
  background: #000;
  bottom: 0;
  filter: blur(50px);
  filter: blur(50px);
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  width: 100%;
  z-index: 0;
}

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  transition-property: opacity;
  transition-property: opacity;
  transition-property: opacity;
}

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-zoom-container {
  align-items: center;
  box-align: center;
  box-pack: center;
  display: flex;
  height: 100%;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.swiper-zoom-container > canvas,
.swiper-zoom-container > img,
.swiper-zoom-container > svg {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  object-fit: contain;
}

.swiper-scrollbar {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border-radius: 10px;
  position: relative;
  touch-action: none;
}

.swiper-container-horizontal > .swiper-scrollbar {
  bottom: 3px;
  height: 5px;
  left: 1%;
  position: absolute;
  width: 98%;
  z-index: 50;
}

.swiper-container-vertical > .swiper-scrollbar {
  height: 98%;
  position: absolute;
  right: 3px;
  top: 1%;
  width: 5px;
  z-index: 50;
}

.swiper-scrollbar-drag {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border-radius: 10px;
  height: 100%;
  left: 0;
  position: relative;
  top: 0;
  width: 100%;
}

.swiper-scrollbar-cursor-drag {
  cursor: move;
}

.elementor-pagination-position-outside .swiper-container {
  padding-bottom: 30px;
}

.elementor-pagination-position-outside .elementor-swiper-button {
  top: calc(50% - 15px);
}

.elementor-swiper {
  position: relative;
}

.elementor-main-swiper {
  position: static;
}

.elementor-arrows-position-outside .swiper-container {
  width: calc(100% - 60px);
}

.elementor-arrows-position-outside .elementor-swiper-button-prev {
  left: 0;
}

.elementor-arrows-position-outside .elementor-swiper-button-next {
  right: 0;
}

.swiper-image-stretch .swiper-slide .swiper-slide-image {
  width: 100%;
}

.elementor-swiper-button {
  color: hsla(0, 0%, 93.3%, 0.9);
  cursor: pointer;
  display: inline-box;
  display: inline-flexbox;
  display: inline-flex;
  font-size: 25px;
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 1px);
  transform: translate3d(0, -50%, 1px);
  z-index: 1;
}

.elementor-swiper-button-prev {
  left: 10px;
}

.elementor-swiper-button-next {
  right: 10px;
}

.elementor-swiper-button.swiper-button-disabled {
  opacity: 0.3;
}

.swiper-lazy-preloader {
  animation: swiper-preloader-spin 1s steps(12) infinite;
  animation: swiper-preloader-spin 1s steps(12) infinite;
  height: 42px;
  left: 50%;
  margin-left: -21px;
  margin-top: -21px;
  position: absolute;
  top: 50%;
  transform-origin: 50%;
  transform-origin: 50%;
  transform-origin: 50%;
  width: 42px;
  z-index: 10;
}

.swiper-lazy-preloader::after {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' stroke='%236c6c6c' stroke-width='11' stroke-linecap='round' d='M60 7v20'/%3E%3C/defs%3E%3Cuse xlink:href='%23a' opacity='.27'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(30 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(60 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(90 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(120 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(150 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.37' transform='rotate(180 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.46' transform='rotate(210 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.56' transform='rotate(240 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.66' transform='rotate(270 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.75' transform='rotate(300 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.85' transform='rotate(330 60 60)'/%3E%3C/svg%3E")
    no-repeat 50%;
  background-size: 100% 100%;
  background-size: 100%;
  content: "";
  display: block;
  height: 100%;
  width: 100%;
}

.swiper-lazy-preloader-white::after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' stroke='%23fff' stroke-width='11' stroke-linecap='round' d='M60 7v20'/%3E%3C/defs%3E%3Cuse xlink:href='%23a' opacity='.27'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(30 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(60 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(90 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(120 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(150 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.37' transform='rotate(180 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.46' transform='rotate(210 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.56' transform='rotate(240 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.66' transform='rotate(270 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.75' transform='rotate(300 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.85' transform='rotate(330 60 60)'/%3E%3C/svg%3E");
}
@keyframes swiper-preloader-spin {
  to {
    transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes swiper-preloader-spin {
  to {
    transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.elementor-lightbox {
  --lightbox-ui-color: hsla(0, 0%, 93.3%, 0.9);
  --lightbox-ui-color-hover: #fff;
  --lightbox-text-color: var(--lightbox-ui-color);
  --lightbox-header-icons-size: 20px;
  --lightbox-navigation-icons-size: 25px;
}

.elementor-lightbox .dialog-header {
  display: none;
}

.elementor-lightbox .dialog-widget-content {
  background: none;
  box-shadow: none;
  box-shadow: none;
  height: 100%;
  width: 100%;
}

.elementor-lightbox .dialog-message {
  animation-duration: 0.3s;
  animation-duration: 0.3s;
}

.elementor-lightbox .dialog-message:not(.elementor-fit-aspect-ratio) {
  height: 100%;
}

.elementor-lightbox .dialog-message.dialog-lightbox-message {
  padding: 0;
}

.elementor-lightbox .dialog-lightbox-close-button {
  cursor: pointer;
  font-size: var(--lightbox-header-icons-size);
  line-height: 1;
  margin-top: 13px;
  padding: 0.25em;
  position: absolute;
  right: 0.75em;
  z-index: 2;
}

.elementor-lightbox .dialog-lightbox-close-button,
.elementor-lightbox .elementor-swiper-button {
  color: var(--lightbox-ui-color);
  opacity: 1;
  transition: all 0.3s;
  transition: all 0.3s;
  transition: all 0.3s;
}

.elementor-lightbox .dialog-lightbox-close-button:hover,
.elementor-lightbox .elementor-swiper-button:hover {
  color: var(--lightbox-ui-color-hover);
}

.elementor-lightbox .swiper-container {
  height: 100%;
}

.elementor-lightbox .elementor-lightbox-item {
  align-items: center;
  box-align: center;
  box-pack: center;
  box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: auto;
  padding: 70px;
  position: relative;
}
@media (max-width: 767px) {
  .elementor-lightbox .elementor-lightbox-item {
    padding: 70px 0;
  }
}

.elementor-lightbox .elementor-lightbox-image {
  max-height: 100%;
  user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
}

.elementor-lightbox .elementor-lightbox-image,
.elementor-lightbox .elementor-lightbox-image:hover {
  border: none;
  filter: none;
  filter: none;
  opacity: 1;
}

.elementor-lightbox .elementor-lightbox-image,
.elementor-lightbox .elementor-video-container {
  border-radius: 2px;
  border-radius: 2px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3), 0 0 8px -5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3), 0 0 8px -5px rgba(0, 0, 0, 0.3);
}

.elementor-lightbox .elementor-video-container {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
@media (min-width: 1025px) {
  .elementor-lightbox .elementor-video-container {
    width: 75%;
  }
}
@media (max-width: 1024px) {
  .elementor-lightbox .elementor-video-container {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .elementor-lightbox .elementor-aspect-ratio-916 .elementor-video-container {
    width: 70%;
  }
}

.elementor-lightbox .elementor-swiper-button:focus {
  outline-width: 1px;
}

.elementor-lightbox .elementor-swiper-button-next,
.elementor-lightbox .elementor-swiper-button-prev {
  align-items: center;
  box-align: center;
  box-pack: center;
  display: flex;
  font-size: var(--lightbox-navigation-icons-size);
  height: 100%;
  justify-content: center;
  width: 15%;
}

.elementor-lightbox .elementor-swiper-button-prev {
  left: 0;
}

.elementor-lightbox .elementor-swiper-button-next {
  right: 0;
}
@media (max-width: 767px) {
  .elementor-lightbox .elementor-swiper-button-next,
  .elementor-lightbox .elementor-swiper-button-prev {
    width: 20%;
  }

  .elementor-lightbox .elementor-swiper-button-next i,
  .elementor-lightbox .elementor-swiper-button-prev i {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
  }

  .elementor-lightbox .elementor-swiper-button-prev {
    box-pack: start;

    justify-content: flex-start;
    left: 0;
  }

  .elementor-lightbox .elementor-swiper-button-next {
    box-pack: end;

    justify-content: flex-end;
    right: 0;
  }
}

.elementor-slideshow__counter {
  color: currentColor;
  font-size: 0.75em;
  width: max-content;
  width: max-content;
  width: max-content;
}

.elementor-slideshow__footer,
.elementor-slideshow__header {
  left: 0;
  padding: 15px 20px;
  position: absolute;
  transition: 0.3s;
  transition: 0.3s;
  transition: 0.3s;
  width: 100%;
}

.elementor-slideshow__footer {
  color: var(--lightbox-text-color);
}

.elementor-slideshow__header {
  align-items: center;
  box-align: center;
  box-direction: reverse;
  box-orient: horizontal;
  color: var(--lightbox-ui-color);
  display: flex;
  flex-direction: row-reverse;
  flex-direction: row-reverse;
  font-size: var(--lightbox-header-icons-size);
  padding-left: 1em;
  padding-right: 2.6em;
  top: 0;
  z-index: 10;
}

.elementor-slideshow__header > i {
  cursor: pointer;
  font-size: inherit;
  margin: 0 0.35em;
  padding: 0.25em;
}

.elementor-slideshow__header > i:hover {
  color: var(--lightbox-ui-color-hover);
}

.elementor-slideshow__header .elementor-slideshow__counter {
  margin-right: auto;
}

.elementor-slideshow__header .elementor-icon-share {
  z-index: 5;
}

.elementor-slideshow__share-menu {
  background-color: transparent;
  height: 0;
  overflow: hidden;
  position: absolute;
  transition: background-color 0.4s;
  transition: background-color 0.4s;
  transition: background-color 0.4s;
  width: 0;
}

.elementor-slideshow__share-menu .elementor-slideshow__share-links a {
  color: #2c2c2c;
}

.elementor-slideshow__share-links {
  background-color: #fff;
  border-radius: 3px;
  border-radius: 3px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  display: block;
  min-width: 200px;
  opacity: 0;
  padding: 14px 20px;
  position: absolute;
  right: 2.8em;
  top: 3em;
  transform: scale(0);
  transform: scale(0);
  transform: scale(0);
  transform-origin: 90% 10%;
  transform-origin: 90% 10%;
  transform-origin: 90% 10%;
  transition: all 0.25s 0.1s;
  transition: all 0.25s 0.1s;
  transition: all 0.25s 0.1s;
}

.elementor-slideshow__share-links a {
  color: #55595c;
  display: block;
  font-size: 12px;
  line-height: 2.5;
  opacity: 0;
  text-align: left;
  transition: opacity 0.5s 0.1s;
  transition: opacity 0.5s 0.1s;
  transition: opacity 0.5s 0.1s;
}

.elementor-slideshow__share-links a:hover {
  color: #000;
}

.elementor-slideshow__share-links a i {
  font-size: 1.25em;
  margin-right: 0.75em;
}

.elementor-slideshow__share-links::before {
  border: 0.45em solid transparent;
  border-bottom-color: #fff;
  content: "";
  display: block;
  position: absolute;
  right: 0.5em;
  top: 1px;
  transform: translateY(-100%) scaleX(0.7);
  transform: translateY(-100%) scaleX(0.7);
  transform: translateY(-100%) scaleX(0.7);
}

.elementor-slideshow__footer {
  bottom: 0;
  position: fixed;
  z-index: 5;
}

.elementor-slideshow__description,
.elementor-slideshow__title {
  margin: 0;
}

.elementor-slideshow__title {
  font-size: 16px;
  font-weight: 700;
}

.elementor-slideshow__description {
  font-size: 14px;
}

.elementor-slideshow--ui-hidden .elementor-slideshow__footer,
.elementor-slideshow--ui-hidden .elementor-slideshow__header {
  opacity: 0;
  pointer-events: none;
}

.elementor-slideshow--ui-hidden .elementor-swiper-button-next,
.elementor-slideshow--ui-hidden .elementor-swiper-button-prev {
  opacity: 0;
}

.elementor-slideshow--fullscreen-mode .elementor-video-container {
  width: 100%;
}

.elementor-slideshow--zoom-mode .elementor-slideshow__footer,
.elementor-slideshow--zoom-mode .elementor-slideshow__header {
  background-color: rgba(0, 0, 0, 0.5);
}

.elementor-slideshow--zoom-mode .elementor-swiper-button-next,
.elementor-slideshow--zoom-mode .elementor-swiper-button-prev {
  opacity: 0;
  pointer-events: none;
}

.elementor-slideshow--share-mode .elementor-slideshow__share-menu {
  background-color: rgba(0, 0, 0, 0.5);
  cursor: default;
  height: 100vh;
  left: 0;
  opacity: 1;
  top: 0;
  width: 100vw;
}

.elementor-slideshow--share-mode .elementor-slideshow__share-links {
  transform: scale(1);
  transform: scale(1);
  transform: scale(1);
}

.elementor-slideshow--share-mode .elementor-slideshow__share-links,
.elementor-slideshow--share-mode .elementor-slideshow__share-links a {
  opacity: 1;
}

.elementor-slideshow--share-mode
  .elementor-slideshow__share-links
  .eicon-twitter {
  color: #1da1f2;
}

.elementor-slideshow--share-mode
  .elementor-slideshow__share-links
  .eicon-facebook {
  color: #3b5998;
}

.elementor-slideshow--share-mode
  .elementor-slideshow__share-links
  .eicon-pinterest {
  color: #bd081c;
}

.elementor-slideshow--share-mode
  .elementor-slideshow__share-links
  .eicon-download-bold {
  color: #a4afb7;
}

.elementor-slideshow--share-mode .eicon-share-arrow {
  z-index: 2;
}

.animated {
  animation-duration: 1.25s;
  animation-duration: 1.25s;
}

.animated.animated-slow {
  animation-duration: 2s;
  animation-duration: 2s;
}

.animated.animated-fast {
  animation-duration: 0.75s;
  animation-duration: 0.75s;
}

.animated.infinite {
  animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.reverse {
  animation-direction: reverse;
}
@media (prefers-reduced-motion: reduce) {
  .animated {
    animation: none;
    animation: none;
  }
}

.elementor-shape {
  direction: ltr;
  left: 0;
  line-height: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
}

.elementor-shape-top {
  top: -1px;
}

.elementor-shape-top:not([data-negative="false"]) svg {
  z-index: -1;
}

.elementor-shape-bottom {
  bottom: -1px;
}

.elementor-shape-bottom:not([data-negative="true"]) svg {
  z-index: -1;
}

.elementor-shape[data-negative="false"].elementor-shape-bottom,
.elementor-shape[data-negative="true"].elementor-shape-top {
  transform: rotate(180deg);
  transform: rotate(180deg);
  transform: rotate(180deg);
}

.elementor-shape svg {
  display: block;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  transform: translateX(-50%);
  transform: translateX(-50%);
  width: calc(100% + 1.3px);
}

.elementor-shape .elementor-shape-fill {
  fill: #fff;
  transform: rotateY(0deg);
  transform: rotateY(0deg);
  transform-origin: center;
  transform-origin: center;
  transform-origin: center;
}

#wp-admin-bar-elementor_edit_page .ab-submenu .ab-item {
  display: flex;
  width: 200px;
}

#wp-admin-bar-elementor_edit_page .elementor-edit-link-title {
  overflow: hidden;
  text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

#wp-admin-bar-elementor_edit_page .elementor-edit-link-type {
  background: #55595c;
  border-radius: 3px;
  border-radius: 3px;
  font-size: 11px;
  line-height: 9px;
  margin-top: 6px;
  padding: 4px 8px;
}

.page-template-elementor_canvas.elementor-page::before {
  display: none;
}

.elementor-post__thumbnail__link {
  transition: none;
  transition: none;
  transition: none;
}

#left-area ul.elementor-icon-list-items,
.elementor-edit-area .elementor-element ul.elementor-icon-list-items,
.elementor .elementor-element ul.elementor-icon-list-items {
  padding: 0;
}

#wpadminbar * {
  font-style: normal;
}

.elementor-portfolio.elementor-grid {
  display: grid;
}

.elementor-share-buttons--align-right {
  text-align: right;
}

.elementor-share-buttons--align-left {
  text-align: left;
}

.elementor-share-buttons--align-center {
  text-align: center;
}

.elementor-share-buttons--align-justify {
  text-align: justify;
  text-align-last: justify;
  text-align-last: justify;
}
@media (max-width: 1024px) {
  .elementor-share-buttons-tablet--align-right {
    text-align: right;
  }

  .elementor-share-buttons-tablet--align-left {
    text-align: left;
  }

  .elementor-share-buttons-tablet--align-center {
    text-align: center;
  }

  .elementor-share-buttons-tablet--align-justify {
    text-align: justify;
    text-align-last: justify;
    text-align-last: justify;
  }
}
@media (max-width: 767px) {
  .elementor-share-buttons-mobile--align-right {
    text-align: right;
  }

  .elementor-share-buttons-mobile--align-left {
    text-align: left;
  }

  .elementor-share-buttons-mobile--align-center {
    text-align: center;
  }

  .elementor-share-buttons-mobile--align-justify {
    text-align: justify;
    text-align-last: justify;
    text-align-last: justify;
  }
}
@media (max-width: 767px) {
  .elementor .elementor-hidden-phone {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .elementor .elementor-hidden-tablet {
    display: none;
  }
}
@media (min-width: 1025px) {
  .elementor .elementor-hidden-desktop {
    display: none;
  }
}

.elementor-7
  .elementor-element.elementor-element-fddb134
  > .elementor-container {
  min-height: 570px;
}

.elementor-7
  .elementor-element.elementor-element-fddb134
  > .elementor-container::after {
  content: "";
  min-height: inherit;
}

.elementor-7
  .elementor-element.elementor-element-fddb134:not(.elementor-motion-effects-element-type-background),
.elementor-7
  .elementor-element.elementor-element-fddb134
  > .elementor-motion-effects-container
  > .elementor-7
  .elementor-element.elementor-element-fddb134
  > .elementor-background-overlay {
  background-color: rgba(0, 0, 0, 0.65);
  opacity: 0.5;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-7 .elementor-element.elementor-element-fddb134 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-b73d2f5
  > .elementor-element-populated {
  padding: 0 0 0 30px;
}

.elementor-7 .elementor-element.elementor-element-29b3ada {
  text-align: left;
}

.elementor-7
  .elementor-element.elementor-element-29b3ada
  .elementor-heading-title {
  color: #fff;
  font-family: "Raleway", Sans-serif;
  font-size: 32px;
  font-weight: 800;
  text-transform: uppercase;
}

.elementor-7 .elementor-element.elementor-element-2e876ec {
  text-align: left;
}

.elementor-7
  .elementor-element.elementor-element-2e876ec
  .elementor-heading-title {
  color: #fff;
  font-family: "Raleway", Sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.elementor-7 .elementor-element.elementor-element-fe8e4ff .elementor-button {
  background-color: #b0205a;
  color: #fff;
  fill: #fff;
}

.elementor-7 .elementor-element.elementor-element-58af6b7 .elementor-button {
  background-color: #b0205a;
  color: #fff;
  fill: #fff;
}

.elementor-7
  .elementor-element.elementor-element-758e365:not(.elementor-motion-effects-element-type-background),
.elementor-7
  .elementor-element.elementor-element-758e365
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: rgba(87, 175, 211, 0.07);
}

.elementor-7 .elementor-element.elementor-element-758e365 {
  padding: 050px 0 20px;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-758e365
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-7 .elementor-element.elementor-element-66a5ca99 {
  text-align: center;
}

.elementor-7
  .elementor-element.elementor-element-66a5ca99
  .elementor-heading-title {
  color: #b0205a;
  font-family: "Raleway", Sans-serif;
  font-size: 32px;
  font-weight: 800;
  text-transform: uppercase;
}

.elementor-7
  .elementor-element.elementor-element-7f93922
  .elementor-text-editor {
  column-gap: 0;
  text-align: center;
}

.elementor-7 .elementor-element.elementor-element-7f93922 {
  color: #277da1;
  font-family: "Raleway", Sans-serif;
  font-size: 17px;
  font-weight: 600;
}

.elementor-7
  .elementor-element.elementor-element-7f93922
  > .elementor-widget-container {
  margin: -11px 0 0;
}

.elementor-7
  .elementor-element.elementor-element-58462e62
  > .elementor-container
  > .elementor-column
  > .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}

.elementor-7
  .elementor-element.elementor-element-58462e62:not(.elementor-motion-effects-element-type-background),
.elementor-7
  .elementor-element.elementor-element-58462e62
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: rgba(87, 175, 211, 0.13);
}

.elementor-7 .elementor-element.elementor-element-58462e62 {
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.15);
  padding: 20px 0;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  z-index: 10;
}

.elementor-7
  .elementor-element.elementor-element-58462e62
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-243bef4
  .elementor-text-editor {
  column-gap: 0;
  text-align: center;
}

.elementor-7 .elementor-element.elementor-element-243bef4 {
  color: #b0205a;
  font-family: "Raleway", Sans-serif;
  font-size: 25px;
  font-weight: 600;
}

.elementor-7
  .elementor-element.elementor-element-243bef4
  > .elementor-widget-container {
  margin: 0;
  padding: 0;
}

.elementor-7
  .elementor-element.elementor-element-268d3a6f:not(.elementor-motion-effects-element-type-background),
.elementor-7
  .elementor-element.elementor-element-268d3a6f
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: rgba(87, 175, 211, 0.07);
}

.elementor-7 .elementor-element.elementor-element-268d3a6f {
  margin-bottom: 0%;
  margin-top: 0%;
  padding: 0 0 20px;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-268d3a6f
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-bc-flex-widget
  .elementor-7
  .elementor-element.elementor-element-3287c4c3.elementor-column
  .elementor-widget-wrap {
  align-items: center;
}

.elementor-7
  .elementor-element.elementor-element-3287c4c3.elementor-column.elementor-element[data-element_type="column"]
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}

.elementor-7
  .elementor-element.elementor-element-3287c4c3.elementor-column
  > .elementor-widget-wrap {
  justify-content: center;
}

.elementor-7
  .elementor-element.elementor-element-3287c4c3:not(.elementor-motion-effects-element-type-background)
  > .elementor-widget-wrap,
.elementor-7
  .elementor-element.elementor-element-3287c4c3
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ef6b8a;
}

.elementor-7
  .elementor-element.elementor-element-3287c4c3
  > .elementor-element-populated,
.elementor-7
  .elementor-element.elementor-element-3287c4c3
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-7
  .elementor-element.elementor-element-3287c4c3
  > .elementor-background-slideshow {
  border-radius: 40px 0 0 40px;
}

.elementor-7
  .elementor-element.elementor-element-3287c4c3
  > .elementor-element-populated {
  padding: 12%;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-3287c4c3
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-7 .elementor-element.elementor-element-128f930 {
  text-align: left;
}

.elementor-7
  .elementor-element.elementor-element-128f930
  .elementor-heading-title {
  color: rgba(255, 255, 255, 0.42);
  font-family: "Raleway", Sans-serif;
  font-size: 80px;
  font-weight: 800;
}

.elementor-7
  .elementor-element.elementor-element-128f930
  > .elementor-widget-container {
  margin: 0 0 -70px -33px;
  padding: 0;
}

.elementor-7 .elementor-element.elementor-element-a79e674 {
  text-align: left;
}

.elementor-7
  .elementor-element.elementor-element-a79e674
  .elementor-heading-title {
  color: #fff;
  font-family: "Raleway", Sans-serif;
  font-size: 25px;
}

.elementor-7
  .elementor-element.elementor-element-a79e674
  > .elementor-widget-container {
  margin: 0;
  padding: 10px 0 0;
}

.elementor-7
  .elementor-element.elementor-element-2da3bcf
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:last-child) {
  padding-bottom: calc(12px / 2);
}

.elementor-7
  .elementor-element.elementor-element-2da3bcf
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:first-child) {
  margin-top: calc(12px / 2);
}

.elementor-7
  .elementor-element.elementor-element-2da3bcf
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item {
  margin-left: calc(12px / 2);
  margin-right: calc(12px / 2);
}

.elementor-7
  .elementor-element.elementor-element-2da3bcf
  .elementor-icon-list-items.elementor-inline-items {
  margin-left: calc(-12px / 2);
  margin-right: calc(-12px / 2);
}

body.rtl
  .elementor-7
  .elementor-element.elementor-element-2da3bcf
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item::after {
  left: calc(-12px / 2);
}

body:not(.rtl)
  .elementor-7
  .elementor-element.elementor-element-2da3bcf
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item::after {
  right: calc(-12px / 2);
}

.elementor-7
  .elementor-element.elementor-element-2da3bcf
  .elementor-icon-list-icon
  i {
  color: #fff;
  font-size: 14px;
}

.elementor-7
  .elementor-element.elementor-element-2da3bcf
  .elementor-icon-list-icon
  svg {
  fill: #fff;
  width: 14px;
}

.elementor-7
  .elementor-element.elementor-element-2da3bcf
  .elementor-icon-list-text {
  color: #fff;
}

.elementor-7
  .elementor-element.elementor-element-2da3bcf
  > .elementor-widget-container {
  padding: 15px 0 0;
}

.elementor-7 .elementor-element.elementor-element-4027e13a .elementor-button {
  background-color: #fff;
  border-radius: 15px;
  color: #2e5ba5;
  fill: #2e5ba5;
  font-size: 15px;
  font-weight: 600;
  padding: 12px 30px;
}

.elementor-7
  .elementor-element.elementor-element-63b274a8:not(.elementor-motion-effects-element-type-background)
  > .elementor-widget-wrap,
.elementor-7
  .elementor-element.elementor-element-63b274a8
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-image: url("../../images/landing/virtual-medical-consultations-in-South-Afruca-Dr-Sihle-Prime-medical-solutions.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.elementor-7
  .elementor-element.elementor-element-63b274a8
  > .elementor-element-populated,
.elementor-7
  .elementor-element.elementor-element-63b274a8
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-7
  .elementor-element.elementor-element-63b274a8
  > .elementor-background-slideshow {
  border-radius: 0 40px 40px 0;
}

.elementor-7
  .elementor-element.elementor-element-63b274a8
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-63b274a8
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-7 .elementor-element.elementor-element-63b274a8 {
  z-index: 5;
}

.elementor-7
  .elementor-element.elementor-element-482e2b99
  .elementor-spacer-inner {
  height: 50px;
}

.elementor-7
  .elementor-element.elementor-element-a198574:not(.elementor-motion-effects-element-type-background),
.elementor-7
  .elementor-element.elementor-element-a198574
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: rgba(87, 175, 211, 0.07);
}

.elementor-7 .elementor-element.elementor-element-a198574 {
  margin-bottom: 0%;
  margin-top: 0%;
  padding: 0 0 20px;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-a198574
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-bc-flex-widget
  .elementor-7
  .elementor-element.elementor-element-d7b1e32.elementor-column
  .elementor-widget-wrap {
  align-items: center;
}

.elementor-7
  .elementor-element.elementor-element-d7b1e32.elementor-column.elementor-element[data-element_type="column"]
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}

.elementor-7
  .elementor-element.elementor-element-d7b1e32.elementor-column
  > .elementor-widget-wrap {
  justify-content: center;
}

.elementor-7
  .elementor-element.elementor-element-d7b1e32:not(.elementor-motion-effects-element-type-background)
  > .elementor-widget-wrap,
.elementor-7
  .elementor-element.elementor-element-d7b1e32
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ef6b8a;
}

.elementor-7
  .elementor-element.elementor-element-d7b1e32
  > .elementor-element-populated,
.elementor-7
  .elementor-element.elementor-element-d7b1e32
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-7
  .elementor-element.elementor-element-d7b1e32
  > .elementor-background-slideshow {
  border-radius: 40px 0 0 40px;
}

.elementor-7
  .elementor-element.elementor-element-d7b1e32
  > .elementor-element-populated {
  padding: 12%;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-d7b1e32
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-7 .elementor-element.elementor-element-03be2ae {
  text-align: left;
}

.elementor-7
  .elementor-element.elementor-element-03be2ae
  .elementor-heading-title {
  color: rgba(255, 255, 255, 0.42);
  font-family: "Raleway", Sans-serif;
  font-size: 80px;
  font-weight: 800;
}

.elementor-7
  .elementor-element.elementor-element-03be2ae
  > .elementor-widget-container {
  margin: 0 0 -70px -33px;
  padding: 0;
}

.elementor-7 .elementor-element.elementor-element-dfc5181 {
  text-align: left;
}

.elementor-7
  .elementor-element.elementor-element-dfc5181
  .elementor-heading-title {
  color: #fff;
  font-family: "Raleway", Sans-serif;
  font-size: 25px;
}

.elementor-7
  .elementor-element.elementor-element-dfc5181
  > .elementor-widget-container {
  margin: 0;
  padding: 10px 0 0;
}

.elementor-7
  .elementor-element.elementor-element-c5c89c3
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:last-child) {
  padding-bottom: calc(12px / 2);
}

.elementor-7
  .elementor-element.elementor-element-c5c89c3
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:first-child) {
  margin-top: calc(12px / 2);
}

.elementor-7
  .elementor-element.elementor-element-c5c89c3
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item {
  margin-left: calc(12px / 2);
  margin-right: calc(12px / 2);
}

.elementor-7
  .elementor-element.elementor-element-c5c89c3
  .elementor-icon-list-items.elementor-inline-items {
  margin-left: calc(-12px / 2);
  margin-right: calc(-12px / 2);
}

body.rtl
  .elementor-7
  .elementor-element.elementor-element-c5c89c3
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item::after {
  left: calc(-12px / 2);
}

body:not(.rtl)
  .elementor-7
  .elementor-element.elementor-element-c5c89c3
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item::after {
  right: calc(-12px / 2);
}

.elementor-7
  .elementor-element.elementor-element-c5c89c3
  .elementor-icon-list-icon
  i {
  color: #fff;
  font-size: 14px;
}

.elementor-7
  .elementor-element.elementor-element-c5c89c3
  .elementor-icon-list-icon
  svg {
  fill: #fff;
  width: 14px;
}

.elementor-7
  .elementor-element.elementor-element-c5c89c3
  .elementor-icon-list-text {
  color: #fff;
}

.elementor-7
  .elementor-element.elementor-element-c5c89c3
  > .elementor-widget-container {
  padding: 15px 0 0;
}

.elementor-7 .elementor-element.elementor-element-bc127ff .elementor-button {
  background-color: #fff;
  border-radius: 15px;
  color: #2e5ba5;
  fill: #2e5ba5;
  font-size: 15px;
  font-weight: 600;
  padding: 12px 30px;
}

.elementor-7
  .elementor-element.elementor-element-ef239e0:not(.elementor-motion-effects-element-type-background)
  > .elementor-widget-wrap,
.elementor-7
  .elementor-element.elementor-element-ef239e0
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-image: url("../../images/landing/virtual-medical-consultations-in-South-Afruca-Dr-Sihle-Prime-medical-solutions.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.elementor-7
  .elementor-element.elementor-element-ef239e0
  > .elementor-element-populated,
.elementor-7
  .elementor-element.elementor-element-ef239e0
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-7
  .elementor-element.elementor-element-ef239e0
  > .elementor-background-slideshow {
  border-radius: 0 40px 40px 0;
}

.elementor-7
  .elementor-element.elementor-element-ef239e0
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-ef239e0
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-7 .elementor-element.elementor-element-ef239e0 {
  z-index: 5;
}

.elementor-7
  .elementor-element.elementor-element-d3170f0
  .elementor-spacer-inner {
  height: 50px;
}

.elementor-7
  .elementor-element.elementor-element-ea6592a:not(.elementor-motion-effects-element-type-background),
.elementor-7
  .elementor-element.elementor-element-ea6592a
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: rgba(87, 175, 211, 0.07);
}

.elementor-7 .elementor-element.elementor-element-ea6592a {
  margin-bottom: 0%;
  margin-top: 0%;
  padding: 0 0 20px;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-ea6592a
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-83c3cfe:not(.elementor-motion-effects-element-type-background)
  > .elementor-widget-wrap,
.elementor-7
  .elementor-element.elementor-element-83c3cfe
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-image: url("../../images/landing/Medical-HomeOffice-Consultations-primemedicalsa.co_.za-2.jpg");
  background-position: center right;
  background-repeat: no-repeat;
  background-size: cover;
}

.elementor-7
  .elementor-element.elementor-element-83c3cfe
  > .elementor-element-populated,
.elementor-7
  .elementor-element.elementor-element-83c3cfe
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-7
  .elementor-element.elementor-element-83c3cfe
  > .elementor-background-slideshow {
  border-radius: 40px 0 0 40px;
}

.elementor-7
  .elementor-element.elementor-element-83c3cfe
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-83c3cfe
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-7 .elementor-element.elementor-element-83c3cfe {
  z-index: 5;
}

.elementor-7
  .elementor-element.elementor-element-9651c9f
  .elementor-spacer-inner {
  height: 50px;
}

.elementor-bc-flex-widget
  .elementor-7
  .elementor-element.elementor-element-6b7accf.elementor-column
  .elementor-widget-wrap {
  align-items: center;
}

.elementor-7
  .elementor-element.elementor-element-6b7accf.elementor-column.elementor-element[data-element_type="column"]
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}

.elementor-7
  .elementor-element.elementor-element-6b7accf.elementor-column
  > .elementor-widget-wrap {
  justify-content: center;
}

.elementor-7
  .elementor-element.elementor-element-6b7accf:not(.elementor-motion-effects-element-type-background)
  > .elementor-widget-wrap,
.elementor-7
  .elementor-element.elementor-element-6b7accf
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ef6b8a;
}

.elementor-7
  .elementor-element.elementor-element-6b7accf
  > .elementor-element-populated,
.elementor-7
  .elementor-element.elementor-element-6b7accf
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-7
  .elementor-element.elementor-element-6b7accf
  > .elementor-background-slideshow {
  border-radius: 0 40px 40px 0;
}

.elementor-7
  .elementor-element.elementor-element-6b7accf
  > .elementor-element-populated {
  padding: 12%;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-6b7accf
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-7 .elementor-element.elementor-element-af94537 {
  text-align: left;
}

.elementor-7
  .elementor-element.elementor-element-af94537
  .elementor-heading-title {
  color: rgba(255, 255, 255, 0.42);
  font-family: "Raleway", Sans-serif;
  font-size: 80px;
  font-weight: 800;
}

.elementor-7
  .elementor-element.elementor-element-af94537
  > .elementor-widget-container {
  margin: 0 0 -70px -33px;
  padding: 0;
}

.elementor-7 .elementor-element.elementor-element-d056660 {
  text-align: left;
}

.elementor-7
  .elementor-element.elementor-element-d056660
  .elementor-heading-title {
  color: #fff;
  font-family: "Raleway", Sans-serif;
  font-size: 25px;
}

.elementor-7
  .elementor-element.elementor-element-d056660
  > .elementor-widget-container {
  padding: 10px 0 0;
}

.elementor-7
  .elementor-element.elementor-element-7834a60
  .elementor-text-editor {
  column-gap: 0;
  text-align: left;
}

.elementor-7 .elementor-element.elementor-element-7834a60 {
  color: #fff;
  font-family: "Raleway", Sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.elementor-7
  .elementor-element.elementor-element-7834a60
  > .elementor-widget-container {
  margin: -11px 0 0;
}

.elementor-7
  .elementor-element.elementor-element-a279981
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:last-child) {
  padding-bottom: calc(12px / 2);
}

.elementor-7
  .elementor-element.elementor-element-a279981
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:first-child) {
  margin-top: calc(12px / 2);
}

.elementor-7
  .elementor-element.elementor-element-a279981
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item {
  margin-left: calc(12px / 2);
  margin-right: calc(12px / 2);
}

.elementor-7
  .elementor-element.elementor-element-a279981
  .elementor-icon-list-items.elementor-inline-items {
  margin-left: calc(-12px / 2);
  margin-right: calc(-12px / 2);
}

body.rtl
  .elementor-7
  .elementor-element.elementor-element-a279981
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item::after {
  left: calc(-12px / 2);
}

body:not(.rtl)
  .elementor-7
  .elementor-element.elementor-element-a279981
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item::after {
  right: calc(-12px / 2);
}

.elementor-7
  .elementor-element.elementor-element-a279981
  .elementor-icon-list-icon
  i {
  color: #fff;
  font-size: 14px;
}

.elementor-7
  .elementor-element.elementor-element-a279981
  .elementor-icon-list-icon
  svg {
  fill: #fff;
  width: 14px;
}

.elementor-7
  .elementor-element.elementor-element-a279981
  .elementor-icon-list-text {
  color: #fff;
}

.elementor-7 .elementor-element.elementor-element-205585f .elementor-button {
  background-color: #fff;
  border-radius: 15px;
  color: #2e5ba5;
  fill: #2e5ba5;
  font-size: 15px;
  font-weight: 600;
  padding: 12px 30px;
}

.elementor-7
  .elementor-element.elementor-element-9e8e391:not(.elementor-motion-effects-element-type-background),
.elementor-7
  .elementor-element.elementor-element-9e8e391
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: rgba(87, 175, 211, 0.07);
}

.elementor-7 .elementor-element.elementor-element-9e8e391 {
  margin-bottom: 0%;
  margin-top: 0%;
  padding: 0 0 20px;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-9e8e391
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-bc-flex-widget
  .elementor-7
  .elementor-element.elementor-element-8a920f6.elementor-column
  .elementor-widget-wrap {
  align-items: center;
}

.elementor-7
  .elementor-element.elementor-element-8a920f6.elementor-column.elementor-element[data-element_type="column"]
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}

.elementor-7
  .elementor-element.elementor-element-8a920f6.elementor-column
  > .elementor-widget-wrap {
  justify-content: center;
}

.elementor-7
  .elementor-element.elementor-element-8a920f6:not(.elementor-motion-effects-element-type-background)
  > .elementor-widget-wrap,
.elementor-7
  .elementor-element.elementor-element-8a920f6
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ef6b8a;
}

.elementor-7
  .elementor-element.elementor-element-8a920f6
  > .elementor-element-populated,
.elementor-7
  .elementor-element.elementor-element-8a920f6
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-7
  .elementor-element.elementor-element-8a920f6
  > .elementor-background-slideshow {
  border-radius: 40px 0 0 40px;
}

.elementor-7
  .elementor-element.elementor-element-8a920f6
  > .elementor-element-populated {
  padding: 12%;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-8a920f6
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-7 .elementor-element.elementor-element-0dcff4c {
  text-align: left;
}

.elementor-7
  .elementor-element.elementor-element-0dcff4c
  .elementor-heading-title {
  color: rgba(255, 255, 255, 0.42);
  font-family: "Raleway", Sans-serif;
  font-size: 80px;
  font-weight: 800;
}

.elementor-7
  .elementor-element.elementor-element-0dcff4c
  > .elementor-widget-container {
  margin: 0 0 -70px -33px;
  padding: 0;
}

.elementor-7 .elementor-element.elementor-element-0d118dc {
  text-align: left;
}

.elementor-7
  .elementor-element.elementor-element-0d118dc
  .elementor-heading-title {
  color: #fff;
  font-family: "Raleway", Sans-serif;
  font-size: 25px;
}

.elementor-7
  .elementor-element.elementor-element-0d118dc
  > .elementor-widget-container {
  margin: 0;
  padding: 10px 0 0;
}

.elementor-7
  .elementor-element.elementor-element-9c40d13
  .elementor-text-editor {
  column-gap: 0;
  text-align: left;
}

.elementor-7 .elementor-element.elementor-element-9c40d13 {
  color: #fff;
  font-family: "Raleway", Sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.elementor-7
  .elementor-element.elementor-element-9c40d13
  > .elementor-widget-container {
  margin: -11px 0 0;
}

.elementor-7
  .elementor-element.elementor-element-76c0c3c
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:last-child) {
  padding-bottom: calc(12px / 2);
}

.elementor-7
  .elementor-element.elementor-element-76c0c3c
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:first-child) {
  margin-top: calc(12px / 2);
}

.elementor-7
  .elementor-element.elementor-element-76c0c3c
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item {
  margin-left: calc(12px / 2);
  margin-right: calc(12px / 2);
}

.elementor-7
  .elementor-element.elementor-element-76c0c3c
  .elementor-icon-list-items.elementor-inline-items {
  margin-left: calc(-12px / 2);
  margin-right: calc(-12px / 2);
}

body.rtl
  .elementor-7
  .elementor-element.elementor-element-76c0c3c
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item::after {
  left: calc(-12px / 2);
}

body:not(.rtl)
  .elementor-7
  .elementor-element.elementor-element-76c0c3c
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item::after {
  right: calc(-12px / 2);
}

.elementor-7
  .elementor-element.elementor-element-76c0c3c
  .elementor-icon-list-icon
  i {
  color: #fff;
  font-size: 14px;
}

.elementor-7
  .elementor-element.elementor-element-76c0c3c
  .elementor-icon-list-icon
  svg {
  fill: #fff;
  width: 14px;
}

.elementor-7
  .elementor-element.elementor-element-76c0c3c
  .elementor-icon-list-text {
  color: #fff;
}

.elementor-7
  .elementor-element.elementor-element-76c0c3c
  > .elementor-widget-container {
  padding: 15px 0 0;
}

.elementor-7 .elementor-element.elementor-element-81066ad .elementor-button {
  background-color: #fff;
  border-radius: 15px;
  color: #2e5ba5;
  fill: #2e5ba5;
  font-size: 15px;
  font-weight: 600;
  padding: 12px 30px;
}

.elementor-7
  .elementor-element.elementor-element-35adb38:not(.elementor-motion-effects-element-type-background)
  > .elementor-widget-wrap,
.elementor-7
  .elementor-element.elementor-element-35adb38
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-image: url("../../images/landing/Medical-HomeOffice-Consultations-primemedicalsa.co_.za-2.jpg");
  background-position: center right;
  background-repeat: no-repeat;
  background-size: cover;
}

.elementor-7
  .elementor-element.elementor-element-35adb38
  > .elementor-element-populated,
.elementor-7
  .elementor-element.elementor-element-35adb38
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-7
  .elementor-element.elementor-element-35adb38
  > .elementor-background-slideshow {
  border-radius: 40px 0 0 40px;
}

.elementor-7
  .elementor-element.elementor-element-35adb38
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-35adb38
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-7 .elementor-element.elementor-element-35adb38 {
  z-index: 5;
}

.elementor-7
  .elementor-element.elementor-element-76f89b8
  .elementor-spacer-inner {
  height: 50px;
}

.elementor-7
  .elementor-element.elementor-element-7b62a53f:not(.elementor-motion-effects-element-type-background),
.elementor-7
  .elementor-element.elementor-element-7b62a53f
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: rgba(87, 175, 211, 0.07);
}

.elementor-7 .elementor-element.elementor-element-7b62a53f {
  padding: 020px 0 20px;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-7b62a53f
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-bc-flex-widget
  .elementor-7
  .elementor-element.elementor-element-5ca29388.elementor-column
  .elementor-widget-wrap {
  align-items: center;
}

.elementor-7
  .elementor-element.elementor-element-5ca29388.elementor-column.elementor-element[data-element_type="column"]
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}

.elementor-7
  .elementor-element.elementor-element-5ca29388.elementor-column
  > .elementor-widget-wrap {
  justify-content: center;
}

.elementor-7
  .elementor-element.elementor-element-5ca29388:not(.elementor-motion-effects-element-type-background)
  > .elementor-widget-wrap,
.elementor-7
  .elementor-element.elementor-element-5ca29388
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ef6b8a;
}

.elementor-7
  .elementor-element.elementor-element-5ca29388
  > .elementor-element-populated,
.elementor-7
  .elementor-element.elementor-element-5ca29388
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-7
  .elementor-element.elementor-element-5ca29388
  > .elementor-background-slideshow {
  border-radius: 40px 0 0 40px;
}

.elementor-7
  .elementor-element.elementor-element-5ca29388
  > .elementor-element-populated {
  padding: 12%;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-5ca29388
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-7 .elementor-element.elementor-element-99b187c {
  text-align: left;
}

.elementor-7
  .elementor-element.elementor-element-99b187c
  .elementor-heading-title {
  color: rgba(255, 255, 255, 0.42);
  font-family: "Raleway", Sans-serif;
  font-size: 80px;
  font-weight: 800;
}

.elementor-7
  .elementor-element.elementor-element-99b187c
  > .elementor-widget-container {
  margin: 0 0 -70px -33px;
  padding: 0;
}

.elementor-7 .elementor-element.elementor-element-94db57f {
  text-align: left;
}

.elementor-7
  .elementor-element.elementor-element-94db57f
  .elementor-heading-title {
  color: #fff;
  font-family: "Raleway", Sans-serif;
  font-size: 25px;
}

.elementor-7
  .elementor-element.elementor-element-94db57f
  > .elementor-widget-container {
  padding: 10px 0 0;
}

.elementor-7
  .elementor-element.elementor-element-ae39382
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:last-child) {
  padding-bottom: calc(12px / 2);
}

.elementor-7
  .elementor-element.elementor-element-ae39382
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:first-child) {
  margin-top: calc(12px / 2);
}

.elementor-7
  .elementor-element.elementor-element-ae39382
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item {
  margin-left: calc(12px / 2);
  margin-right: calc(12px / 2);
}

.elementor-7
  .elementor-element.elementor-element-ae39382
  .elementor-icon-list-items.elementor-inline-items {
  margin-left: calc(-12px / 2);
  margin-right: calc(-12px / 2);
}

body.rtl
  .elementor-7
  .elementor-element.elementor-element-ae39382
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item::after {
  left: calc(-12px / 2);
}

body:not(.rtl)
  .elementor-7
  .elementor-element.elementor-element-ae39382
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item::after {
  right: calc(-12px / 2);
}

.elementor-7
  .elementor-element.elementor-element-ae39382
  .elementor-icon-list-icon
  i {
  color: #fff;
  font-size: 14px;
}

.elementor-7
  .elementor-element.elementor-element-ae39382
  .elementor-icon-list-icon
  svg {
  fill: #fff;
  width: 14px;
}

.elementor-7
  .elementor-element.elementor-element-ae39382
  .elementor-icon-list-text {
  color: #fff;
}

.elementor-7
  .elementor-element.elementor-element-ae39382
  > .elementor-widget-container {
  padding: 15px 0 0;
}

.elementor-7 .elementor-element.elementor-element-4cccfb3 .elementor-button {
  background-color: #fff;
  border-radius: 15px;
  color: #2e5ba5;
  fill: #2e5ba5;
  font-size: 15px;
  font-weight: 600;
  padding: 12px 30px;
}

.elementor-7
  .elementor-element.elementor-element-15a9671e:not(.elementor-motion-effects-element-type-background)
  > .elementor-widget-wrap,
.elementor-7
  .elementor-element.elementor-element-15a9671e
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-image: url("../../images/landing/Intravenous-Vitamin-Therapy-prime-medical-solutions.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.elementor-7
  .elementor-element.elementor-element-15a9671e
  > .elementor-element-populated,
.elementor-7
  .elementor-element.elementor-element-15a9671e
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-7
  .elementor-element.elementor-element-15a9671e
  > .elementor-background-slideshow {
  border-radius: 0 40px 40px 0;
}

.elementor-7
  .elementor-element.elementor-element-15a9671e
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-15a9671e
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-7 .elementor-element.elementor-element-15a9671e {
  z-index: 5;
}

.elementor-7
  .elementor-element.elementor-element-62cdb896
  .elementor-spacer-inner {
  height: 50px;
}

.elementor-7
  .elementor-element.elementor-element-30bb530:not(.elementor-motion-effects-element-type-background),
.elementor-7
  .elementor-element.elementor-element-30bb530
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: rgba(87, 175, 211, 0.07);
}

.elementor-7 .elementor-element.elementor-element-30bb530 {
  padding: 020px 0 20px;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-30bb530
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-bc-flex-widget
  .elementor-7
  .elementor-element.elementor-element-54baa37.elementor-column
  .elementor-widget-wrap {
  align-items: center;
}

.elementor-7
  .elementor-element.elementor-element-54baa37.elementor-column.elementor-element[data-element_type="column"]
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}

.elementor-7
  .elementor-element.elementor-element-54baa37.elementor-column
  > .elementor-widget-wrap {
  justify-content: center;
}

.elementor-7
  .elementor-element.elementor-element-54baa37:not(.elementor-motion-effects-element-type-background)
  > .elementor-widget-wrap,
.elementor-7
  .elementor-element.elementor-element-54baa37
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ef6b8a;
}

.elementor-7
  .elementor-element.elementor-element-54baa37
  > .elementor-element-populated,
.elementor-7
  .elementor-element.elementor-element-54baa37
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-7
  .elementor-element.elementor-element-54baa37
  > .elementor-background-slideshow {
  border-radius: 40px 0 0 40px;
}

.elementor-7
  .elementor-element.elementor-element-54baa37
  > .elementor-element-populated {
  padding: 12%;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-54baa37
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-7 .elementor-element.elementor-element-1b15d39 {
  text-align: left;
}

.elementor-7
  .elementor-element.elementor-element-1b15d39
  .elementor-heading-title {
  color: rgba(255, 255, 255, 0.42);
  font-family: "Raleway", Sans-serif;
  font-size: 80px;
  font-weight: 800;
}

.elementor-7
  .elementor-element.elementor-element-1b15d39
  > .elementor-widget-container {
  margin: 0 0 -70px -33px;
  padding: 0;
}

.elementor-7 .elementor-element.elementor-element-54f0079 {
  text-align: left;
}

.elementor-7
  .elementor-element.elementor-element-54f0079
  .elementor-heading-title {
  color: #fff;
  font-family: "Raleway", Sans-serif;
  font-size: 25px;
}

.elementor-7
  .elementor-element.elementor-element-54f0079
  > .elementor-widget-container {
  margin: 0;
  padding: 10px 0 0;
}

.elementor-7
  .elementor-element.elementor-element-a0aac9d
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:last-child) {
  padding-bottom: calc(12px / 2);
}

.elementor-7
  .elementor-element.elementor-element-a0aac9d
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:first-child) {
  margin-top: calc(12px / 2);
}

.elementor-7
  .elementor-element.elementor-element-a0aac9d
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item {
  margin-left: calc(12px / 2);
  margin-right: calc(12px / 2);
}

.elementor-7
  .elementor-element.elementor-element-a0aac9d
  .elementor-icon-list-items.elementor-inline-items {
  margin-left: calc(-12px / 2);
  margin-right: calc(-12px / 2);
}

body.rtl
  .elementor-7
  .elementor-element.elementor-element-a0aac9d
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item::after {
  left: calc(-12px / 2);
}

body:not(.rtl)
  .elementor-7
  .elementor-element.elementor-element-a0aac9d
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item::after {
  right: calc(-12px / 2);
}

.elementor-7
  .elementor-element.elementor-element-a0aac9d
  .elementor-icon-list-icon
  i {
  color: #fff;
  font-size: 14px;
}

.elementor-7
  .elementor-element.elementor-element-a0aac9d
  .elementor-icon-list-icon
  svg {
  fill: #fff;
  width: 14px;
}

.elementor-7
  .elementor-element.elementor-element-a0aac9d
  .elementor-icon-list-text {
  color: #fff;
}

.elementor-7
  .elementor-element.elementor-element-a0aac9d
  > .elementor-widget-container {
  padding: 15px 0 0;
}

.elementor-7 .elementor-element.elementor-element-f1b2cc0 .elementor-button {
  background-color: #fff;
  border-radius: 15px;
  color: #2e5ba5;
  fill: #2e5ba5;
  font-size: 15px;
  font-weight: 600;
  padding: 12px 30px;
}

.elementor-7
  .elementor-element.elementor-element-ba42efd:not(.elementor-motion-effects-element-type-background)
  > .elementor-widget-wrap,
.elementor-7
  .elementor-element.elementor-element-ba42efd
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-image: url("../../images/landing/Intravenous-Vitamin-Therapy-prime-medical-solutions.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.elementor-7
  .elementor-element.elementor-element-ba42efd
  > .elementor-element-populated,
.elementor-7
  .elementor-element.elementor-element-ba42efd
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-7
  .elementor-element.elementor-element-ba42efd
  > .elementor-background-slideshow {
  border-radius: 0 40px 40px 0;
}

.elementor-7
  .elementor-element.elementor-element-ba42efd
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-ba42efd
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-7 .elementor-element.elementor-element-ba42efd {
  z-index: 5;
}

.elementor-7
  .elementor-element.elementor-element-b773a3f
  .elementor-spacer-inner {
  height: 50px;
}

.elementor-7
  .elementor-element.elementor-element-28c182dc:not(.elementor-motion-effects-element-type-background),
.elementor-7
  .elementor-element.elementor-element-28c182dc
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: rgba(87, 175, 211, 0.07);
}

.elementor-7 .elementor-element.elementor-element-28c182dc {
  margin-bottom: 0%;
  margin-top: 0%;
  padding: 20px 0 40px;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-28c182dc
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-5a08774
  > .elementor-element-populated,
.elementor-7
  .elementor-element.elementor-element-5a08774
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-7
  .elementor-element.elementor-element-5a08774
  > .elementor-background-slideshow {
  border-radius: 40px 0 0 40px;
}

.elementor-7
  .elementor-element.elementor-element-5a08774
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-5a08774
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-7 .elementor-element.elementor-element-5a08774 {
  z-index: 5;
}

.elementor-7
  .elementor-element.elementor-element-28093e18
  .elementor-spacer-inner {
  height: 50px;
}

.elementor-bc-flex-widget
  .elementor-7
  .elementor-element.elementor-element-183d8f25.elementor-column
  .elementor-widget-wrap {
  align-items: center;
}

.elementor-7
  .elementor-element.elementor-element-183d8f25.elementor-column.elementor-element[data-element_type="column"]
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}

.elementor-7
  .elementor-element.elementor-element-183d8f25.elementor-column
  > .elementor-widget-wrap {
  justify-content: center;
}

.elementor-7
  .elementor-element.elementor-element-183d8f25:not(.elementor-motion-effects-element-type-background)
  > .elementor-widget-wrap,
.elementor-7
  .elementor-element.elementor-element-183d8f25
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ef6b8a;
}

.elementor-7
  .elementor-element.elementor-element-183d8f25
  > .elementor-element-populated,
.elementor-7
  .elementor-element.elementor-element-183d8f25
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-7
  .elementor-element.elementor-element-183d8f25
  > .elementor-background-slideshow {
  border-radius: 0 40px 40px 0;
}

.elementor-7
  .elementor-element.elementor-element-183d8f25
  > .elementor-element-populated {
  padding: 12%;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-183d8f25
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-7 .elementor-element.elementor-element-9abe479 {
  text-align: left;
}

.elementor-7
  .elementor-element.elementor-element-9abe479
  .elementor-heading-title {
  color: rgba(255, 255, 255, 0.42);
  font-family: "Raleway", Sans-serif;
  font-size: 80px;
  font-weight: 800;
}

.elementor-7
  .elementor-element.elementor-element-9abe479
  > .elementor-widget-container {
  margin: 0 0 -70px -33px;
  padding: 0;
}

.elementor-7 .elementor-element.elementor-element-6550c09 {
  text-align: left;
}

.elementor-7
  .elementor-element.elementor-element-6550c09
  .elementor-heading-title {
  color: #fff;
  font-family: "Raleway", Sans-serif;
  font-size: 25px;
}

.elementor-7
  .elementor-element.elementor-element-6550c09
  > .elementor-widget-container {
  padding: 10px 0 0;
}

.elementor-7
  .elementor-element.elementor-element-c9ee49e
  .elementor-text-editor {
  column-gap: 0;
  text-align: left;
}

.elementor-7 .elementor-element.elementor-element-c9ee49e {
  color: #fff;
  font-family: "Raleway", Sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.elementor-7
  .elementor-element.elementor-element-c9ee49e
  > .elementor-widget-container {
  margin: -11px 0 0;
}

.elementor-7 .elementor-element.elementor-element-51c2d79a .elementor-button {
  background-color: #fff;
  border-radius: 15px;
  color: #2e5ba5;
  fill: #2e5ba5;
  font-size: 15px;
  font-weight: 600;
  padding: 12px 30px;
}

.elementor-7
  .elementor-element.elementor-element-0a67ffd:not(.elementor-motion-effects-element-type-background),
.elementor-7
  .elementor-element.elementor-element-0a67ffd
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: rgba(87, 175, 211, 0.07);
}

.elementor-7 .elementor-element.elementor-element-0a67ffd {
  margin-bottom: 0%;
  margin-top: 0%;
  padding: 20px 0 40px;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-0a67ffd
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-5dc0cd5
  > .elementor-element-populated,
.elementor-7
  .elementor-element.elementor-element-5dc0cd5
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-7
  .elementor-element.elementor-element-5dc0cd5
  > .elementor-background-slideshow {
  border-radius: 40px 0 0 40px;
}

.elementor-7
  .elementor-element.elementor-element-5dc0cd5
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-5dc0cd5
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-7 .elementor-element.elementor-element-5dc0cd5 {
  z-index: 5;
}

.elementor-7
  .elementor-element.elementor-element-c598032
  .elementor-spacer-inner {
  height: 50px;
}

.elementor-bc-flex-widget
  .elementor-7
  .elementor-element.elementor-element-75782dd.elementor-column
  .elementor-widget-wrap {
  align-items: center;
}

.elementor-7
  .elementor-element.elementor-element-75782dd.elementor-column.elementor-element[data-element_type="column"]
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}

.elementor-7
  .elementor-element.elementor-element-75782dd.elementor-column
  > .elementor-widget-wrap {
  justify-content: center;
}

.elementor-7
  .elementor-element.elementor-element-75782dd:not(.elementor-motion-effects-element-type-background)
  > .elementor-widget-wrap,
.elementor-7
  .elementor-element.elementor-element-75782dd
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ef6b8a;
}

.elementor-7
  .elementor-element.elementor-element-75782dd
  > .elementor-element-populated,
.elementor-7
  .elementor-element.elementor-element-75782dd
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-7
  .elementor-element.elementor-element-75782dd
  > .elementor-background-slideshow {
  border-radius: 0 40px 40px 0;
}

.elementor-7
  .elementor-element.elementor-element-75782dd
  > .elementor-element-populated {
  padding: 12%;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-75782dd
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-7 .elementor-element.elementor-element-a05c622 {
  text-align: left;
}

.elementor-7
  .elementor-element.elementor-element-a05c622
  .elementor-heading-title {
  color: rgba(255, 255, 255, 0.42);
  font-family: "Raleway", Sans-serif;
  font-size: 80px;
  font-weight: 800;
}

.elementor-7
  .elementor-element.elementor-element-a05c622
  > .elementor-widget-container {
  margin: 0 0 -70px -33px;
  padding: 0;
}

.elementor-7 .elementor-element.elementor-element-f011824 {
  text-align: left;
}

.elementor-7
  .elementor-element.elementor-element-f011824
  .elementor-heading-title {
  color: #fff;
  font-family: "Raleway", Sans-serif;
  font-size: 25px;
}

.elementor-7
  .elementor-element.elementor-element-f011824
  > .elementor-widget-container {
  margin: 0;
  padding: 10px 0 0;
}

.elementor-7
  .elementor-element.elementor-element-aecfd7f
  .elementor-text-editor {
  column-gap: 0;
  text-align: left;
}

.elementor-7 .elementor-element.elementor-element-aecfd7f {
  color: #fff;
  font-family: "Raleway", Sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.elementor-7
  .elementor-element.elementor-element-aecfd7f
  > .elementor-widget-container {
  margin: -11px 0 0;
}

.elementor-7 .elementor-element.elementor-element-6711047 .elementor-button {
  background-color: #fff;
  border-radius: 15px;
  color: #2e5ba5;
  fill: #2e5ba5;
  font-size: 15px;
  font-weight: 600;
  padding: 12px 30px;
}

.elementor-7
  .elementor-element.elementor-element-f46d300:not(.elementor-motion-effects-element-type-background),
.elementor-7
  .elementor-element.elementor-element-f46d300
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #fff;
}

.elementor-7 .elementor-element.elementor-element-f46d300 {
  padding: 50px 0;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-f46d300
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-7 .elementor-element.elementor-element-36e222fb {
  text-align: center;
}

.elementor-7
  .elementor-element.elementor-element-36e222fb
  .elementor-heading-title {
  color: #b0205a;
  font-family: "Montserrat", Sans-serif;
  font-size: 32px;
  font-weight: 700;
}

.elementor-7
  .elementor-element.elementor-element-36e222fb
  > .elementor-widget-container {
  padding: 20px 0 0;
}

.elementor-7
  .elementor-element.elementor-element-4c232a4
  .elementor-text-editor {
  text-align: center;
}

.elementor-7 .elementor-element.elementor-element-4c232a4 {
  color: #54595f;
}

.elementor-7 .elementor-element.elementor-element-79a3b84a {
  padding: 0 0 30px;
}

.elementor-7
  .elementor-element.elementor-element-76161154.elementor-position-right
  .elementor-image-box-img {
  margin-left: 15px;
}

.elementor-7
  .elementor-element.elementor-element-76161154.elementor-position-left
  .elementor-image-box-img {
  margin-right: 15px;
}

.elementor-7
  .elementor-element.elementor-element-76161154.elementor-position-top
  .elementor-image-box-img {
  margin-bottom: 15px;
}

.elementor-7
  .elementor-element.elementor-element-76161154
  .elementor-image-box-wrapper
  .elementor-image-box-img {
  width: 28%;
}

.elementor-7
  .elementor-element.elementor-element-76161154
  .elementor-image-box-img
  img {
  transition-duration: 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-76161154
  .elementor-image-box-title {
  margin-bottom: 12px;
}

.elementor-7
  .elementor-element.elementor-element-76161154
  .elementor-image-box-content
  .elementor-image-box-title {
  color: #000;
  font-family: "Montserrat", Sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.elementor-7
  .elementor-element.elementor-element-76161154
  .elementor-image-box-content
  .elementor-image-box-description {
  color: #000;
  font-family: "Open Sans", Sans-serif;
  font-size: 14px;
}

.elementor-7
  .elementor-element.elementor-element-dfe8f27.elementor-position-right
  .elementor-image-box-img {
  margin-left: 15px;
}

.elementor-7
  .elementor-element.elementor-element-dfe8f27.elementor-position-left
  .elementor-image-box-img {
  margin-right: 15px;
}

.elementor-7
  .elementor-element.elementor-element-dfe8f27.elementor-position-top
  .elementor-image-box-img {
  margin-bottom: 15px;
}

.elementor-7
  .elementor-element.elementor-element-dfe8f27
  .elementor-image-box-wrapper
  .elementor-image-box-img {
  width: 28%;
}

.elementor-7
  .elementor-element.elementor-element-dfe8f27
  .elementor-image-box-img
  img {
  transition-duration: 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-dfe8f27
  .elementor-image-box-title {
  margin-bottom: 12px;
}

.elementor-7
  .elementor-element.elementor-element-dfe8f27
  .elementor-image-box-content
  .elementor-image-box-title {
  color: #000;
  font-family: "Montserrat", Sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.elementor-7
  .elementor-element.elementor-element-dfe8f27
  .elementor-image-box-content
  .elementor-image-box-description {
  color: #000;
  font-family: "Open Sans", Sans-serif;
  font-size: 14px;
}

.elementor-7
  .elementor-element.elementor-element-4cf46f55.elementor-position-right
  .elementor-image-box-img {
  margin-left: 15px;
}

.elementor-7
  .elementor-element.elementor-element-4cf46f55.elementor-position-left
  .elementor-image-box-img {
  margin-right: 15px;
}

.elementor-7
  .elementor-element.elementor-element-4cf46f55.elementor-position-top
  .elementor-image-box-img {
  margin-bottom: 15px;
}

.elementor-7
  .elementor-element.elementor-element-4cf46f55
  .elementor-image-box-wrapper
  .elementor-image-box-img {
  width: 28%;
}

.elementor-7
  .elementor-element.elementor-element-4cf46f55
  .elementor-image-box-img
  img {
  transition-duration: 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-4cf46f55
  .elementor-image-box-title {
  margin-bottom: 12px;
}

.elementor-7
  .elementor-element.elementor-element-4cf46f55
  .elementor-image-box-content
  .elementor-image-box-title {
  color: #000;
  font-family: "Montserrat", Sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.elementor-7
  .elementor-element.elementor-element-4cf46f55
  .elementor-image-box-content
  .elementor-image-box-description {
  color: #000;
  font-family: "Open Sans", Sans-serif;
  font-size: 14px;
}

.elementor-7 .elementor-element.elementor-element-5704684c .elementor-button {
  background-color: #b0205a;
  color: #fff;
  fill: #fff;
}

.elementor-7
  .elementor-element.elementor-element-7be2f770
  > .elementor-background-overlay {
  background-color: #b0205a;
  opacity: 0.65;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-7 .elementor-element.elementor-element-7be2f770 {
  margin-bottom: 0;
  margin-top: 0;
  padding: 0 0 40px;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  z-index: 0;
}

.elementor-7
  .elementor-element.elementor-element-5a641270
  > .elementor-element-populated {
  margin: 50px 0 0;
}

.elementor-7 .elementor-element.elementor-element-762245b8 {
  text-align: center;
}

.elementor-7
  .elementor-element.elementor-element-762245b8
  .elementor-heading-title {
  color: #fff;
  font-family: "Raleway", Sans-serif;
  font-size: 35px;
  font-weight: 800;
  text-transform: uppercase;
}

.elementor-7
  .elementor-element.elementor-element-e091fb4
  .elementor-text-editor {
  column-gap: 0;
  text-align: center;
}

.elementor-7 .elementor-element.elementor-element-e091fb4 {
  color: #fff;
  font-family: "Raleway", Sans-serif;
  font-size: 17px;
  font-weight: 600;
}

.elementor-7
  .elementor-element.elementor-element-e091fb4
  > .elementor-widget-container {
  margin: -11px 0 0;
}

.elementor-7 .elementor-element.elementor-element-7b41820 {
  text-align: center;
}

.elementor-7
  .elementor-element.elementor-element-7b41820
  .elementor-star-rating {
  font-size: 23px;
}

.elementor-7
  .elementor-element.elementor-element-7b41820
  > .elementor-widget-container {
  margin: -35px 0 0;
}

.elementor-7
  .elementor-element.elementor-element-57271c5f:not(.elementor-motion-effects-element-type-background),
.elementor-7
  .elementor-element.elementor-element-57271c5f
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #304770;
}

.elementor-7 .elementor-element.elementor-element-57271c5f {
  margin-bottom: 0;
  margin-top: -45px;
  padding: 0 0 90px;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  z-index: 5;
}

.elementor-7
  .elementor-element.elementor-element-57271c5f
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-57271c5f
  > .elementor-shape-top
  .elementor-shape-fill {
  fill: #ce7495;
}

.elementor-7
  .elementor-element.elementor-element-57271c5f
  > .elementor-shape-top
  svg {
  height: 40px;
}

.elementor-7
  .elementor-element.elementor-element-57271c5f
  > .elementor-shape-top {
  pointer-events: none;
  z-index: 2;
}

.elementor-7
  .elementor-element.elementor-element-14a6b079
  > .elementor-element-populated {
  margin: 50px 0 0;
}

.elementor-7 .elementor-element.elementor-element-1403ff91 {
  text-align: center;
}

.elementor-7
  .elementor-element.elementor-element-1403ff91
  .elementor-heading-title {
  color: #fff;
  font-family: "Raleway", Sans-serif;
  font-size: 40px;
  font-weight: 900;
  text-transform: uppercase;
}

.elementor-7
  .elementor-element.elementor-element-1403ff91
  > .elementor-widget-container {
  margin: 60px 0 0;
}

.elementor-7
  .elementor-element.elementor-element-49ad950a
  .elementor-text-editor {
  column-gap: 0;
  text-align: center;
}

.elementor-7 .elementor-element.elementor-element-49ad950a {
  color: #fff;
  font-family: "Raleway", Sans-serif;
  font-size: 17px;
  font-weight: 600;
}

.elementor-7
  .elementor-element.elementor-element-49ad950a
  > .elementor-widget-container {
  margin: -11px 0 0;
}

.elementor-7
  .elementor-element.elementor-element-b89a73f:not(.elementor-motion-effects-element-type-background)
  > .elementor-widget-wrap,
.elementor-7
  .elementor-element.elementor-element-b89a73f
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-image: url("../../images/landing/Video-Consultations-Banner-prime-medical-solutions-banner-1.jpg");
  background-position: center right;
  background-size: cover;
}

.elementor-7
  .elementor-element.elementor-element-b89a73f
  > .elementor-element-populated {
  margin: 0;
  padding: 0;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-b89a73f
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-d8292f4
  .elementor-spacer-inner {
  height: 50px;
}

.elementor-7
  .elementor-element.elementor-element-808747f:not(.elementor-motion-effects-element-type-background)
  > .elementor-widget-wrap,
.elementor-7
  .elementor-element.elementor-element-808747f
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: rgba(0, 0, 0, 0.7);
}

.elementor-7
  .elementor-element.elementor-element-808747f
  > .elementor-element-populated,
.elementor-7
  .elementor-element.elementor-element-808747f
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-7
  .elementor-element.elementor-element-808747f
  > .elementor-background-slideshow {
  border-radius: 0 0 50px;
}

.elementor-7
  .elementor-element.elementor-element-808747f
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-7
  .elementor-element.elementor-element-808747f
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-7 .elementor-element.elementor-element-1ed48ff {
  text-align: center;
}

.elementor-7
  .elementor-element.elementor-element-1ed48ff
  .elementor-heading-title {
  color: #fff;
  font-family: "Montserrat", Sans-serif;
  font-size: 24px;
  font-weight: 700;
}

.elementor-7
  .elementor-element.elementor-element-1ed48ff
  > .elementor-widget-container {
  padding: 6% 0% 0%;
}

.elementor-7
  .elementor-element.elementor-element-8790d67
  .elementor-field-group {
  margin-bottom: 18px;
  padding-left: calc(7px / 2);
  padding-right: calc(7px / 2);
}

.elementor-7
  .elementor-element.elementor-element-8790d67
  .elementor-form-fields-wrapper {
  margin-bottom: -18px;
  margin-left: calc(-7px / 2);
  margin-right: calc(-7px / 2);
}

.elementor-7
  .elementor-element.elementor-element-8790d67
  .elementor-field-group.recaptcha_v3-bottomleft,
.elementor-7
  .elementor-element.elementor-element-8790d67
  .elementor-field-group.recaptcha_v3-bottomright {
  margin-bottom: 0;
}

body.rtl
  .elementor-7
  .elementor-element.elementor-element-8790d67
  .elementor-labels-inline
  .elementor-field-group
  > label {
  padding-left: 0;
}

body:not(.rtl)
  .elementor-7
  .elementor-element.elementor-element-8790d67
  .elementor-labels-inline
  .elementor-field-group
  > label {
  padding-right: 0;
}

body
  .elementor-7
  .elementor-element.elementor-element-8790d67
  .elementor-labels-above
  .elementor-field-group
  > label {
  padding-bottom: 0;
}

.elementor-7
  .elementor-element.elementor-element-8790d67
  .elementor-field-type-html {
  padding-bottom: 0;
}

.elementor-7
  .elementor-element.elementor-element-8790d67
  .elementor-field-group:not(.elementor-field-type-upload)
  .elementor-field:not(.elementor-select-wrapper) {
  background-color: #fff;
  border-width: 0;
}

.elementor-7
  .elementor-element.elementor-element-8790d67
  .elementor-field-group
  .elementor-select-wrapper
  select {
  background-color: #fff;
  border-width: 0;
}

.elementor-7
  .elementor-element.elementor-element-8790d67
  .e-form__buttons__wrapper__button-next {
  background-color: #8bc34a;
  color: #fff;
}

.elementor-7
  .elementor-element.elementor-element-8790d67
  .elementor-button[type="submit"] {
  background-color: #8bc34a;
  color: #fff;
}

.elementor-7
  .elementor-element.elementor-element-8790d67
  .elementor-button[type="submit"]
  svg
  * {
  fill: #fff;
}

.elementor-7
  .elementor-element.elementor-element-8790d67
  .e-form__buttons__wrapper__button-previous {
  color: #fff;
}

.elementor-7
  .elementor-element.elementor-element-8790d67
  .e-form__buttons__wrapper__button-next:hover {
  background-color: #8bc34a;
  color: #fff;
}

.elementor-7
  .elementor-element.elementor-element-8790d67
  .elementor-button[type="submit"]:hover {
  background-color: #8bc34a;
  color: #fff;
}

.elementor-7
  .elementor-element.elementor-element-8790d67
  .elementor-button[type="submit"]:hover
  svg
  * {
  fill: #fff;
}

.elementor-7
  .elementor-element.elementor-element-8790d67
  .e-form__buttons__wrapper__button-previous:hover {
  color: #fff;
}

.elementor-7 .elementor-element.elementor-element-8790d67 {
  --e-form-steps-indicators-spacing: 20px;
  --e-form-steps-indicator-padding: 30px;
  --e-form-steps-indicator-inactive-secondary-color: #fff;
  --e-form-steps-indicator-active-secondary-color: #fff;
  --e-form-steps-indicator-completed-secondary-color: #fff;
  --e-form-steps-divider-width: 1px;
  --e-form-steps-divider-gap: 10px;
}

.elementor-7
  .elementor-element.elementor-element-8790d67
  > .elementor-widget-container {
  margin: 0%;
  padding: 10% 8%;
}

:root {
  --page-title-display: none;
}
@media (max-width: 1024px) {
  .elementor-7
    .elementor-element.elementor-element-fddb134
    > .elementor-container {
    min-height: 316px;
  }

  .elementor-7
    .elementor-element.elementor-element-fddb134
    > .elementor-container::after {
    content: "";
    min-height: inherit;
  }

  .elementor-7
    .elementor-element.elementor-element-fddb134:not(.elementor-motion-effects-element-type-background),
  .elementor-7
    .elementor-element.elementor-element-fddb134
    > .elementor-motion-effects-container
    > .elementor-motion-effects-layer {
    background-position: center center;
    background-size: cover;
  }

  .elementor-7 .elementor-element.elementor-element-fddb134 {
    padding: 0 25px;
  }

  .elementor-7
    .elementor-element.elementor-element-29b3ada
    .elementor-heading-title {
    font-size: 24px;
  }

  .elementor-7
    .elementor-element.elementor-element-29b3ada
    > .elementor-widget-container {
    margin: 0;
  }

  .elementor-7
    .elementor-element.elementor-element-2e876ec
    .elementor-heading-title {
    font-size: 15px;
  }

  .elementor-7
    .elementor-element.elementor-element-2e876ec
    > .elementor-widget-container {
    margin: 0;
  }

  .elementor-7
    .elementor-element.elementor-element-66a5ca99
    .elementor-heading-title {
    font-size: 24px;
  }

  .elementor-7
    .elementor-element.elementor-element-66a5ca99
    > .elementor-widget-container {
    margin: 0;
  }

  .elementor-7 .elementor-element.elementor-element-58462e62 {
    padding: 0 25px;
  }

  .elementor-7 .elementor-element.elementor-element-268d3a6f {
    padding: 0 15px 15px;
  }

  .elementor-7
    .elementor-element.elementor-element-63b274a8:not(.elementor-motion-effects-element-type-background)
    > .elementor-widget-wrap,
  .elementor-7
    .elementor-element.elementor-element-63b274a8
    > .elementor-widget-wrap
    > .elementor-motion-effects-container
    > .elementor-motion-effects-layer {
    background-position: center right;
    background-size: cover;
  }

  .elementor-7 .elementor-element.elementor-element-a198574 {
    padding: 0 15px 15px;
  }

  .elementor-7
    .elementor-element.elementor-element-ef239e0:not(.elementor-motion-effects-element-type-background)
    > .elementor-widget-wrap,
  .elementor-7
    .elementor-element.elementor-element-ef239e0
    > .elementor-widget-wrap
    > .elementor-motion-effects-container
    > .elementor-motion-effects-layer {
    background-position: center right;
    background-size: cover;
  }

  .elementor-7 .elementor-element.elementor-element-ea6592a {
    padding: 0 15px 15px;
  }

  .elementor-7 .elementor-element.elementor-element-9e8e391 {
    padding: 0 15px 15px;
  }

  .elementor-7 .elementor-element.elementor-element-7b62a53f {
    padding: 15px;
  }

  .elementor-7 .elementor-element.elementor-element-30bb530 {
    padding: 15px;
  }

  .elementor-7 .elementor-element.elementor-element-28c182dc {
    padding: 15px;
  }

  .elementor-7 .elementor-element.elementor-element-0a67ffd {
    padding: 15px;
  }

  .elementor-7
    .elementor-element.elementor-element-36e222fb
    .elementor-heading-title {
    font-size: 24px;
  }

  .elementor-7
    .elementor-element.elementor-element-4c232a4
    > .elementor-widget-container {
    padding: 0;
  }

  .elementor-7 .elementor-element.elementor-element-79a3b84a {
    padding: 0% 3%;
  }

  .elementor-7
    .elementor-element.elementor-element-76161154
    .elementor-image-box-wrapper
    .elementor-image-box-img {
    width: 35%;
  }

  .elementor-7
    .elementor-element.elementor-element-76161154
    > .elementor-widget-container {
    padding: 0% 0% 10%;
  }

  .elementor-7
    .elementor-element.elementor-element-dfe8f27
    .elementor-image-box-wrapper
    .elementor-image-box-img {
    width: 35%;
  }

  .elementor-7
    .elementor-element.elementor-element-dfe8f27
    > .elementor-widget-container {
    padding: 0% 0% 10%;
  }

  .elementor-7
    .elementor-element.elementor-element-4cf46f55
    .elementor-image-box-wrapper
    .elementor-image-box-img {
    width: 35%;
  }

  .elementor-7
    .elementor-element.elementor-element-762245b8
    .elementor-heading-title {
    font-size: 35px;
  }

  .elementor-7
    .elementor-element.elementor-element-762245b8
    > .elementor-widget-container {
    margin: 0;
  }

  .elementor-7
    .elementor-element.elementor-element-e091fb4
    .elementor-text-editor {
    text-align: center;
  }

  .elementor-7
    .elementor-element.elementor-element-57271c5f
    > .elementor-shape-top
    svg {
    height: 23px;
  }

  .elementor-7 .elementor-element.elementor-element-57271c5f {
    padding: 0 0 50px;
  }

  .elementor-7 .elementor-element.elementor-element-1403ff91 {
    text-align: center;
  }

  .elementor-7
    .elementor-element.elementor-element-1403ff91
    .elementor-heading-title {
    font-size: 35px;
  }

  .elementor-7
    .elementor-element.elementor-element-1403ff91
    > .elementor-widget-container {
    margin: 0;
    padding: 0;
  }

  .elementor-7
    .elementor-element.elementor-element-49ad950a
    .elementor-text-editor {
    text-align: center;
  }

  .elementor-7
    .elementor-element.elementor-element-b89a73f:not(.elementor-motion-effects-element-type-background)
    > .elementor-widget-wrap,
  .elementor-7
    .elementor-element.elementor-element-b89a73f
    > .elementor-widget-wrap
    > .elementor-motion-effects-container
    > .elementor-motion-effects-layer {
    background-image: url("../../images/landing/Video-Consultations-Banner-prime-medical-solutions-banner-1.jpg");
    background-position: center center;
    background-size: cover;
  }

  .elementor-7
    .elementor-element.elementor-element-1ed48ff
    .elementor-heading-title {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .elementor-7
    .elementor-element.elementor-element-fddb134
    > .elementor-container {
    min-height: 7px;
  }

  .elementor-7
    .elementor-element.elementor-element-fddb134
    > .elementor-container::after {
    content: "";
    min-height: inherit;
  }

  .elementor-7
    .elementor-element.elementor-element-fddb134:not(.elementor-motion-effects-element-type-background),
  .elementor-7
    .elementor-element.elementor-element-fddb134
    > .elementor-motion-effects-container
    > .elementor-motion-effects-layer {
    background-size: cover;
  }

  .elementor-7 .elementor-element.elementor-element-fddb134 {
    padding: 25px;
  }

  .elementor-7
    .elementor-element.elementor-element-29b3ada
    .elementor-heading-title {
    font-size: 17px;
  }

  .elementor-7
    .elementor-element.elementor-element-2e876ec
    .elementor-heading-title {
    font-size: 14px;
  }

  .elementor-7 .elementor-element.elementor-element-be06bd3 {
    width: 50%;
  }

  .elementor-7 .elementor-element.elementor-element-fe8e4ff .elementor-button {
    font-size: 12px;
  }

  .elementor-7 .elementor-element.elementor-element-aae5ec0 {
    width: 50%;
  }

  .elementor-7 .elementor-element.elementor-element-58af6b7 .elementor-button {
    font-size: 12px;
  }

  .elementor-7 .elementor-element.elementor-element-758e365 {
    padding: 25px 15px 0;
  }

  .elementor-7
    .elementor-element.elementor-element-66a5ca99
    .elementor-heading-title {
    font-size: 17px;
  }

  .elementor-7 .elementor-element.elementor-element-7f93922 {
    font-size: 14px;
  }

  .elementor-7
    .elementor-element.elementor-element-7f93922
    > .elementor-widget-container {
    margin: 0 20px;
  }

  .elementor-7 .elementor-element.elementor-element-58462e62 {
    padding: 20px;
  }

  .elementor-7 .elementor-element.elementor-element-da3a937 {
    width: 50%;
  }

  .elementor-7
    .elementor-element.elementor-element-da3a937
    > .elementor-element-populated {
    margin: 0;
  }

  .elementor-7 .elementor-element.elementor-element-243bef4 {
    font-size: 14px;
  }

  .elementor-7
    .elementor-element.elementor-element-243bef4
    > .elementor-widget-container {
    margin: 0 20px;
  }

  .elementor-7
    .elementor-element.elementor-element-3287c4c3
    > .elementor-element-populated {
    padding: 50px 30px;
  }

  .elementor-7
    .elementor-element.elementor-element-63b274a8
    > .elementor-element-populated {
    padding: 25% 0%;
  }

  .elementor-7
    .elementor-element.elementor-element-d7b1e32
    > .elementor-element-populated,
  .elementor-7
    .elementor-element.elementor-element-d7b1e32
    > .elementor-element-populated
    > .elementor-background-overlay,
  .elementor-7
    .elementor-element.elementor-element-d7b1e32
    > .elementor-background-slideshow {
    border-radius: 0 0 25px 25px;
  }

  .elementor-7
    .elementor-element.elementor-element-d7b1e32
    > .elementor-element-populated {
    padding: 50px 15px;
  }

  .elementor-7
    .elementor-element.elementor-element-03be2ae
    > .elementor-widget-container {
    margin: 0 0 -75px;
    padding: 0;
  }

  .elementor-7
    .elementor-element.elementor-element-dfc5181
    .elementor-heading-title {
    font-size: 21px;
  }

  .elementor-7
    .elementor-element.elementor-element-dfc5181
    > .elementor-widget-container {
    padding: 15px 0 0 21px;
  }

  .elementor-7
    .elementor-element.elementor-element-c5c89c3
    .elementor-icon-list-item,
  .elementor-7
    .elementor-element.elementor-element-c5c89c3
    .elementor-icon-list-item
    a {
    font-size: 15px;
  }

  .elementor-7
    .elementor-element.elementor-element-bc127ff
    > .elementor-widget-container {
    margin: 0 0 0 20px;
  }

  .elementor-7
    .elementor-element.elementor-element-ef239e0
    > .elementor-element-populated,
  .elementor-7
    .elementor-element.elementor-element-ef239e0
    > .elementor-element-populated
    > .elementor-background-overlay,
  .elementor-7
    .elementor-element.elementor-element-ef239e0
    > .elementor-background-slideshow {
    border-radius: 25px 25px 0 0;
  }

  .elementor-7
    .elementor-element.elementor-element-ef239e0
    > .elementor-element-populated {
    padding: 25% 0%;
  }

  .elementor-7
    .elementor-element.elementor-element-83c3cfe
    > .elementor-element-populated {
    padding: 25% 0%;
  }

  .elementor-7
    .elementor-element.elementor-element-9651c9f
    .elementor-spacer-inner {
    height: 305px;
  }

  .elementor-7
    .elementor-element.elementor-element-6b7accf
    > .elementor-element-populated {
    padding: 50px 30px;
  }

  .elementor-7 .elementor-element.elementor-element-7834a60 {
    font-size: 14px;
  }

  .elementor-7
    .elementor-element.elementor-element-7834a60
    > .elementor-widget-container {
    margin: 0 20px;
  }

  .elementor-7
    .elementor-element.elementor-element-8a920f6
    > .elementor-element-populated,
  .elementor-7
    .elementor-element.elementor-element-8a920f6
    > .elementor-element-populated
    > .elementor-background-overlay,
  .elementor-7
    .elementor-element.elementor-element-8a920f6
    > .elementor-background-slideshow {
    border-radius: 0 0 25px 25px;
  }

  .elementor-7
    .elementor-element.elementor-element-8a920f6
    > .elementor-element-populated {
    padding: 50px 15px;
  }

  .elementor-7
    .elementor-element.elementor-element-0dcff4c
    > .elementor-widget-container {
    margin: 0 0 -75px;
    padding: 0;
  }

  .elementor-7
    .elementor-element.elementor-element-0d118dc
    .elementor-heading-title {
    font-size: 21px;
  }

  .elementor-7
    .elementor-element.elementor-element-0d118dc
    > .elementor-widget-container {
    padding: 15px 0 0 21px;
  }

  .elementor-7 .elementor-element.elementor-element-9c40d13 {
    font-size: 14px;
  }

  .elementor-7
    .elementor-element.elementor-element-9c40d13
    > .elementor-widget-container {
    margin: 0 20px;
  }

  .elementor-7
    .elementor-element.elementor-element-76c0c3c
    .elementor-icon-list-item,
  .elementor-7
    .elementor-element.elementor-element-76c0c3c
    .elementor-icon-list-item
    a {
    font-size: 15px;
  }

  .elementor-7
    .elementor-element.elementor-element-76c0c3c
    > .elementor-widget-container {
    margin: -15px 0 0;
  }

  .elementor-7
    .elementor-element.elementor-element-81066ad
    > .elementor-widget-container {
    margin: 0 0 0 20px;
  }

  .elementor-7
    .elementor-element.elementor-element-35adb38
    > .elementor-element-populated,
  .elementor-7
    .elementor-element.elementor-element-35adb38
    > .elementor-element-populated
    > .elementor-background-overlay,
  .elementor-7
    .elementor-element.elementor-element-35adb38
    > .elementor-background-slideshow {
    border-radius: 25px 25px 0 0;
  }

  .elementor-7
    .elementor-element.elementor-element-35adb38
    > .elementor-element-populated {
    margin: 0;
    padding: 25% 0%;
  }

  .elementor-7
    .elementor-element.elementor-element-76f89b8
    .elementor-spacer-inner {
    height: 305px;
  }

  .elementor-7
    .elementor-element.elementor-element-5ca29388
    > .elementor-element-populated {
    padding: 50px 30px;
  }

  .elementor-7
    .elementor-element.elementor-element-15a9671e
    > .elementor-element-populated {
    padding: 25% 0%;
  }

  .elementor-7
    .elementor-element.elementor-element-54baa37
    > .elementor-element-populated,
  .elementor-7
    .elementor-element.elementor-element-54baa37
    > .elementor-element-populated
    > .elementor-background-overlay,
  .elementor-7
    .elementor-element.elementor-element-54baa37
    > .elementor-background-slideshow {
    border-radius: 0 0 25px 25px;
  }

  .elementor-7
    .elementor-element.elementor-element-54baa37
    > .elementor-element-populated {
    padding: 50px 15px;
  }

  .elementor-7
    .elementor-element.elementor-element-1b15d39
    > .elementor-widget-container {
    margin: 0 0 -75px;
    padding: 0;
  }

  .elementor-7
    .elementor-element.elementor-element-54f0079
    .elementor-heading-title {
    font-size: 21px;
  }

  .elementor-7
    .elementor-element.elementor-element-54f0079
    > .elementor-widget-container {
    padding: 15px 0 0 21px;
  }

  .elementor-7
    .elementor-element.elementor-element-a0aac9d
    .elementor-icon-list-item,
  .elementor-7
    .elementor-element.elementor-element-a0aac9d
    .elementor-icon-list-item
    a {
    font-size: 15px;
  }

  .elementor-7
    .elementor-element.elementor-element-a0aac9d
    > .elementor-widget-container {
    margin: -15px 0 0;
  }

  .elementor-7
    .elementor-element.elementor-element-f1b2cc0
    > .elementor-widget-container {
    margin: 0 0 0 20px;
  }

  .elementor-7
    .elementor-element.elementor-element-ba42efd
    > .elementor-element-populated,
  .elementor-7
    .elementor-element.elementor-element-ba42efd
    > .elementor-element-populated
    > .elementor-background-overlay,
  .elementor-7
    .elementor-element.elementor-element-ba42efd
    > .elementor-background-slideshow {
    border-radius: 25px 25px 0 0;
  }

  .elementor-7
    .elementor-element.elementor-element-ba42efd
    > .elementor-element-populated {
    padding: 25% 0%;
  }

  .elementor-7
    .elementor-element.elementor-element-5a08774
    > .elementor-element-populated {
    padding: 25% 0%;
  }

  .elementor-7
    .elementor-element.elementor-element-183d8f25
    > .elementor-element-populated {
    padding: 50px 30px;
  }

  .elementor-7 .elementor-element.elementor-element-c9ee49e {
    font-size: 14px;
  }

  .elementor-7
    .elementor-element.elementor-element-c9ee49e
    > .elementor-widget-container {
    margin: 0 20px;
  }

  .elementor-7
    .elementor-element.elementor-element-5dc0cd5
    > .elementor-element-populated,
  .elementor-7
    .elementor-element.elementor-element-5dc0cd5
    > .elementor-element-populated
    > .elementor-background-overlay,
  .elementor-7
    .elementor-element.elementor-element-5dc0cd5
    > .elementor-background-slideshow {
    border-radius: 25px 25px 0 0;
  }

  .elementor-7
    .elementor-element.elementor-element-5dc0cd5
    > .elementor-element-populated {
    padding: 25% 0%;
  }

  .elementor-7
    .elementor-element.elementor-element-75782dd
    > .elementor-element-populated,
  .elementor-7
    .elementor-element.elementor-element-75782dd
    > .elementor-element-populated
    > .elementor-background-overlay,
  .elementor-7
    .elementor-element.elementor-element-75782dd
    > .elementor-background-slideshow {
    border-radius: 0 0 25px 25px;
  }

  .elementor-7
    .elementor-element.elementor-element-75782dd
    > .elementor-element-populated {
    padding: 50px 30px;
  }

  .elementor-7
    .elementor-element.elementor-element-a05c622
    > .elementor-widget-container {
    margin: 0 0 -75px;
    padding: 0;
  }

  .elementor-7
    .elementor-element.elementor-element-f011824
    .elementor-heading-title {
    font-size: 21px;
  }

  .elementor-7
    .elementor-element.elementor-element-f011824
    > .elementor-widget-container {
    padding: 15px 0 0 25px;
  }

  .elementor-7 .elementor-element.elementor-element-aecfd7f {
    font-size: 14px;
  }

  .elementor-7
    .elementor-element.elementor-element-aecfd7f
    > .elementor-widget-container {
    margin: 0 20px;
  }

  .elementor-7
    .elementor-element.elementor-element-63426c1
    > .elementor-element-populated {
    padding: 0 15px;
  }

  .elementor-7
    .elementor-element.elementor-element-36e222fb
    .elementor-heading-title {
    font-size: 21px;
  }

  .elementor-7 .elementor-element.elementor-element-4c232a4 {
    font-size: 15px;
  }

  .elementor-7
    .elementor-element.elementor-element-4c232a4
    > .elementor-widget-container {
    padding: 0% 0% 8%;
  }

  .elementor-7
    .elementor-element.elementor-element-3e0e3bef
    > .elementor-element-populated {
    padding: 0;
  }

  .elementor-7
    .elementor-element.elementor-element-76161154
    .elementor-image-box-img {
    margin-bottom: 15px;
  }

  .elementor-7
    .elementor-element.elementor-element-76161154
    > .elementor-widget-container {
    padding: 0;
  }

  .elementor-7
    .elementor-element.elementor-element-501992bb
    > .elementor-element-populated {
    padding: 30px 0;
  }

  .elementor-7
    .elementor-element.elementor-element-dfe8f27
    .elementor-image-box-img {
    margin-bottom: 15px;
  }

  .elementor-7
    .elementor-element.elementor-element-dfe8f27
    > .elementor-widget-container {
    padding: 0;
  }

  .elementor-7
    .elementor-element.elementor-element-49b4b9cc
    > .elementor-element-populated {
    padding: 0 0 30px;
  }

  .elementor-7
    .elementor-element.elementor-element-4cf46f55
    .elementor-image-box-img {
    margin-bottom: 15px;
  }

  .elementor-7
    .elementor-element.elementor-element-4cf46f55
    > .elementor-widget-container {
    padding: 0;
  }

  .elementor-7 .elementor-element.elementor-element-5704684c .elementor-button {
    font-size: 12px;
  }

  .elementor-7
    .elementor-element.elementor-element-5a641270
    > .elementor-element-populated {
    padding: 0;
  }

  .elementor-7
    .elementor-element.elementor-element-762245b8
    .elementor-heading-title {
    font-size: 21px;
  }

  .elementor-7 .elementor-element.elementor-element-e091fb4 {
    font-size: 16px;
  }

  .elementor-7
    .elementor-element.elementor-element-e091fb4
    > .elementor-widget-container {
    margin: 0 20px;
  }

  .elementor-7 .elementor-element.elementor-element-57271c5f {
    margin-bottom: 0;
    margin-top: 0;
    padding: 0 0 50px;
  }

  .elementor-7
    .elementor-element.elementor-element-1403ff91
    .elementor-heading-title {
    font-size: 21px;
  }

  .elementor-7 .elementor-element.elementor-element-49ad950a {
    font-size: 15px;
  }

  .elementor-7
    .elementor-element.elementor-element-b89a73f
    > .elementor-element-populated {
    padding: 25px 0 0;
  }

  .elementor-7
    .elementor-element.elementor-element-d8292f4
    .elementor-spacer-inner {
    height: 215px;
  }
}
@media (min-width: 768px) {
  .elementor-7 .elementor-element.elementor-element-be06bd3 {
    width: 33.148%;
  }

  .elementor-7 .elementor-element.elementor-element-aae5ec0 {
    width: 66.852%;
  }

  .elementor-7 .elementor-element.elementor-element-3287c4c3 {
    width: 42.719%;
  }

  .elementor-7 .elementor-element.elementor-element-63b274a8 {
    width: 57.281%;
  }

  .elementor-7 .elementor-element.elementor-element-d7b1e32 {
    width: 42.719%;
  }

  .elementor-7 .elementor-element.elementor-element-ef239e0 {
    width: 57.281%;
  }

  .elementor-7 .elementor-element.elementor-element-83c3cfe {
    width: 42.895%;
  }

  .elementor-7 .elementor-element.elementor-element-6b7accf {
    width: 57.105%;
  }

  .elementor-7 .elementor-element.elementor-element-8a920f6 {
    width: 42.719%;
  }

  .elementor-7 .elementor-element.elementor-element-35adb38 {
    width: 57.281%;
  }

  .elementor-7 .elementor-element.elementor-element-5ca29388 {
    width: 53.509%;
  }

  .elementor-7 .elementor-element.elementor-element-15a9671e {
    width: 46.491%;
  }

  .elementor-7 .elementor-element.elementor-element-54baa37 {
    width: 42.719%;
  }

  .elementor-7 .elementor-element.elementor-element-ba42efd {
    width: 57.281%;
  }

  .elementor-7 .elementor-element.elementor-element-5a08774 {
    width: 50.614%;
  }

  .elementor-7 .elementor-element.elementor-element-183d8f25 {
    width: 49.386%;
  }

  .elementor-7 .elementor-element.elementor-element-5dc0cd5 {
    width: 50.614%;
  }

  .elementor-7 .elementor-element.elementor-element-75782dd {
    width: 49.386%;
  }

  .elementor-7 .elementor-element.elementor-element-b89a73f {
    width: 61.071%;
  }

  .elementor-7 .elementor-element.elementor-element-808747f {
    width: 38.929%;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .elementor-7 .elementor-element.elementor-element-be06bd3 {
    width: 45%;
  }

  .elementor-7 .elementor-element.elementor-element-aae5ec0 {
    width: 55%;
  }

  .elementor-7 .elementor-element.elementor-element-da3a937 {
    width: 75%;
  }

  .elementor-7 .elementor-element.elementor-element-3e0e3bef {
    width: 33.33%;
  }

  .elementor-7 .elementor-element.elementor-element-501992bb {
    width: 33.33%;
  }

  .elementor-7 .elementor-element.elementor-element-49b4b9cc {
    width: 33.33%;
  }

  .elementor-7 .elementor-element.elementor-element-b89a73f {
    width: 55%;
  }

  .elementor-7 .elementor-element.elementor-element-808747f {
    width: 45%;
  }
}
