.landing {
  font-weight: lighter;

  .services {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .services-header {
      color: $secondary-background-colour;
      font-size: 3rem;
      text-align: center;
    }
  }

  .accordion:last-child {
    margin-bottom: 8rem;
  }

  .accordion {
    margin-bottom: 2rem;
    width: 50%;

    .book-btn {
      float: right;
      margin-bottom: 2rem;
    }

    .register-btn {
      float: right;
      margin-bottom: 2rem;
    }
  
  }

  

  @media only screen and (max-width: $mobile-breakpoint-width) {
    .accordion {
      width: 90%;
    }

    .book-btn {
      margin-bottom: 3rem;
      margin-top: 3rem;
    }

    .register-btn {
      float: right;
      margin-bottom: 2rem;
    }
  }
}
