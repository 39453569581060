.document {
  border: 0.5px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  padding: 2rem;

  a {
    align-content: center;
    display: flex;

    svg {
      margin-right: 2rem;
    }
  }
}
