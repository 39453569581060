.binary-toggle {
  display: flex;
  justify-content: center;

  .binary-toggle-button {
    background-color: $primary-background-colour;
    border: 0;
    border-radius: $rounded-border-radius;
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
    font-weight: lighter;
    padding: 1rem 3rem;
    text-transform: uppercase;

    &.left {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    &.right {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    &.active {
      background-color: $disabled-colour;
      cursor: not-allowed;
    }
  }
}
