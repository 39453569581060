.landing-banner {
  background: #ededed;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: -2;

  .grid-container {
    align-items: center;

    border-radius: $standard-border-radius;
    display: grid;
    grid-gap: 5rem;
    grid-template-columns: repeat(20, 1fr);
    grid-template-rows: repeat(15, 50px);
    justify-items: center;

    row-gap: 0.2rem;
  }

  .wrapper {
    position: relative;
    width: 100%;
  }

  .content {
    align-items: flex-start;
    color: #fff;
    display: flex;
    font-size: 26px;
    font-weight: bold;
    height: 90vh;
    justify-content: center;
    position: relative;
    text-shadow: -1px -1px 1px #000, 1px 1px 1px #000;
    z-index: 1;

    .image-wrapper {
      align-items: center;
      background: #f7f7f7;
      border-radius: 3rem;
      display: flex;
      flex-direction: column;
      margin-top: 20rem;
      padding: 2rem;
    }
  }

  .background {
    height: 90vh;
    opacity: 0.4;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;

    svg {
      align-items: center;
      display: grid;
      font-size: 3rem;
      justify-content: center;
      margin: auto;
      stroke-width: 1.5rem;
    }
  }

  .animation {
    fill: none;
    stroke-dasharray: 2000;
    stroke-linecap: round;
    stroke-miterlimit: 10;

    &.computer-animation {
      animation: lines 10s infinite;
      animation-delay: 2s;
      color: $primary-background-colour;
    }

    &.stethoscope-animation {
      animation: lines 6s infinite;
      color: $primary-background-colour;
    }

    &.bandage-animation {
      animation: lines 6s infinite;
      color: $primary-background-colour;

      opacity: 0.5;

      stroke-miterlimit: 5;
    }

    &.heart-animation {
      animation: dash 4s linear infinite;
      color: $tertiary-background-colour;

      fill: none;
      stroke-dasharray: 2900;
      stroke-dashoffset: 2900;
      stroke-width: 3rem;
    }
  }

  .computer-top-layer {
    stroke-dasharray: 1000;
  }

  .stethoscope-top-layer {
    stroke-dasharray: 3000;
  }

  .bandage-top-layer {
    stroke-dasharray: 3000;
  }
  @keyframes dash {
    from {
      stroke-dashoffset: 6000;
    }

    to {
      stroke-dashoffset: 0;
    }
  }
  @keyframes lines {
    100% {
      stroke-dashoffset: 4000;
    }
  }

  @media only screen and (max-width: $mobile-breakpoint-width) {
    .container {
      height: 20rem;
    }

    .animation {
      animation: none !important;
    }

    .computer-top-layer {
      stroke-dasharray: 1000;
    }

    .stethoscope-top-layer {
      stroke-dasharray: 3000;
    }

    .bandage-top-layer {
      stroke-dasharray: 3000;
    }

    .heart-animation {
      stroke-dasharray: initial;
      stroke-dashoffset: 100 !important;
    }

    .grid-container {
      column-gap: 0;
      display: grid;
      gap: 0 0;
      grid-gap: 0;
      grid-row-gap: 0.1rem;
      grid-template-columns: repeat(15, 4rem);
      grid-template-rows: repeat(13, 6rem);
    }

    .content,
    .background {
      height: 100vh;
    }

    .content .image-wrapper {
      padding: 2rem 0;
      width: 90%;
    }

    .image-wrapper img {
      width: 80%;
    }

    svg {
      align-items: center;
      display: grid;
      font-size: 5rem;
      font-size: 3rem;
      height: 9rem;
      justify-content: center;
      margin: auto;
      stroke-width: 1.5rem;
      width: 9rem;
    }
  }
}
