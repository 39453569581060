.booking-confirmed {
  align-items: center;
  display: flex;
  flex-direction: column;

  h2,
  svg {
    color: $success-colour;
    font-size: 2rem;
    font-weight: bolder;
    margin-bottom: 2rem;
  }

  .new-appointment-button {
    background-color: $primary-background-colour;
    border: 0;
    border-radius: $standard-border-radius;
    color: #fff;
    cursor: pointer;
    padding: 2rem 3rem;
  }
}
