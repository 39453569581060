.select-doctor-location {
  max-width: fit-content;

  .standard-radio-select .radio-item {
    text-align: left;
  }

  @media only screen and (max-width: $mobile-breakpoint-width) {
    .doctor {
      margin: 1rem;
    }
  }
}
