.select-doctor {
  .available-doctors {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  .selected-doctor {
    font-size: 1.5rem;
  }

  .doctor {
    margin: 2rem;
  }

  @media only screen and (max-width: $mobile-breakpoint-width) {
    .doctor {
      justify-content: center;
      margin: 1rem;
    }

    .available-doctors {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      width: 100%;
    }
  }
}
