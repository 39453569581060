.register {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  form:nth-child(1) input {
    margin-bottom: 1rem;
  }

  form {
    min-width: 55%;

    label {
      margin-top: 0.5rem;
    }

    input {
      display: block;
    }
  }
}
