.address-lookup {
  .autocomplete-suggestion {
    align-items: center;
    color: #8c8c8c;
    display: flex;
    overflow: hidden;
    padding: 1rem;

    svg {
      margin-right: 1rem;
      min-height: 1.5rem;
      min-width: 1.5rem;
    }
  }
}
