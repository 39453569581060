.service-option {
  align-items: center;

  display: flex;
  justify-content: space-between;

  input {
    cursor: pointer;
  }

  label {
    align-items: center;
    display: flex;
    margin-right: $small;
    padding: 0 2rem;

    span {
      cursor: pointer;
      display: inline-flex;
      margin-left: 0.5rem;
    }
  }

  &.disabled {
    label,
    input,
    span {
      color: $disabled-colour;
      cursor: not-allowed;
    }
  }

  .cost {
    margin-right: 0.5rem;
  }
}
