.u-margin-bottom-big {
  margin-bottom: $big;
}

.u-margin-bottom-medium {
  margin-bottom: $medium;
}

.u-margin-bottom-small {
  margin-bottom: $small;
}

.u-margin-top-big {
  margin-top: $big;
}

.u-margin-top-medium {
  margin-top: $medium;
}

.u-margin-top-small {
  margin-top: $small;
}

.u-text-center {
  text-align: center;
}

.u-display-flex {
  display: flex;
}

.u-justify-content-center {
  justify-content: center;
}

.u-hidden-mobile {
  @media only screen and (max-width: $mobile-breakpoint-width) {
    display: none !important;
  }
}

.u-only-mobile {
  @media only screen and (min-width: $mobile-breakpoint-width) {
    display: none !important;
  }
}
