.footer {
  align-items: center;
  background-color: #6ec1e4;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  a {
    color: inherit;
    text-decoration: none;
  }

  .terms-of-use {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }

  .social-media {
    color: #fff;
    font-size: 2rem;
    margin-top: 0.5rem;

    a {
      margin-right: 2rem;
    }
  }

  .contact-details {
    align-items: flex-start;
    display: flex;
    float: left;
    text-align: left;

    a {
      align-items: center;
      color: inherit;
      display: flex;
      margin-left: $small;
      text-decoration: none;
    }

    svg {
      margin-right: 4px;
    }
  }

  @media only screen and (max-width: $mobile-breakpoint-width) {
    background-color: #6ec1e4;
    display: flex;
    flex-direction: row;
    font-size: 1rem;
    justify-content: center;
    padding-top: 1rem;
    text-align: center;

    .social-media {
      margin-top: 0;
    }

    .terms-of-use {
      align-content: center;
      display: flex;
      flex: 0 0 100%;
      font-size: 1rem;
      height: auto;
      justify-content: center;
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
      text-align: center;
    }

    .contact-details {
      align-items: center;
      flex-direction: column;
      margin-top: 0;

      div {
        margin-left: 0;
      }
    }
  }
}
