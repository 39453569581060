*,
*::after,
*::before {
  /* sets every elements sizing to inherit box-sizing (from the body selector) */
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  /* Defines what 1 rem is. 10/16 == % to use. We use % instead of pixels for root so that browsers with custom px won't work correctly. */
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
}
