.login-registration-toggle {
  align-items: center;
  background-color: $secondary-background-colour;
  border-radius: 10px;

  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 70%;
  justify-content: center;

  width: 100%;

  button {
    background: none;
    border: 1px solid #fff;
    border-bottom-left-radius: 1rem;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    outline: #fff;
    padding: 1rem;
  }

  button:hover {
    background: #fff;
    color: $secondary-background-colour;
  }
}
