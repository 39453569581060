.doctor {
  align-items: center;

  background-color: $secondary-background-colour;
  border: 3px #fff solid;
  border-radius: $standard-border-radius;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;

  .image-wrapper {
    height: 15rem;
    overflow: hidden;
    width: 15rem;
  }

  img {
    width: 100%;
    z-index: 0;
  }

  svg {
    margin-top: 4rem;
  }

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }

  &.selected {
    border-color: $primary-background-colour;
  }

  .doctor-name {
    align-items: center;
    display: block;
    display: flex;
    padding: 1rem 0;
    text-align: center;
  }

  &.disabled {
    background-color: $disabled-colour;
    cursor: not-allowed;

    .image-wrapper {
      opacity: 0.4;
    }
  }

  .sub-title {
    display: block;
    font-size: 1rem;
    text-align: center;
  }
  @media only screen and (max-width: $mobile-breakpoint-width) {
    height: 14rem;
    width: 12rem;

    .image-wrapper {
      height: initial;
      overflow: hidden;
      width: initial;
    }

    .doctor-name {
      align-items: center;
      display: flex;
      margin-top: 0;
      padding: 0.3rem;
    }
  }
}
