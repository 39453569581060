.form-control {
  border-radius: $standard-border-radius;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 50vh;
  min-width: 40%;
  padding: 2rem;

  .button-block {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
  }

  .navigation-button {
    align-items: center;
    align-self: center;
    background: $primary-background-colour;
    border: 0;
    border-radius: 0.4rem;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-size: 1.3rem;
    height: 3rem;
    justify-content: space-between;
    justify-content: center;
    padding: 0.5rem;
    text-align: center;
    width: 9rem;
  }

  .navigation-button + .navigation-button {
    margin-left: 2rem;
  }

  .navigation-button:disabled {
    background-color: $disabled-colour;
    cursor: not-allowed;
  }

  @media only screen and (max-width: $mobile-breakpoint-width) {
    max-width: 90%;
    min-width: 90%;

    .form-item {
      width: 100%;
    }
  }
}
