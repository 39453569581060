.capture-patient-details {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-width: 40rem;
  width: 100%;

  .autocomplete-dropdown-container {
    border-radius: $standard-border-radius;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    max-width: 40rem;
  }

  .autocomplete-suggestion {
    display: block;
    padding: 3px 0;
    width: 100%;
  }

  .autocomplete-suggestion:hover {
    background-color: rgba(0, 0, 0, 0.06);
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .medical-aid-question {
    text-align: center;
  }

  .standard-input {
    margin-bottom: 0.5rem;
  }

  .standard-button {
    display: inline;
  }

  .submit-form-button {
    background: $success-colour;
    border: 0;
    border-radius: 12px;
    color: #fff;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem 2rem;
    text-align: center;
  }

  .newsletter-signup {
    margin-top: 1rem;

    input {
      margin-right: 1rem;
    }

    label {
      align-items: center;
      display: inline-flex;
      font-size: 1.2rem;
    }
  }

  .policy-agreement {
    font-size: 0.9rem;
    margin-top: 1rem;
    text-align: center;
  }
  @media only screen and (max-width: $mobile-breakpoint-width) {
    width: 70%;

    form {
      width: 100%;
    }
  }
  @media only screen and (max-width: $tablet-breakpoint-width) {
    min-width: 20rem;
    width: 100%;

    .autocomplete-dropdown-container {
      max-width: 25rem;
    }

    form {
      width: 100%;
    }
  }
}
