.documents {
  h2 {
    font-weight: lighter;
  }

  .upload-block {
    display: flex;
    justify-content: flex-end;

    input {
      display: none;
    }
  }

  .upload-btn {
    align-items: center;
    background: #ecaebc;
    border: 0;
    border-radius: $rounded-border-radius;
    color: #fff;
    cursor: pointer;
    display: flex;
    justify-self: flex-end;
    margin-right: 0.5rem;
    padding: 1rem 2rem;
    text-decoration: none;
  }

  svg {
    margin-right: 0.4rem;
  }
}
