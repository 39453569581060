.newsletter-block {
  background-color: $primary-background-colour;
  color: #fff;
  display: flex;
  justify-content: center;
  padding: 2rem;
  text-align: center;

  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    padding: 0 2rem;
  }

  .input-block {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    max-width: 80%;

    input {
      text-align: center;
    }

    .input-error {
      margin-top: 1rem;
    }

    button {
      margin-top: 2rem;
      max-width: 50%;
    }
  }
}
