.PracticeInfo {
    /* background: rgb(236, 231, 231);  */
    /* background: radial-gradient(
      circle,
      rgb(255, 0, 0) 0%,
      rgba(212, 51, 16, 1) 84%,
      rgba(255, 0, 95, 1) 100%
    /* ); */
    width: 100vw;
    height: 100vh;
    display: grid;
    place-items: center;
  }
  