.patient {
  display: flex;
  flex: 1 1 0;
  flex-wrap: wrap;
  margin-left: 2rem;

  .left-hand-side {
    flex: 1 1 0;
  }

  .right-hand-side {
    flex: 1 1 0;
  }

  .back-btn {
    align-self: center;
    background: #ecaebc;
    border: 0;
    border-radius: 100%;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 2rem;
    margin-right: 2rem;
    padding: 0.5rem;
  }

  .page-heading {
    display: block;
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .patient-info {
    display: flex;
    flex-wrap: wrap;

    .detail-card-icon {
      margin-right: 0.4rem;
    }

    a {
      color: #fff;
      text-decoration: none;
    }

    .detail-card {
      align-items: center;
      background: #c5babd;
      border-radius: $rounded-border-radius;
      color: #fff;
      display: flex;
      margin-bottom: 0.5rem;
      margin-right: 0.5rem;
      padding: 1rem 2rem;
      text-decoration: none;
    }
  }
}
