.time-slot-picker {
  .time-list {
    height: 20em;
    line-height: 2em;
    margin: 0;
    overflow: scroll;
    overflow-x: hidden;
    padding: 0;
    width: 15em;
  }

  .title {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  @media only screen and (max-width: $mobile-breakpoint-width) {
    .time-list {
      width: 24rem;
    }
  }
}
