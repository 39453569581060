.accordion {
  align-items: center;
  background-color: $secondary-background-colour;

  border: 2px solid $secondary-background-colour;
  border-radius: $standard-border-radius;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 2px;

  .title {
    align-items: center;
    background: none;
    border: 0;
    color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    text-align: center;
    width: 100%;
  }

  .content-block {
    font-size: 2rem;
    padding: 2rem;
  }

  .accordion-icon {
    margin-left: auto;
    transition: transform 0.2s ease;
  }

  .rotate {
    transform: rotate(90deg);
  }

  h2 {
    font-weight: lighter;
  }

  section {
    background: #fff;
    border-bottom-left-radius: $standard-border-radius;
    border-bottom-right-radius: $standard-border-radius;
    color: $secondary-background-colour;
    overflow: hidden;
    transition: max-height 0.4s ease;
    width: 100%;
  }
}
