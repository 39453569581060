.standard-input {
  width: 100%;

  &.dark label {
    color: $dark-colour;
  }

  &.dark input {
    border: $dark-colour 1px solid;
  }

  .error-message {
    color: $error-colour;
    font-size: 1rem;
    text-transform: uppercase;
  }

  input {
    border: 0;
    border-radius: $standard-border-radius;
    display: block;
    margin-top: 0.5rem;
    padding: 1rem 0.5rem;
    width: 100%;
  }

  &.error input {
    border: $error-colour 2px solid;
  }

  label {
    color: #fff;
    font-size: 1rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
  }
}
