.login {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  h2 {
    color: #fff;
    justify-self: center;
  }

  form:nth-child(1) input {
    margin-bottom: 1rem;
  }

  form {
    min-width: 55%;

    label {
      margin-top: 0.5rem;
    }

    .forgot-password {
      align-self: right;
      align-self: flex-end;
      color: #fff;
      font-size: 0.9rem;
    }
  }
}
