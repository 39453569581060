$primary-background-colour: #ef6b8a;
$secondary-background-colour: #304770;
$tertiary-background-colour: #b0205a;
$disabled-colour: #ddd;
$primary-colour: rgb(0, 0, 0);
$dark-colour: rgb(43, 42, 42);
$warning-colour: #c41b1b;
$error-colour: #e94f4f;
$success-colour: #07940f;

$standard-border-radius: 0.4rem;
$rounded-border-radius: 2.4rem;

$mobile-breakpoint-width: 767px;
$tablet-breakpoint-width: 1023px;

$big: 8rem;
$medium: 4rem;
$small: 1.5rem;
