.service-category {
  .category-header {
    align-items: center;

    background: none;
    background-color: $secondary-background-colour;
    border: 0;
    color: #fff;
    cursor: pointer;
    display: flex;
    display: flex;
    font-size: 1rem;
    justify-content: space-between;
    padding: 1rem;
    text-align: center;
    width: 100%;
  }

  .service-option {
    align-items: center;
    display: flex;
    margin-top: $small;

    > input {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  section {
    background: #fff;
    border: 1px solid $primary-background-colour;
    border-bottom-left-radius: $standard-border-radius;
    border-bottom-right-radius: $standard-border-radius;
    color: $secondary-background-colour;
    overflow: hidden;
    padding-bottom: 2rem;
    transition: max-height 0.4s ease;
    width: 100%;
  }
}
