.standard-button {
  background-color: #fff;
  border: 0;
  border-radius: $standard-border-radius;
  color: $secondary-background-colour;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 600;
  padding: 1.2rem 3rem;
  width: 100%;
}
