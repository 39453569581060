.layout {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;

  main {
    flex-grow: 1;
  }

  header,
  main,
  footer {
    flex-shrink: 0;
  }

  main {
    padding-bottom: 10.5rem;
  }

  .small-padding-bottom {
    padding-bottom: 5rem;
  }
  @media only screen and (max-width: $mobile-breakpoint-width) {
    main {
      padding-bottom: 0;
    }
  }
}
