.sidebar {
  background-color: $secondary-background-colour;
  color: #fff;
  margin: 0;
  min-height: 185vh;

  min-width: 20rem;
  transition: width 2s ease;

  &.collapsed {
    align-items: flex-start;
    display: flex;
    justify-content: center;
    min-width: 4rem;
    transition: width 2s ease;

    .collapse-button {
      margin: 2rem 0 0;
    }
  }

  .logout-button {
    background: none;
    border: 0;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-weight: lighter;
    margin: auto;
    margin-top: 2rem;
  }

  .dashboard-heading {
    align-items: center;
    display: flex;

    h2 {
      display: inline;
      margin-right: 0.5rem;
    }
  }

  .collapse-button {
    align-items: center;
    background: none;
    border: 0;
    color: #fff;
    display: flex;
    font-size: 2rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  h2 {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 2rem 1rem;
  }

  .sidebar-item {
    align-items: center;
    align-items: center;
    background-color: $primary-background-colour;
    color: #fff;
    display: flex;

    padding: 1.5rem 1rem;
    text-decoration: none;

    &.active {
      background-color: $tertiary-background-colour;
      font-weight: bold;
    }

    svg {
      font-size: 2rem;
      margin-right: 1rem;
    }
  }
}
