.select-service {
  .service-category {
    margin-top: $small;
  }

  .total-cost {
    display: flex;
    justify-content: flex-end;
    margin-top: $medium;
  }

  .note {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-right: 1.5rem;

    max-width: 47rem;
    padding: 0 2rem;
  }
}
