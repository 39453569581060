.time-slot {
  background-color: #fff;
  border: $primary-background-colour 1px solid;
  border-radius: $standard-border-radius;
  color: $primary-background-colour;
  cursor: pointer;
  display: block;
  font-size: 1.1rem;
  font-weight: lighter;
  letter-spacing: 0;
  line-height: 1.5rem;
  margin-bottom: 0.9rem;

  padding: 1.2rem 2rem;
  width: 90%;

  &.disabled,
  &.disabled:hover {
    background-color: $disabled-colour;
    cursor: not-allowed;
    filter: none;
    opacity: 0.2;
  }

  &.active {
    background-color: $tertiary-background-colour;
    color: #fff;
  }

  &:hover {
    background-color: $tertiary-background-colour;
    color: #fff;
  }
  @media only screen and (max-width: $mobile-breakpoint-width) {
    width: 100%;
  }
}
