.book-btn {
  align-content: center;
  background: $primary-background-colour;
  border: $tertiary-background-colour 1px solid;
  border-radius: $standard-border-radius;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 1.5rem;
  padding: 1.5rem 2rem;
  text-decoration: none;

  svg {
    margin-right: 1rem;
  }
  @media only screen and (max-width: $mobile-breakpoint-width) {
    padding: 1rem;
  }
}
