.appointment-form {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 70%;
  min-width: 70%;

  .date-time-picker {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .selected-date {
    font-size: 1.5rem;
    margin-top: 0;
    text-align: center;
  }

  .show-date-button {
    background: none;
    border: 0;
    color: $primary-background-colour;
    display: none;
  }

  .time-slot-picker {
    margin-left: 2rem;
  }

  .create-booking {
    background-color: $primary-background-colour;
    border: 0;
    border: 1px solid $primary-background-colour;
    border-radius: $standard-border-radius;
    color: #fff;
    display: block;
    padding: 2rem 3rem;
    text-align: center;
    text-decoration: none;
  }
  @media only screen and (max-width: $mobile-breakpoint-width) {
    min-width: 100%;

    .show-date-button {
      display: inline-flex;
    }

    .date-time-picker .date-picker {
      margin-right: 0;
    }
  }
}
