@import url(https://fonts.googleapis.com/css?family=Montserrat);

.Register {
  /* background: rgb(236, 231, 231);  */
  /* background: radial-gradient(
    circle,
    rgb(255, 0, 0) 0%,
    rgba(212, 51, 16, 1) 84%,
    rgba(255, 0, 95, 1) 100%
  /* ); */
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
}

.Register2 {
  /* background: rgb(236, 231, 231);  */
  /* background: radial-gradient(
    circle,
    rgb(255, 0, 0) 0%,
    rgba(212, 51, 16, 1) 84%,
    rgba(255, 0, 95, 1) 100%
  /* ); */
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

[aria-hidden="true"] {
  display: none;
}


body {
  margin: 0%;
  padding: 0%;
  font-family: montserrat, arial, verdana;
}

.form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-container {
  width: 900px;
  height: 900px;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
}

.form-container .header2 {
 
  display: grid;
  place-items: Left;
}


/* .form-container .header1 {
  flex: 30%;
  display: grid;
  place-items: center;
} */


/* .form-container .body1 {
  flex: 40%;
} */

/* .form-container .footers {
  flex: 20%;
  display: flex;
  justify-content: center;
}

.form-container .footers button {
  border-radius: 7px;
  width: 130px;
  height: 40px;
  background-color: rgb(154, 148, 148);
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 1px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
  margin: 5px;
} */

.sign-up-container {
  height: 69%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sign-up-container input {
  margin: 5px;
  width: 281px;
  height: 40px;
  padding-left: 5px;
  font-size: 16px;
}

.personal-info-container {
  height: 56%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.personal-info-container input {
  margin: 5px;
  width: 567px;
  height: 40px;
  padding-left: 5px;
  font-size: 16px;
}

.check-out-container {
  height: 69%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.check-out-container input {
  margin: 5px;
  width: 327px;
  height: 40px;
  padding-left: 5px;
  font-size: 16px;
}

/* PROGRESS BAR */
.progressbar {
  width: 700px;
  height: 30px;
  background-color: rgb(246, 242, 242);
  margin-bottom: 50px;
  /* border-style: groove; */
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
}

.progressbar div {
  width: 20%;
  height: 100%;
  background-color: #b0205a;
 
}

.other-info-container {
  height: 55%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.other-info-container input {
  margin: 5px;
  width: 567px;
  height: 40px;
  padding-left: 5px;
  font-size: 16px;
}

.practice-setup-container {
  height: 55%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.practice-setup-container input {
  margin: 5px;
  width: 567px;
  height: 40px;
  padding-left: 5px;
  font-size: 16px;
}

input {
  border: 2px solid rgb(15, 15, 15);
  border-radius: 5px;
}

input:focus {
  border: 3px solid rgb(9, 1, 1);
}

/* .upload-btn {

    display: block;
    /* text-align: center; */
    /* margin: 20px auto 20px; */
    /* width: 50%; */
    /* height: 24px;
    background-color: #ef6b8a; */
    /* line-height: 30px; */

    /* } 

.upload-btn:hover {
    background-color: #f08aa2;
    color: white;
    cursor: pointer;
    }

    .upload-btn2 {

      display: block;
      /* text-align: center; */
      /* margin: 20px auto 20px; */
      /* width: 100%;
      height: 47px;
      background-color: #ef6b8a;
      /* line-height: 30px; */
  
      /* }
  
  .upload-btn2:hover {
      background-color: #f08aa2;
      color: white;
      cursor: pointer;
      } */ 



