.standard-radio-select {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  text-align: center;

  .radio-item {
    align-items: center;
    cursor: pointer;
    display: grid;
    font-size: 2rem;
    grid-gap: 0.5em;
    grid-template-columns: min-content auto;
    margin-bottom: 2rem;
    margin-right: 1.5rem;

    &.disabled {
      color: $disabled-colour;
      cursor: not-allowed;
    }
  }

  .radio-item-circle {
    border: 2px solid currentColor;
    border-radius: 50%;
    display: grid;
    font-size: 2rem;
    height: 1.4em;
    place-items: center;
    transform: translateY(-0.05em);
    transform: translateY(-0.05em);
    width: 1.4em;
  }

  .radio-item-label {
    line-height: 1;
    opacity: 0.8;
    transition: 180ms all ease-in-out;

    &--prefix {
      font-weight: bold;
    }
  }

  .radio-item-input {
    display: flex;

    input {
      height: 0;
      opacity: 0;
      width: 0;
    }
  }

  input:checked + .radio-item-circle::before {
    transform: scale(1);
  }

  input + .radio-item-circle::before {
    border-radius: 50%;
    box-shadow: inset 1.2em 1.2em $primary-background-colour;
    content: "";
    height: 0.6em;
    transform: scale(0);
    transform: scale(0);
    transition: 180ms transform ease-in-out;
    width: 0.6em;
  }
  @media only screen and (max-width: $mobile-breakpoint-width) {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: auto;
    text-align: left;

    .radio-item {
      display: flex;
      justify-content: center;
      margin-bottom: 2rem;
      margin-right: 1.5rem;
    }

    .radio-item-input {
      display: flex;
      margin-right: 1rem;
    }

    .radio-item-circle {
      border-radius: 50%;
      height: 1.2em;
      width: 1.2em;
    }

    .radio-item-circle::before {
      border-radius: 50%;
      box-shadow: inset 1em 1em #ef6b8a;
      content: "";
      height: 1em;
      transform: scale(0);
      transform: scale(0);
      transition: 180ms transform ease-in-out;
      width: 1em;
    }
  }
}
