.appointments {
  display: flex;
  margin-left: 2rem;
  width: 100%;

  .appointment-slot {
    background-color: #257e4a;
  }

  .appointment {
    align-self: flex-start;
    margin-top: 12rem;
  }

  .reserved-slot {
    background-color: #000;
  }

  .fc {
    margin: 0 auto;
    padding-bottom: 2rem;
    width: 100%;

    tr {
      height: 3rem;
    }
  }
}
