.auth-pane {
  align-items: center;
  background-color: $primary-background-colour;
  border-radius: $standard-border-radius;

  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  display: flex;
  flex-direction: column;
  grid-column: span 2;
  height: 70%;
  justify-content: center;
  width: 100%;

  button {
    margin-top: 1.5rem;
  }

  &.fade-out {
    .login,
    .register {
      opacity: 0;
      transition: opacity 0.1s ease-out;
    }
  }

  &.fade-in {
    .login,
    .register {
      opacity: 1;

      transition: opacity 0.1s ease-in;
    }
  }
}
