.old-home-page {
  .why-choose-us-intro {
    p {
      font-size: 16px;
    }
  }

  .why-choose-us-reasons {
    display: flex;
    flex: 1 1 0;
    justify-content: space-around;
    margin: $medium auto;
    max-width: 1140px;
    text-align: center;

    .reason {
      align-items: center;
      display: flex;
      flex: 1 1 0;
      flex-direction: column;

      max-width: 25rem;
    }

    h3 {
      color: #000;
      font-family: "Montserrat", Sans-serif;
      font-size: 1.4rem;
      font-weight: 600;
      margin-bottom: 1.2rem;
    }

    p {
      color: #000;
      font-family: "Open Sans", Sans-serif;
      font-size: 14px;
    }

    svg {
      color: $tertiary-background-colour;
      margin-bottom: 1.2rem;
    }
  }
  @media only screen and (max-width: $mobile-breakpoint-width) {
    &.why-choose-us-intro {
      padding: 0;
    }

    .why-choose-us-reasons {
      align-items: center;
      flex-direction: column;
      margin-top: 0;

      .reason {
        margin-bottom: $small;
      }
    }
  }
}
