.date-picker {
  .selected-date {
    font-size: 1.5rem;
    margin-top: 0;
    text-align: center;
  }

  .selected-date-block {
    display: flex;
    flex-direction: column;
  }

  /* stylelint-disable */
  .react-datepicker {
    font-size: 1.3em;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header,
  .react-datepicker__day-name {
    color: #fff;
    font-weight: bold;
  }

  .react-datepicker__header {
    background-color: $primary-background-colour;
    padding-top: 0.8em;
  }

  .react-datepicker__month {
    margin: 0.4em 1em;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    line-height: 2em;
    margin: 0.166em;
    width: 2em;
  }

  .react-datepicker__current-month {
    font-size: 1em;
  }

  .react-datepicker__navigation {
    border: 0.45em solid transparent;
    line-height: 1.7em;
    top: 1em;
  }

  .react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em;
  }

  .react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em;
  }
  .react-datepicker__day--selected {
    border-radius: 50%;
    border-color: $secondary-background-colour;
  }
  /* stylelint-enabled */
}
